import axios from "axios";

//const BASE_URL = "https://divina-creation.herokuapp.com";
//const BASE_URL = "https://divina-api.codioticdemo.com";
const BASE_URL = "https://api.divinainc.com";
//const BASE_URL = "https://testapi.divinainc.com/";
// const BASE_URL = "http://127.0.0.1:8080";
//const BASE_URL = "http://localhost:80/"

var axiosInstance = axios.create({
  baseURL: BASE_URL,
  /* other custom settings */
});

export default axiosInstance;
