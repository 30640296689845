import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Productcard from "../Components/Productcard";
import img1 from "../assets/images/diamond.png";
import img2 from "../assets/images/diamond.png";
import img3 from "../assets/images/diamond.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import axios from "../config/Axios";
import Skeleton from "react-loading-skeleton";
import toaster from "toasted-notes";
import _, { round } from "lodash";
import "toasted-notes/src/styles.css";
import sampleImage from "../sampleImages/sample_diamond.jpg";
// import {
//   Magnifier,
//   GlassMagnifier,
//   SideBySideMagnifier,
//   PictureInPictureMagnifier,
//   MOUSE_ACTIVATION,
//   TOUCH_ACTIVATION
// } from "react-image-magnifiers";

import Magnifier from "react-magnifier";
import ReactImageMagnify from "react-image-magnify";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Diamond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diamondPageSliderTitle: "",
      diamondPageSliderData: [],
      declaimer: "Disclaimer: All Diamond Weights are approx weights",
      topTenProducts: [],
      stock: "",
      shape: "",
      weight: "",
      color: "",
      clarity: "",
      cut_grade: "",
      polish: "",
      symmetry: "",
      lab: "",
      certificate: "",
      depth: "",
      table: "",
      girdle: "",
      availability: "",
      fluorescence_intensity: "",
      fluorescence_color: "",
      culet_size: "",

      length: "",
      width: "",
      height: "",
      girdle_thin: "",
      diamond_image: [],
      rapnet_discount: "",
      cash_price: "",
      certificate_filename: "",
      price: 0,
      loader: false,
      our_stock: "",
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerToken__") !== null ? true : false,
      three_d_file: [],
      three_d_fileView: false,
      selectedImage: "",
      selectedImageIndex: "",
    };
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let id = this.props.match.params.id;
    this.setState({ loader: true });
    axios
      .get("/cms/website")
      .then((res) => {
        let { diamondPageSliderTitle, diamondPageSliderData } =
          res.data.data[0];
        this.setState({ diamondPageSliderTitle, diamondPageSliderData });
      })
      .catch((err) => {
        this.notify("danger", err, "2000");
      });

    axios
      .get("/diamond/website/" + id, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token:
            localStorage.getItem("__divinaRetailerToken__") !== null
              ? localStorage.getItem("__divinaRetailerToken__")
              : "",
        },
      })
      .then((res) => {
        if (res.data.data) {
          let data = this.state;
          for (const [key, value] of Object.entries(res.data.data[0])) {
            for (const [statekey] of Object.entries(data)) {
              if (statekey == key) {
                if (key == "diamond_image") {
                  if (value.length && Array.isArray(value)) {
                    data[statekey] = value;
                  }

                  data.selectedImage = data[statekey][0];
                } else {
                  data[statekey] = value;
                }
              }
            }
          }

          if (res.data.data[0]) {
            data.three_d_fileView =
              res.data.data[0].three_d_file !== null &&
              res.data.data[0].three_d_file.length > 0
                ? true
                : false;

            data.selectedImage = data.three_d_fileView
              ? "/play-button.svg"
              : data.selectedImage;
          }

          this.setState({ ...data });
        }
        this.setState({ items: res.data.data, loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err, "2000");
      });
  }

  imageUrlModification = (url) => {
    if (url) {
      let newUrl = baseURL + url.toLowerCase();
      // let urlPart = url.split("/");

      // let name = urlPart[urlPart.length - 1];
      // let diamondName = name.split(".")[0];
      // let ext = name.split(".")[1].toLowerCase();
      // //let ext = name.split(".")[1];

      // urlPart.splice(urlPart.length - 1, 1);
      // url = urlPart.join("/");
      // let newUrl = url + "/" + diamondName + "." + ext;
      return newUrl;
    } else {
      return url;
    }
  };

  render() {
    let showCert = this.state.certificate_filename !== "" && this.state.token;

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }

    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />

        <div className="container-fluid mb-lg-5">
          <div className="row py-5 ">
            <div className="col-lg-5">
              <div className="main-img-container text-center">
                {this.state.loader ? (
                  <Skeleton count={1} height={400} />
                ) : this.state.three_d_file !== null &&
                  this.state.three_d_file.length > 0 &&
                  this.state.three_d_fileView ? (
                  <iframe
                    src={this.state.three_d_file[0]}
                    frameBorder="0"
                    webkitallowfullscreen=""
                    mozallowfullscreen=""
                    allowFullScreen=""
                    scrolling="no"
                    style={{ zIndex: 0, backgroundColor: "transparent" }}
                    height={300}
                  ></iframe>
                ) : (
                  //
                  <Magnifier
                    src={this.imageUrlModification(this.state.selectedImage)}
                    //src={sampleImage}
                    width={500}
                    zoomImgSrc={this.imageUrlModification(
                      this.state.selectedImage
                    )}
                    zoomFactor={1.5}
                  />
                )}
              </div>
              <div className="row mt-3 justify-content-center">
                {this.state.loader ? (
                  <>
                    <div className="col-3 mb-1">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-3 mb-1">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-3 mb-1">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-3 mb-1">
                      <Skeleton count={1} height={150} />
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.three_d_file !== null &&
                    this.state.three_d_file.length > 0 ? (
                      <div className="col-2 mb-1 pl-0">
                        <div
                          style={{ cursor: "pointer" }}
                          className={
                            this.state.selectedImage == "/play-button.svg"
                              ? "thum-img-container active"
                              : "thum-img-container"
                          }
                          onClick={() =>
                            this.setState({
                              selectedImage: "/play-button.svg",
                              three_d_fileView: true,
                              selectedImageIndex: "",
                            })
                          }
                        >
                          <img src={"/play-button.svg"} className="img-fluid" />
                        </div>
                      </div>
                    ) : null}
                    {this.state.diamond_image.length
                      ? this.state.diamond_image.map((el, i) => {
                          return (
                            <div
                              className={
                                this.state.three_d_file !== null &&
                                this.state.three_d_file.length > 0
                                  ? "col-2 mb-1 pl-0"
                                  : "col-3 mb-1 pl-0"
                              }
                              key={i}
                            >
                              <div
                                style={{ cursor: "pointer" }}
                                className={
                                  this.state.selectedImageIndex == i
                                    ? "thum-img-container active"
                                    : "thum-img-container"
                                }
                                onClick={() =>
                                  this.setState({
                                    selectedImageIndex: i,
                                    selectedImage: el,
                                    three_d_fileView: false,
                                  })
                                }
                              >
                                <img
                                  src={this.imageUrlModification(el)}
                                  //src={sampleImage}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </>
                )}
              </div>
            </div>

            <div className="col-lg-7 mt-3 mt-lg-0">
              <h3 className="ft-lato bb-dashed">
                {this.state.our_stock ? (
                  this.state.our_stock
                ) : this.state.loader ? (
                  <Skeleton count={1} width={200} />
                ) : null}
              </h3>

              <div className="row my-4 diamond-table">
                <div className="col-lg-4 col-5 px-0">
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">LOT ID</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.our_stock ? (
                          this.state.our_stock
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">SHAPE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0 text-uppercase">
                        {this.state.shape ? (
                          this.state.shape
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">CARAT</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.weight ? (
                          this.state.weight
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">COLOR</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.color ? (
                          this.state.color
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">CLARITY</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.clarity ? (
                          this.state.clarity
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6 ">
                      <p className="mb-0">CUT</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.cut_grade ? (
                          this.state.cut_grade
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">POLISH</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.polish ? (
                          this.state.polish
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">SYMMETRY</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.symmetry ? (
                          this.state.symmetry
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">FLUORESCENCE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.fluorescence_intensity ? (
                          this.state.fluorescence_intensity
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-7 px-0">
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">LAB</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.lab ? (
                          this.state.lab
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">DEPTH%</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.depth ? (
                          this.state.depth + "%"
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">TABLE%</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.table ? (
                          this.state.table + "%"
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">MEASURE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.length &&
                        this.state.width &&
                        this.state.height ? (
                          this.state.length +
                          "x" +
                          this.state.width +
                          "x" +
                          this.state.height
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">GIRDLE%</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.girdle ? (
                          this.state.girdle + "%"
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">GIRDLE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.girdle_thin ? (
                          this.state.girdle_thin
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">CULET</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.culet_size ? (
                          this.state.culet_size
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">SHADE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.fluorescence_color ? (
                          this.state.fluorescence_color
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">AVAILABLE</p>
                    </div>
                    <div className="col-lg-6 text-right text-lg-left">
                      <p className="mb-0">
                        {this.state.availability ? (
                          this.state.availability
                        ) : this.state.loader ? (
                          <Skeleton count={1} />
                        ) : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row my-4 diamond-table">
                <div className="col-lg-4 px-0">
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">CERT NO.</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-0">
                        {this.state.token ? (
                          this.state.certificate ? (
                            this.state.certificate
                          ) : this.state.loader ? (
                            <Skeleton count={1} />
                          ) : null
                        ) : (
                          <a href="/login">Login</a>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">RAP OFF</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-0">
                        {this.state.token ? (
                          this.state.rapnet_discount ? (
                            "-" +
                            round(Math.abs(this.state.rapnet_discount), 2) +
                            "%"
                          ) : this.state.loader ? (
                            <Skeleton count={1} />
                          ) : null
                        ) : (
                          <a href="/login">Login</a>
                        )}
                      </p>
                    </div>
                  </div> */}
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">US$/CT</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-0">
                        {this.state.token ? (
                          this.state.cash_price ? (
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(round(this.state.cash_price, 2))
                          ) : this.state.loader ? (
                            <Skeleton count={1} />
                          ) : null
                        ) : (
                          <a href="/login">Login</a>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="col-lg-6">
                      <p className="mb-0">AMOUNT</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="mb-0">
                        {this.state.token ? (
                          this.state.price ? (
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(round(this.state.price, 2))
                          ) : this.state.loader ? (
                            <Skeleton count={1} />
                          ) : null
                        ) : (
                          <a href="/login">Login</a>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="product-action-buttons mt-4">
                {localStorage.getItem("__divinaRetailerToken__") == null ? (
                  <Link to="/login">
                    <button className="btn btn-block btn-secondary ft-lato-bold py-3">
                      <span className="ml-2">
                        LOGIN FOR PRICE & CERTIFICATE DETAILS
                      </span>
                    </button>
                  </Link>
                ) : null}
              </div>
              <div className="product-action-buttons">
                <div className="d-flex flex-wrap mt-3">
                  <div className="col-lg-6 pl-0 px-0 px-lg-auto">
                    {this.state.token ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=+19179579177&text=Hey!, I want to enquire about the Diamond : *${this.state.our_stock}* *Name*: ${this.state.userdata.name} *Company*:${this.state.userdata.business_name} *Contact Number*: ${this.state.userdata.mobile_number} *Email ID*: ${this.state.userdata.email} Please call me or email me on the same`}
                        target="_blank"
                      >
                        <button className="btn btn-block btn-outline-light ft-lato-bold py-3">
                          SEND AN ENQUIRY
                        </button>
                      </a>
                    ) : (
                      <button
                        className="btn btn-block btn-outline-light ft-lato-bold py-3"
                        onClick={() =>
                          this.notify("danger", "Please Login First", "2000")
                        }
                      >
                        SEND AN ENQUIRY
                      </button>
                    )}
                  </div>

                  <div className="col-lg-6 pr-0 px-0 pl-lg-3 mt-3 mt-lg-0">
                    {this.state.token ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=+19179579177&text=I want to 24hr hold on the Diamond : *${this.state.our_stock}* *Name*: ${this.state.userdata.name} *Company*:${this.state.userdata.business_name} *Contact Number*: ${this.state.userdata.mobile_number} *Email ID*: ${this.state.userdata.email} Please call me or email me on the same`}
                        target="_blank"
                      >
                        <button className="btn btn-block btn-outline-light ft-lato-bold py-3">
                          REQUEST 24 HOUR HOLD
                        </button>
                      </a>
                    ) : (
                      <button
                        className="btn btn-block btn-outline-light ft-lato-bold py-3"
                        onClick={() =>
                          this.notify("danger", "Please Login First", "2000")
                        }
                      >
                        REQUEST 24 HOUR HOLD
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap align-items-center justify-content-center mt-lg-5">
            <div className="col-lg-12 text-center d-none d-lg-block">
              {showCert ? (
                <>
                  <embed
                    src={this.state.certificate_filename}
                    height={"900px"}
                    width={"100%"}
                  ></embed>
                </>
              ) : (
                <img src="/Sample-Certificate-1.jpg" />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
