import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./pages/Home";
// import Rings from "./pages/Rings";
import Ring from "./pages/Ring";
import Diamond from "./pages/Diamond";
// import Bracelets from "./pages/Bracelets";
// import Earrings from "./pages/Earrings";
import Jewelry from "./pages/categories/jewelery/index";
import Contact from "./pages/Contact";
import Events from "./pages/Events";
import Createaccount from "./pages/Createaccount";
import Estatejewllery from "./pages/Estatejewllery";
import Webuy from "./pages/Webuy";
import Customdesign from "./pages/Customdesign";
import Diamonds from "./pages/Diamonds";
import Login from "./pages/Login";
// import Bracelet from "./pages/Bracelet";
// import Earring from "./pages/Earring";
import Estate from "./pages/Estate";
import EstateJewlleryWithType from "./pages/EstateJewlleryWithType";
import ForgotPassword from "./pages/ForgotPassword";
import SetNewPassword from "./pages/SetNewPassword";
import ScheduleAppointment from "./pages/ScheduleAppointment";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import Gallery from "./pages/Gallery";
import AboutUS from "./pages/AboutUS";
import NotFound from "./pages/NotFound";
import CongratulationPage from "./pages/CongratulationPage";
import Categorylayout from "./pages/categories/jewelery/categorylayout";
// import { data } from "jquery";

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            path="/login"
            render={(props) => <Login {...props} />}
            parent=""
            child=""
            exact
          />
          <Route
            path="/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
            parent=""
            child=""
            exact
          />
          <Route
            path="/reset-password/:token"
            render={(props) => <SetNewPassword {...props} />}
            parent=""
            child=""
            exact
          />
          <Route
            path="/schedule-appointment"
            render={(props) => <ScheduleAppointment {...props} />}
            parent=""
            child=""
            exact
          />

          <Route path="/jewelry" exact>
            <Jewelry parent="" child="" />
          </Route>

          <Route path="/jewelry/:id" exact>
            <Categorylayout parent="jewelry" child="" />
          </Route>

          <Route
            path="/jewelry/:cateCode/:styleName"
            render={(props) => <Ring {...props} />}
            parent="jewelry"
            child="bracelets"
            exact
          />

          {/* <Route path="/jewelry/:id" exact>
            <Categorylayout parent="jewelry" child="" />
          </Route> */}
          {/* 
          <Route
            path="/jewelry/rings/ring/:id"
            render={(props) => <Ring {...props} />}
            parent="jewelry"
            child="rings"
            exact
          /> */}
          {/* <Route path="/jewelry/bracelets" exact>
            <Bracelets parent="jewelry" child="" />
          </Route> */}
          {/* <Route
            path="/jewelry/bracelets/bracelet/:id"
            render={(props) => <Bracelet {...props} />}
            parent="jewelry"
            child="bracelets"
            exact
          />
          <Route path="/jewelry/earrings" exact>
            <Earrings parent="jewelry" child="" />
          </Route>
          <Route
            path="/jewelry/earrings/earring/:id"
            render={(props) => <Earring {...props} />}
            parent="jewelry"
            child="earrings"
            exact
          /> */}

          <Route path="/contact-us" exact>
            <Contact parent="" child="" />
          </Route>
          <Route path="/events" exact>
            <Events parent="" child="" />
          </Route>
          <Route path="/create-account" exact>
            <Createaccount parent="" child="" />
          </Route>
          <Route path="/estate-jewelry" exact>
            <Estatejewllery parent="jewelry" child="" />
          </Route>
          <Route
            path="/estate-jewelry/:name"
            render={(props) => <EstateJewlleryWithType {...props} />}
            parent="estate-jewelry"
            child="earrings"
            exact
          />
          <Route
            path="/estate-jewelry/:name/:id"
            render={(props) => <Estate {...props} />}
            parent="estate-jewelry"
            child="earrings"
            exact
          />
          <Route path="/we-buy" exact>
            <Webuy parent="" child="" />
          </Route>
          <Route path="/blogs" exact>
            <Blogs parent="" child="" />
          </Route>
          <Route path="/gallery" exact>
            <Gallery parent="" child="" />
          </Route>

          <Route
            path="/blogs/blog/:id"
            render={(props) => <Blog {...props} />}
            parent=""
            child=""
            exact
          />
          <Route
            path="/diamonds/diamond/:id"
            render={(props) => <Diamond {...props} />}
            parent=""
            child=""
            exact
          />
          <Route path="/custom-design" exact>
            <Customdesign parent="" child="" />
          </Route>
          <Route path="/about-us" exact>
            <AboutUS parent="" child="" />
          </Route>
          <Route path="/diamonds" exact>
            <Diamonds parent="" child="" />
          </Route>
          <Route path="/congratulation" exact>
            <CongratulationPage parent="" child="" />
          </Route>
          <Route path="/" exact>
            <Home parent="" child="" />
          </Route>

          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    );
  }
}
