import React, { Component } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import _, { round } from "lodash";
import { Fragment } from "react";

let _this;
let invoice = {
  desc: "Proj description",
  time: "9:00 - 12:00",
  unit_cost: "30",
  hour_rate: "5$",
  amount: "180$",
};

export default class DiamondPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
    };
    _this = this;
    this.closePDF = props.fatch;
  }

  static getDerivedStateFromProps(props, state) {
    state.data = props.data;
    return state;
  }

  componentDidMount() {
    window.html2canvas = html2canvas;
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      //format: [4, 2],
    });

    //var doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(10);
    var content = document.getElementById("diamond-pdf-table");
    doc.html(content, {
      callback: function (doc) {
        doc.save();
        _this.closePDF();
      },
    });
  }

  render() {
    return (
      <Fragment>
        <div id="diamond-pdf-table">
          {/* <div
            className="container-fluid"
            style={{ background: "#000", color: "white" }}
          >
            <div className="row my-2 py-2 justify-content-center text-center align-items-center">
              <div className="col-md-2">
                <img src="/Divina_Logo.svg" width={100} />
              </div>
              <div className="col-md-6">
                <h3>
                  <b>Certified Diamond Inventory List</b>
                </h3>
              </div>
              <div className="col-md-4">
                <h4>
                  <b>sales@amipi.com | +1 (800) 530-2647</b>
                </h4>
              </div>
            </div>
          </div> */}

          <table style={{ width: "34%" }}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    color: "#000",
                    fontSize: 12,
                    padding: "5px 10px",
                    width: "33.33%",
                    textAlign: "center",
                  }}
                >
                  Divina Creation Inc.
                </td>
                {/* <td
                  style={{
                    color: "#000",
                    fontSize: 8,
                    padding: "5px 10px",
                    width: "33.33%",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: "url(/Divina_Logo.svg)",
                      padding: "30px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundColor: "#0e0e0e",
                    }}
                  ></div>
                </td> */}
                <td
                  style={{
                    color: "#000",
                    fontSize: 12,
                    padding: "5px 10px",
                    width: "33.33%",
                    textAlign: "center",
                  }}
                >
                  Certified Diamond Inventory List
                </td>
                <td
                  style={{
                    color: "#000",
                    fontSize: 10,
                    padding: "5px 10px",
                    width: "33.33%",
                    textAlign: "center",
                  }}
                >
                  sales@divinainc.com&nbsp;&nbsp;|&nbsp;&nbsp;+1 (212) 575-4600
                </td>
              </tr>
            </tbody>
          </table>

          {/* <div>
            <p>
              <b>Certified Diamond Inventory List</b>
            </p>
            <p>
              <b>sales@amipi.com | +1 (800) 530-2647</b>
            </p>
          </div> */}
          <table className="" id="" style={{ fontSize: 8, width: "34%" }}>
            <thead style={{ padding: "5px 10px", background: "#ccc" }}>
              <tr>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  LOT NO
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  SHAPE
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  CTS
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  COL
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  CLA
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  CUT
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  POL
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  SYM
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  FLO
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  AMOUNT
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  LAB
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  DEPTH%
                </th>
                <th style={{ color: "#000", fontSize: 8, padding: "5px 10px" }}>
                  TABLE%
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((e, i) => {
                return (
                  <tr
                    key={i}
                    style={{
                      background: i % 2 !== 0 ? "#e8e8e8" : "",
                    }}
                  >
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.our_stock}
                    </td>
                    <td
                      className="text-uppercase"
                      style={{ fontSize: 8, padding: "5px 10px" }}
                    >
                      {e.shape}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.weight}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.color}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.clarity}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.cut_grade}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.polish}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.symmetry}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.fluorescence_intensity}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {this.state.token ? (
                        "$" + round(e.price, 2)
                      ) : (
                        <a href="/login">0</a>
                      )}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.lab}
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.depth}%
                    </td>
                    <td style={{ fontSize: 8, padding: "5px 10px" }}>
                      {e.table}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
