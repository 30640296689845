function validatePhone(number) {
  const re = /^[789]\d{9}$/
  return re.test(Number(number))
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}


function datePrefix(date) {
  switch (date) {
    case 1, 21, 31: return 'st'
    case 2, 22: return 'nd'
    case 3, 23: return 'rd'
    default: return 'th'
  }
}

function humanDate(date) {
  const copyDate = new Date(date).toDateString()
  let _date = ''
  if (copyDate !== 'Invalid Date') {
    const dateString = new Date(date).toDateString().split(' ') //split on space
    _date = `${dateString[2]}${datePrefix(Number(dateString[2]))} ${dateString[1]}, ${dateString[3]}`
  } else {
    return ''
  }
  return _date
}

export {
  validatePhone,
  validateEmail,    
  humanDate,
}