import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import wcDivinaImg from "../assets/welcome-divina.png";
import axios from "../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { Link } from "react-router-dom";

let _this = "";

let diamondFilter = [];

let colorFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  { keyName: "D", value: true, displayName: "D" },
  { keyName: "E", value: true, displayName: "E" },
  { keyName: "F", value: true, displayName: "F" },
  { keyName: "G", value: true, displayName: "G" },
  { keyName: "H", value: true, displayName: "H" },
  { keyName: "I", value: true, displayName: "I" },
  { keyName: "J", value: true, displayName: "J" },
  { keyName: "K", value: true, displayName: "K" },
  { keyName: "L", value: true, displayName: "L" },
  { keyName: "M", value: true, displayName: "M" },
  { keyName: "N", value: true, displayName: "N" },
  { keyName: "O", value: true, displayName: "O" },
  { keyName: "P", value: true, displayName: "P" },
  { keyName: "Q", value: true, displayName: "Q" },
  { keyName: "R", value: true, displayName: "R" },
  { keyName: "S", value: true, displayName: "S" },
  { keyName: "T", value: true, displayName: "T" },
  { keyName: "U", value: true, displayName: "U" },
  { keyName: "V", value: true, displayName: "V" },
  { keyName: "W", value: true, displayName: "W" },
  { keyName: "X", value: true, displayName: "X" },
  { keyName: "Y", value: true, displayName: "Y" },
  { keyName: "Z", value: true, displayName: "Z" },
];

let clarityFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "FL",
    value: true,
    displayName: "FL",
  },
  {
    keyName: "IF",
    value: true,
    displayName: "IF",
  },
  {
    keyName: "VVS1",
    value: true,
    displayName: "VVS1",
  },
  {
    keyName: "VVS2",
    value: true,
    displayName: "VVS2",
  },
  {
    keyName: "VS1",
    value: true,
    displayName: "VS1",
  },
  {
    keyName: "VS2",
    value: true,
    displayName: "VS2",
  },
  {
    keyName: "SI1",
    value: true,
    displayName: "SI1",
  },
  {
    keyName: "SI2",
    value: true,
    displayName: "SI2",
  },
  {
    keyName: "SI3",
    value: true,
    displayName: "SI3",
  },
  {
    keyName: "I1",
    value: true,
    displayName: "I1",
  },
  {
    keyName: "I2",
    value: true,
    displayName: "I2",
  },
  {
    keyName: "I3",
    value: true,
    displayName: "I3",
  },
];

let cutFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "ID",
    value: true,
    displayName: "ID",
  },
  {
    keyName: "EX",
    value: true,
    displayName: "EX",
  },
  {
    keyName: "VG",
    value: true,
    displayName: "VG",
  },
  {
    keyName: "G",
    value: true,
    displayName: "G",
  },
  {
    keyName: "F",
    value: true,
    displayName: "F",
  },
  {
    keyName: "P",
    value: true,
    displayName: "P",
  },
];
let polishFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "I",
    value: true,
    displayName: "I",
  },
  {
    keyName: "EX",
    value: true,
    displayName: "EX",
  },
  {
    keyName: "VG",
    value: true,
    displayName: "VG",
  },
  {
    keyName: "G",
    value: true,
    displayName: "G",
  },
  {
    keyName: "F",
    value: true,
    displayName: "F",
  },
  {
    keyName: "P",
    value: true,
    displayName: "P",
  },
];
let symmetryFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "I",
    value: true,
    displayName: "I",
  },
  {
    keyName: "EX",
    value: true,
    displayName: "EX",
  },
  {
    keyName: "VG",
    value: true,
    displayName: "VG",
  },
  {
    keyName: "G",
    value: true,
    displayName: "G",
  },
  {
    keyName: "F",
    value: true,
    displayName: "F",
  },
  {
    keyName: "P",
    value: true,
    displayName: "P",
  },
];
let fluorescenceFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "N",
    value: true,
    displayName: "N",
  },
  {
    keyName: "F",
    value: true,
    displayName: "F",
  },
  {
    keyName: "M",
    value: true,
    displayName: "M",
  },
  {
    keyName: "S",
    value: true,
    displayName: "S",
  },
  {
    keyName: "VS",
    value: true,
    displayName: "VS",
  },
];

let labFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
  {
    keyName: "GIA",
    value: true,
    displayName: "GIA",
  },
  {
    keyName: "EGL USA",
    value: true,
    displayName: "EGL USA",
  },
  {
    keyName: "AGS",
    value: true,
    displayName: "AGS",
  },
  {
    keyName: "HRD",
    value: true,
    displayName: "HRD",
  },
  {
    keyName: "IGI",
    value: true,
    displayName: "IGI",
  },
  {
    keyName: "GCAL",
    value: true,
    displayName: "GCAL",
  },
  {
    keyName: "GSI",
    value: true,
    displayName: "GSI",
  },
  {
    keyName: "LOOSE",
    value: true,
    displayName: "LOOSE",
  },
];

let vendorsFilter = [
  {
    keyName: "All",
    value: true,
    displayName: "All",
  },
];

export default class Createaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationPageDescription: "",
      formData: {
        name: "",
        password: "",
        business_name: "",
        city: "",
        country: "",
        state: "",
        zipcode: "",
        address1: "",
        address2: "",
        mobile_number: "",
        email: "",
        confirmPassword: "",
        representative: "",
        permissions: [
          {
            filterName: "DIAMOND_SHAP",
            data: diamondFilter,
            key: "shape",
            filterDisplayName: "Filter Diamond Shape",
          },
          {
            filterName: "VENDORS",
            data: vendorsFilter,
            key: "vendors",
            filterDisplayName: "Vendors",
          },
          {
            filterName: "DIAMOND_COLOR",
            data: colorFilter,
            key: "color",
            filterDisplayName: "Diamond Color",
          },
          {
            filterName: "DIAMOND_CLARITY",
            data: clarityFilter,
            key: "clarity",
            filterDisplayName: "Diamond Clarity",
          },
          {
            filterName: "DIAMOND_CUT",
            data: cutFilter,
            key: "cut_grade",
            filterDisplayName: "Diamond Cut",
          },
          {
            filterName: "DIAMOND_POLISH",
            data: polishFilter,
            key: "polish",
            filterDisplayName: "Diamond Polish",
          },
          {
            filterName: "DIAMOND_SYMMETRY",
            data: symmetryFilter,
            key: "symmetry",
            filterDisplayName: "Diamond Symmetry",
          },
          {
            filterName: "DIAMOND_FLUORESCENCE",
            data: fluorescenceFilter,
            key: "fluorescence_intensity",
            filterDisplayName: "Diamond Fluorescence",
          },
          {
            filterName: "LAB",
            data: labFilter,
            key: "lab",
            filterDisplayName: "Lab",
          },

          {
            filterName: "FILTER_COUNTRY",
            data: [],
            filterDisplayName: "Filter Country",
          },
          {
            filterName: "INVENTORY_CATEGORY",
            data: [],
            filterDisplayName: "Inventory Category",
          },
        ],
      },

      representative: [],
    };
    _this = this;
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getShapes();
    axios
      .get("/cms/website")
      .then((res) => {
        let { registrationPageDescription } = res.data.data[0];
        let {
          city,
          country,
          state,
          zipcode,
          address1,
          address2,
          contact_number,
          email,
          whatsapp_link,
          email_link,
          location_link,
          call_link,
          banner_image,
        } = res.data.data[0].contactUs;
        this.setState({
          contactUs: {
            city,
            country,
            state,
            zipcode,
            address1,
            address2,
            contact_number,
            email,
            whatsapp_link,
            email_link,
            location_link,
            call_link,
            banner_image,
          },
          registrationPageDescription,
        });
      })
      .catch((err) => {
        console.log("err");
      });

    axios
      .get("/user/website")
      .then((res) => {
        if (res.data.data.length) {
          let { representative } = this.state;
          representative = res.data.data;
          this.setState({
            representative,
          });
        } else {
          this.notify("danger", res.data.message, "2000");
        }
      })
      .catch((err) => {
        console.log("err");
      });
  }

  getShapes = () => {
    let { formData } = this.state;
    axios
      .get("/diamond/get-shapes", {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__authToken__"),
        },
      })
      .then(function (response) {
        let responseJson = response.data;
        diamondFilter = [];

        if (responseJson.data.length > 0) {
          diamondFilter.push({
            keyName: "All",
            value: true,
            displayName: "All",
          });

          responseJson.data.forEach((el) => {
            diamondFilter.push({
              keyName: el.shape_name.toLowerCase(),
              value: true,
              displayName: el.shape_name,
            });
          });
          // diamodoption
          formData.permissions[0].data = diamondFilter;
          _this.setState({ loader: false, formData });
        } else {
          _this.setState({ loader: false });
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
      });
  };

  fatchData = (name, value) => {
    console.log(name, value);
    let { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  addRetailer = () => {
    let data = {
      name: this.state.formData.name,
      password: this.state.formData.password,
      business_name: this.state.formData.business_name,
      city: this.state.formData.city,
      country: this.state.formData.country,
      state: this.state.formData.state,
      zipcode: this.state.formData.zipcode,
      address1: this.state.formData.address1,
      address2: this.state.formData.address2,
      mobile_number: this.state.formData.mobile_number,
      representative: this.state.formData.representative,
      email: this.state.formData.email,
      markup: [
        { category_type: "diamond", markup: 0 },
        { category_type: "labgrown", markup: 0 },
        { category_type: "jewelry", markup: 0 },
        { category_type: "estate", markup: 0 },
      ],
      permissions: this.state.formData.permissions,
    };

    if (data.name == "") {
      _this.notify("danger", "Name is required", "2000");
      return;
    }
    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }
    if (data.mobile_number == "") {
      _this.notify("danger", "Phone Number is required", "2000");
      return;
    }

    if (data.password == "") {
      _this.notify("danger", "Password is required", "2000");
      return;
    }

    if (this.state.formData.confirmPassword == "") {
      _this.notify("danger", "Confirm is required", "2000");
      return;
    }

    if (this.state.formData.confirmPassword !== data.password) {
      _this.notify(
        "danger",
        "Password and confirm password is not match",
        "2000"
      );
      return;
    }

    if (data.business_name == "") {
      _this.notify("danger", "Business Name is required", "2000");
      return;
    }
    if (data.address1 == "" && data.address2 == "") {
      _this.notify("danger", "Address is required", "2000");
      return;
    }

    if (data.city == "") {
      _this.notify("danger", "City is required", "2000");
      return;
    }
    if (data.state == "") {
      _this.notify("danger", "State is required", "2000");
      return;
    }
    if (data.zipcode == "") {
      _this.notify("danger", "Zipcode is required", "2000");
      return;
    }

    if (data.country == "") {
      _this.notify("danger", "Country is required", "2000");
      return;
    }

    axios
      .post("/retailer/website/add-retailer", data)
      .then((res) => {
        let data = res.data.data;
        if (res.data.status) {
          _this.notify("success", "Account Created successfully", "2000");
          window.location.replace("/login");
        } else {
          _this.notify("danger", res.data.message, "2000");
        }
      })
      .catch((err) => {
        _this.notify("danger", err.message, "2000");
      });

    axios
      .post("/mail/website/add-mail", { mail_data: this.state.formData.email })
      .then((res) => {
        let data = res.data.data;
        if (res.data.status) {
          // _this.notify("success", "Account Created successfully", "2000");
        } else {
          // _this.notify("danger", res.data.message, "2000");
        }
      })
      .catch((err) => {
        _this.notify("danger", err.message, "2000");
      });
  };

  render() {
    return (
      <div className="container-fluid p-0">
        {localStorage.getItem("__divinaRetailerToken__") ? <Header /> : null}
        <Bread />

        {/* <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${baseURL+this.state.registrationPageDescription})`,
          }}
        >
          <div className="blur-bg  text-center pt-lg-5 pt-2 px-2 px-lg-0">
            <img src={wcDivinaImg} className="img-fluid" />
            <h3 className="text-center wc-divina-text mt-2">
              In a few simple steps you will have unrestricted access to all the
              <br />
              goodies this site has to offer.
            </h3>
          </div>
        </div> */}

        <div className="mt-lg-3 mt-md-0 pt-md-3 container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <p className="text-center desc-text">
                {this.state.registrationPageDescription}
              </p>
            </div>
          </div>
        </div>

        <div className="contact-form container  d-flex justify-content-center mt-5 mb-5">
          <div className="p-lg-5 p-3 bg-grey">
            <div className="row">
              <div className="col-md-2 col-4">
                <div className="form-group">
                  <select className="form-control">
                    <option>Mr.</option>
                    <option>Ms.</option>
                    <option>Miss.</option>
                  </select>
                </div>
              </div>

              <div className="col-md-10 col-8">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Name"
                    onChange={(e) => this.fatchData("name", e.target.value)}
                    value={this.state.formData.name}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Email"
                    onChange={(e) => this.fatchData("email", e.target.value)}
                    value={this.state.formData.email}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Phone"
                    onChange={(e) =>
                      this.fatchData("mobile_number", e.target.value)
                    }
                    value={this.state.formData.mobile_number}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    type="password"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Password"
                    onChange={(e) => this.fatchData("password", e.target.value)}
                    value={this.state.formData.password}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    type="password"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Confirm Password"
                    onChange={(e) =>
                      this.fatchData("confirmPassword", e.target.value)
                    }
                    value={this.state.formData.confirmPassword}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Business Name"
                    onChange={(e) =>
                      this.fatchData("business_name", e.target.value)
                    }
                    value={this.state.formData.business_name}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <textarea
                    className="form-control"
                    placeholder="Address 1 "
                    value={this.state.formData.address1}
                    onChange={(e) => this.fatchData("address1", e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <textarea
                    className="form-control"
                    placeholder="Address 2"
                    value={this.state.formData.address2}
                    onChange={(e) => this.fatchData("address2", e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    className="form-control"
                    placeholder="City"
                    value={this.state.formData.city}
                    onChange={(e) => this.fatchData("city", e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    className="form-control"
                    placeholder="State"
                    value={this.state.formData.state}
                    onChange={(e) => this.fatchData("state", e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    className="form-control"
                    placeholder="Zip Code"
                    value={this.state.formData.zipcode}
                    onChange={(e) => this.fatchData("zipcode", e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <input
                    className="form-control"
                    placeholder="Country"
                    value={this.state.formData.country}
                    onChange={(e) => this.fatchData("country", e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor=""></label>
                  <select
                    className="form-control"
                    placeholder="Select Representative"
                    value={this.state.formData.representative}
                    onChange={(e) =>
                      this.fatchData("representative", e.target.value)
                    }
                  >
                    <option value="">Select Representative</option>
                    {this.state.representative.map((el, index) => {
                      return (
                        <option
                          value={el.name + "(Ext." + el.extension + ")"}
                          key={index}
                        >
                          {el.name} ({"Ext." + el.extension})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <div className="form-group">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.addRetailer}
                  >
                    Create Account
                  </button>
                </div>
                {localStorage.getItem("__divinaRetailerToken__") ? null : (
                  <div>
                    <p className="mb-0">
                      <Link to="/login" className="text-white">
                        or Login
                      </Link>{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
