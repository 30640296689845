import React, { Component, Fragment } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import NumericInput from "../Components/Numericinput";
import axios from "../config/Axios";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import Loader from "react-loader-spinner";
import { data } from "jquery";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import exportFromJSON from "export-from-json";
import _, { round } from "lodash";
import DiamondPdf from "./DiamondPdf";
import { baseURL } from "../constant/defaultValues";
import sampleImage from "../sampleImages/sample_diamond.jpg";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

let _this = "";

let otherClarity = ["I1", "I2", "I3"];

let otherColor = [
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const rangFilterDefultValue = [
  {
    keyName: "weight",
    position: "from",
    value: 0,
  },
  {
    keyName: "weight",
    position: "to",
    value: 99,
  },
  {
    keyName: "cash_price",
    position: "from",
    value: 0,
  },
  {
    keyName: "cash_price",
    position: "to",
    value: 100000,
  },
  {
    keyName: "price",
    position: "from",
    value: 0,
  },
  {
    keyName: "price",
    position: "to",
    value: 100000,
  },
];

const rangAdvanceFilterDefultValue = [
  {
    keyName: "length",
    position: "from",
    value: 0,
  },
  {
    keyName: "length",
    position: "to",
    value: 100000,
  },
  {
    keyName: "width",
    position: "from",
    value: 0,
  },
  {
    keyName: "width",
    position: "to",
    value: 99,
  },
  {
    keyName: "depth",
    position: "from",
    value: 0,
  },
  {
    keyName: "depth",
    position: "to",
    value: 100000,
  },
  {
    keyName: "table",
    position: "from",
    value: 0,
  },
  {
    keyName: "table",
    position: "to",
    value: 100000,
  },
  {
    keyName: "rapnet_discount",
    position: "from",
    value: 0,
  },
  {
    keyName: "rapnet_discount",
    position: "to",
    value: 100000,
  },
  {
    keyName: "girdle_thin",
    position: "from",
    value: 0,
  },
  {
    keyName: "girdle_thin",
    position: "to",
    value: 8,
  },
  {
    keyName: "culet_size",
    position: "from",
    value: 0,
  },
  {
    keyName: "culet_size",
    position: "to",
    value: 7,
  },
];

const rangfilterClone = [
  {
    keyName: "weight",
    value: "",
    displayName: "From Weight",
    position: "from",
  },
  {
    keyName: "weight",
    value: "",
    displayName: "To Weight",
    position: "to",
  },
  {
    keyName: "cash_price",
    value: "",
    displayName: "From Price/Ct",
    position: "from",
  },
  {
    keyName: "cash_price",
    value: "",
    displayName: "To Price/Ct",
    position: "to",
  },
  {
    keyName: "price",
    value: "",
    displayName: "From Total Price",
    position: "from",
  },
  {
    keyName: "price",
    value: "",
    displayName: "To Total Price",
    position: "to",
  },
];

const rangAdvancefilterClone = [
  {
    keyName: "length",
    value: "",
    displayName: "From Length",
    position: "from",
    isSelect: false,
  },
  {
    keyName: "length",
    value: "",
    displayName: "To Length",
    position: "to",
    isSelect: false,
  },
  {
    keyName: "width",
    value: "",
    displayName: "From Width",
    position: "from",
    isSelect: false,
  },
  {
    keyName: "width",
    value: "",
    displayName: "To Width",
    position: "to",
    isSelect: false,
  },
  {
    keyName: "depth",
    value: "",
    displayName: "From Depth",
    position: "from",
    isSelect: false,
  },
  {
    keyName: "depth",
    value: "",
    displayName: "To Depth",
    position: "to",
    isSelect: false,
  },
  {
    keyName: "table",
    value: "",
    displayName: "From Table%",
    position: "from",
    isSelect: false,
  },
  {
    keyName: "table",
    value: "",
    displayName: "To Table%",
    position: "to",
    isSelect: false,
  },
  {
    keyName: "rapnet_discount",
    value: "",
    displayName: "From Rap%",
    position: "from",
    isSelect: false,
  },
  {
    keyName: "rapnet_discount",
    value: "",
    displayName: "To Rap%",
    position: "to",
    isSelect: false,
  },
  {
    keyName: "girdle_thin",
    value: 0,
    displayName: "From Girdle",
    position: "from",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "XTN", value: 1 },
      { key: "VTN", value: 2 },
      { key: "TN", value: 3 },
      { key: "STN", value: 4 },
      { key: "M", value: 5 },
      { key: "STK", value: 6 },
      { key: "VTK", value: 7 },
      { key: "XTK", value: 8 },
    ],
  },
  {
    keyName: "girdle_thin",
    value: 0,
    displayName: "To Girdle",
    position: "to",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "XTN", value: 1 },
      { key: "VTN", value: 2 },
      { key: "TN", value: 3 },
      { key: "STN", value: 4 },
      { key: "M", value: 5 },
      { key: "STK", value: 6 },
      { key: "VTK", value: 7 },
      { key: "XTK", value: 8 },
    ],
  },
  {
    keyName: "culet_size",
    value: 0,
    displayName: "From Culet",
    position: "from",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "N", value: 1 },
      { key: "VS", value: 2 },
      { key: "S", value: 3 },
      { key: "M", value: 4 },
      { key: "SL", value: 5 },
      { key: "L", value: 6 },
      { key: "VL", value: 7 },
    ],
  },
  {
    keyName: "culet_size",
    value: 0,
    displayName: "To Culet",
    position: "to",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "N", value: 1 },
      { key: "VS", value: 2 },
      { key: "S", value: 3 },
      { key: "M", value: 4 },
      { key: "SL", value: 5 },
      { key: "L", value: 6 },
      { key: "VL", value: 7 },
    ],
  },
];

let fancyIntensityFilterClone = [
  {
    keyName: "fancy_intensity",
    value: 0,
    displayName: "From Culet",
    position: "from",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "Faint", value: 1 },
      { key: "Very Light", value: 2 },
      { key: "Light", value: 3 },
      { key: "Fancy Light", value: 4 },
      { key: "Fancy Light", value: 5 },
      { key: "Fancy Dark", value: 6 },
      { key: "Fancy Intense", value: 7 },
      { key: "Fancy Vivid", value: 8 },
      { key: "Fancy Deep", value: 9 },
    ],
  },
  {
    keyName: "fancy_intensity",
    value: 0,
    displayName: "To Culet",
    position: "to",
    isSelect: true,
    option: [
      { key: "Any", value: 0 },
      { key: "Faint", value: 1 },
      { key: "Very Light", value: 2 },
      { key: "Light", value: 3 },
      { key: "Fancy Light", value: 4 },
      { key: "Fancy Light", value: 5 },
      { key: "Fancy Dark", value: 6 },
      { key: "Fancy Intense", value: 7 },
      { key: "Fancy Vivid", value: 8 },
      { key: "Fancy Deep", value: 9 },
    ],
  },
];

let keyName = [
  "our_stock",
  "shape",
  "color",
  "clarity",
  "lab",
  "depth",
  "table",
  "culet_size",
  "polish",
  "symmetry",
  "fluorescence_intensity",
  "girdle",
  "culet_condition",
  "length",
  "width",
  "height",
  "rapnet_price",
  "cash_price",
  "price",
  "availability",
  "color_fancy",
  "comment",
  "crown_angle",
  "crown_height",
  "cut_grade",
  "depth",
  "fancy_color",
  "fancy_color_overtone",
  "fluorescence_color",
  "girdle_condition",
  "girdle_thin",
  "laser_inscription",
  "pavilion_angle",
  "pavilion_depth",
  "weight",
  "treatment",
];

export default class Diamonds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: [
        {
          label: "LOT NO",
          key: "our_stock",
          sort: "desc",
          isSort: false,
        },

        {
          label: "SHAPE",
          key: "shape",
          sort: "desc",
          isSort: false,
        },

        {
          label: "CTS",
          key: "weight",
          sort: "desc",
          isSort: true,
        },

        {
          label: "COL",
          key: "color",
          sort: "false",
          isSort: false,
        },

        {
          label: "CLA",
          key: "clarity",
          sort: "desc",
          isSort: false,
        },

        {
          label: "CUT",
          key: "cut_grade",
          sort: "desc",
          isSort: false,
        },

        {
          label: "POL",
          key: "polish",
          sort: "desc",
          isSort: false,
        },

        {
          label: "SYM",
          key: "symmetry",
          sort: "desc",
          isSort: false,
        },

        {
          label: "FLO",
          key: "fluorescence_color",
          sort: "desc",
          isSort: false,
        },

        {
          label: "AMOUNT",
          key: "cash_price",
          sort: "desc",
          isSort: true,
        },

        {
          label: "LAB",
          key: "lab",
          sort: "desc",
          isSort: false,
        },

        {
          label: "DEPTH%",
          key: "depth",
          sort: "desc",
          isSort: false,
        },

        {
          label: "TABLE%",
          key: "table",
          sort: "desc",
          isSort: false,
        },
      ],
      showRow: "",
      activeSort: null,
      reqData: {
        limit: 10,
        page: 1,
        offset: 0,
        params: ["shape"],
        orderBy: "_id",
        query: "",
      },
      selectedShapes: [],
      selectedColors: [],
      selectedClarity: [],
      selectedCut: [],
      selectedPolish: [],
      selectedSymmetry: [],
      selectedFlourescene: [],
      selectedCertificate: [],
      selectedGirdleThin: [],
      selectedCuletSize: [],
      selectedFancyColor: [],
      selectedOvertone: [],
      selectedFancyIntensity: [],
      rangfilter: [...rangfilterClone],
      rangAdvancefilter: [...rangAdvancefilterClone],
      fancyIntensityfilter: [...fancyIntensityFilterClone],
      diamondsView: window.innerWidth > 992 ? "table" : "item",
      items: [],
      itemsClone: [],
      showMoreShapes: false,
      filterOptions: {
        shape: [
          {
            name: "Round",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Round.svg",
          },
          {
            name: "Princess",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Princess.svg",
          },
          {
            name: "Emerald",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Emerald.svg",
          },
          {
            name: "Square Emerald",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Sq+Emerald.svg",
          },
          {
            name: "Oval",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Oval.svg",
          },
          {
            name: "Radiant",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Radiant.svg",
          },
          {
            name: "Square Radiant",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Sq+Radiant.svg",
          },
          {
            name: "Asscher",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Asscher.svg",
          },
          {
            name: "Cushion",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Cushiom-A.svg",
          },
          {
            name: "Cushion Brilliant",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Cushiom+B.svg",
          },
          {
            name: "Cushion Modified",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Cushiom+M.svg",
          },
          {
            name: "Pear",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Pear+Shape.svg",
          },
          {
            name: "Marquise",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Marquise.svg",
          },
          {
            name: "Heart",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Heart.svg",
          },
          {
            name: "Tapered Baugette",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Tapered+Baugette.svg",
          },
          {
            name: "Baugette",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Baugette.svg",
          },
          {
            name: "Old Euro",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/European+Cut.svg",
          },
          {
            name: "Old Miner",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Old+Miner.svg",
          },
          {
            name: "Star",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Star.svg",
          },
          {
            name: "Rose",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Rose.svg",
          },
          {
            name: "Half Moon",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Half+moon.svg",
          },
          {
            name: "Square",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Square.svg",
          },
          {
            name: "Bullets",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Bullet.svg",
          },
          {
            name: "Tapered Bullet",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Tapered+Bullet.svg",
          },
          {
            name: "Trapezoid",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Trapezoid.svg",
          },
          {
            name: "Flanders",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Flanders.svg",
          },
          {
            name: "Briolette",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Briolette.svg",
          },
          {
            name: "Pentagon",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Pentagon.svg",
          },
          {
            name: "Hexagonal",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Hexagon.svg",
          },
          {
            name: "Octagonal",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Octagon.svg",
          },
          {
            name: "Triangular",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Triangulat.svg",
          },
          {
            name: "Trilliant",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Trilliant.svg",
          },
          {
            name: "Calf",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Calf.svg",
          },
          {
            name: "Shield",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Shield.svg",
          },
          {
            name: "Lozenge",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Lozenge.svg",
          },
          {
            name: "Kite",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Kite.svg",
          },
          {
            name: "Other",
            imageUrl:
              baseURL +
              "uploads/Divina-Web-Resources/DiamondShapeIcon/Other+Diamond.svg",
          },
        ],

        color: [
          { name: "D", value: "D" },
          { name: "E", value: "E" },
          { name: "F", value: "F" },
          { name: "G", value: "G" },
          { name: "H", value: "H" },
          { name: "I", value: "I" },
          { name: "J", value: "J" },
          { name: "K", value: "K" },
          { name: "L", value: "L" },
          { name: "M+", value: "M+" },
        ],

        clarity: [
          { name: "FL", value: "FL" },
          { name: "IF", value: "IF" },
          { name: "VVS1", value: "VVS1" },
          { name: "VVS2", value: "VVS2" },
          { name: "VS1", value: "VS1" },
          { name: "VS2", value: "VS2" },
          { name: "SI1", value: "SI1" },
          { name: "SI2", value: "SI2" },
          { name: "SI3", value: "SI3" },
          { name: "I+", value: "I+" },
        ],
        cut_grade: [
          { name: "ID", value: "ID" },
          { name: "EX", value: "EX" },
          { name: "VG", value: "VG" },
          { name: "G", value: "G" },
          { name: "F", value: "F" },
          { name: "P", value: "P" },
        ],

        polish: [
          { name: "I", value: "I" },
          { name: "EX", value: "EX" },
          { name: "VG", value: "VG" },
          { name: "G", value: "G" },
          { name: "F", value: "F" },
          { name: "P", value: "P" },
        ],

        symmetry: [
          { name: "I", value: "I" },
          { name: "EX", value: "EX" },
          { name: "VG", value: "VG" },
          { name: "G", value: "G" },
          { name: "F", value: "F" },
          { name: "P", value: "P" },
        ],

        fluorescence_intensity: [
          { name: "N", value: "N" },
          { name: "F", value: "F" },
          { name: "M", value: "M" },
          { name: "S", value: "S" },
          { name: "VS", value: "VS" },
        ],
        lab: [
          { name: "GIA", value: "GIA" },
          { name: "EGL USA", value: "EGL USA" },
          { name: "AGS", value: "AGS" },
          { name: "HRD", value: "HRD" },
          { name: "IGI", value: "IGI" },
          { name: "GCAL", value: "GCAL" },
          { name: "GSI", value: "GSI" },
          { name: "NON CERT", value: "" },
        ],
        fancyColor: [
          { value: "Yellow", label: "Yellow" },
          { value: "Pink", label: "Pink" },
          { value: "Blue", label: "Blue" },
          { value: "Red", label: "Red" },
          { value: "Green", label: "Green" },
          { value: "Purple", label: "Purple" },
          { value: "Orange", label: "Orange" },
          { value: "Violet", label: "Violet" },
          { value: "Gray", label: "Gray" },
          { value: "Black", label: "Black" },
          { value: "Brown", label: "Brown" },
          { value: "Champagne", label: "Champagne" },
          { value: "Cognac", label: "Cognac" },
          { value: "Chameleon", label: "Chameleon" },
          { value: "White", label: "White" },
          { value: "Other", label: "Other" },
        ],
        fancyOvertone: [
          { value: "None", label: "None" },
          { value: "Yellow", label: "Yellow" },
          { value: "Yellowish", label: "Yellowish" },
          { value: "Pink", label: "Pink" },
          { value: "Pinkish", label: "Pinkish" },
          { value: "Blue", label: "Blue" },
          { value: "Bluish", label: "Bluish" },
          { value: "Red", label: "Red" },
          { value: "Reddish", label: "Reddish" },
          { value: "Green", label: "Green" },
          { value: "Greenish", label: "Greenish" },
          { value: "Purple", label: "Purple" },
          { value: "Purplish", label: "Purplish" },
          { value: "Orange", label: "Orange" },
          { value: "Orangey", label: "Orangey" },
          { value: "Violet", label: "Violet" },
          { value: "Gray", label: "Gray" },
          { value: "Grayish", label: "Grayish" },
          { value: "Black", label: "Black" },
          { value: "Brown", label: "Brown" },
          { value: "Brownish", label: "Brownish" },
          { value: "Champagne", label: "Champagne" },
          { value: "Cognac", label: "Cognac" },
          { value: "Chameleon", label: "Chameleon" },
          { value: "Violet", label: "Violet" },
          { value: "White", label: "White" },
          { value: "Other", label: "Other" },
        ],
      },
      totalItem: 0,
      totalItemClone: 0,
      advanceFilterShow: false,
      hideFilter: window.innerWidth > 992 ? false : true,
      compareData: [],
      hiddenData: [],
      isCompare: false,
      isHidden: true,
      checkAll: false,
      isFancy: false,
      loader: false,
      noMoreData: false,
      diamond_image: [],
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
      isPdf: false,
      pdfData: [],
    };

    _this = this;
  }

  isSelected = (stateArr, value) => {
    let selected = [];
    selected = [...this.state[stateArr]];

    if (stateArr == "selectedShapes") {
      return selected.indexOf(value.toLowerCase()) != -1;
    } else {
      return selected.indexOf(value) != -1;
    }
  };

  fetchData = (filter, filterValue, rangfilter, isReset) => {
    let postData = this.state.reqData;

    postData.filterBy = [
      {
        fieldName: "shape",
        value:
          filter == "selectedShapes" ? filterValue : this.state.selectedShapes,
      },
      {
        fieldName: "color",
        value:
          filter == "selectedColors" ? filterValue : this.state.selectedColors,
      },
      {
        fieldName: "cut_grade",
        value: filter == "selectedCut" ? filterValue : this.state.selectedCut,
      },
      {
        fieldName: "clarity",
        value:
          filter == "selectedClarity"
            ? filterValue
            : this.state.selectedClarity,
      },
      {
        fieldName: "lab",
        value:
          filter == "selectedCertificate"
            ? filterValue
            : this.state.selectedCertificate,
      },
      {
        fieldName: "fluorescence_intensity",
        value:
          filter == "selectedFlourescene"
            ? filterValue
            : this.state.selectedFlourescene,
      },
      {
        fieldName: "polish",
        value:
          filter == "selectedPolish" ? filterValue : this.state.selectedPolish,
      },
      {
        fieldName: "symmetry",
        value:
          filter == "selectedSymmetry"
            ? filterValue
            : this.state.selectedSymmetry,
      },
      {
        fieldName: "girdle_thin",
        value:
          filter == "selectedGirdleThin"
            ? filterValue
            : this.state.selectedGirdleThin,
      },
      {
        fieldName: "culet_size",
        value:
          filter == "selectedCuletSize"
            ? filterValue
            : this.state.selectedCuletSize,
      },
      {
        fieldName: "fancy_intensity",
        value:
          filter == "selectedFancyIntensity"
            ? filterValue
            : this.state.selectedFancyIntensity,
      },
      {
        fieldName: "fancy_overtone",
        value:
          filter == "selectedOvertone"
            ? filterValue
            : this.state.selectedOvertone,
      },
      {
        fieldName: "fancy_color",
        value:
          filter == "selectedFancyColor"
            ? filterValue
            : this.state.selectedFancyColor,
      },
    ];

    let newFilterData = [];
    postData.filterBy.forEach((el) => {
      if (el.value.length) {
        newFilterData.push(el);
      }
    });

    if (isReset == true) {
      postData.filterBy = [];
      postData.rangeFilter = [];
    } else {
      postData.filterBy = newFilterData;
      if (rangfilter) {
        postData.rangeFilter = rangfilter;
      }
    }

    if (postData.filterBy.length > 0) {
      postData.page = parseInt(1);
      postData.offset = 0;
    }

    if (postData.rangfilter > 0) {
      postData.page = parseInt(1);
      postData.offset = 0;
    }

    this.setState({ loader: true });

    let { diamond_image } = this.state;

    axios
      .post("/diamond/website", this.state.reqData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token:
            localStorage.getItem("__divinaRetailerToken__") !== null
              ? localStorage.getItem("__divinaRetailerToken__")
              : "",
        },
      })
      .then((res) => {
        let data = res.data.data;
        if (data.length) {
          data.forEach((el) => {
            el.hidden = false;
            el.shortlist = false;
            el.compare = false;
            el.checked = false;
            el.threedvideo = Array.isArray(el.three_d_file)
              ? el.three_d_file.length == 0
                ? false
                : true
              : el.three_d_file
              ? true
              : false;
            el.diamond_image = Array.isArray(el.diamond_image)
              ? el.diamond_image.length
                ? el.diamond_image
                : diamond_image
              : diamond_image;

            el.selectedImage = Array.isArray(el.diamond_image)
              ? el.diamond_image.length
                ? el.diamond_image[0]
                : diamond_image[0]
              : diamond_image[0];
            el.selectedImageIndex = 0;
          });
        }

        let noMoreData = data.length == res.data.totalItem ? true : false;
        this.setState({
          items: data,
          totalItem: res.data.totalItem,
          itemsClone: data,
          totalItemClone: res.data.totalItem,
          loader: false,
          noMoreData: noMoreData,
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  rangfilter = (fieldName, position, index, value, name) => {
    let rangfilter = this.state[name];
    rangfilter[index].value = value;

    this.setState({ [name]: rangfilter });

    if (
      fieldName == "girdle_thin" ||
      fieldName == "culet_size" ||
      fieldName == "fancy_intensity"
    ) {
      this.handleFilterAdvance(value, position, fieldName, index, rangfilter);
      return;
    } else {
      let defaultData =
        name == "rangfilter"
          ? rangFilterDefultValue
          : rangAdvanceFilterDefultValue;
      if (
        value == "" &&
        position == "to" &&
        rangfilter[index - 1].value == ""
      ) {
        this.setRangeFilterData(name, defaultData);
      } else if (
        value == "" &&
        position == "from" &&
        rangfilter[index + 1].value == ""
      ) {
        this.setRangeFilterData(name, defaultData);
      } else if (
        value !== "" &&
        position == "to" &&
        rangfilter[index - 1].value !== ""
      ) {
        this.setRangeFilterData(name, defaultData);
      } else if (
        value !== "" &&
        position == "from" &&
        rangfilter[index + 1].value !== ""
      ) {
        this.setRangeFilterData(name, defaultData);
      }
    }
  };

  setRangeFilterData = (name, defaultData) => {
    let { rangfilter, rangAdvancefilter } = this.state;
    let newFilter = [];
    if (rangfilter) {
      for (let i = 0; i < rangfilter.length; i++) {
        if (rangfilter[i].position == "to") {
          // if (rangfilter[i].keyName == "price") {
          //   continue;
          // }
          newFilter.push({
            key: rangfilter[i].keyName,
            from: rangfilter[i - 1].value
              ? parseFloat(rangfilter[i - 1].value)
              : parseFloat(rangFilterDefultValue[i - 1].value),
            to: rangfilter[i].value
              ? parseFloat(rangfilter[i].value)
              : parseFloat(rangFilterDefultValue[i].value),
          });
        }
      }
    }

    if (rangAdvancefilter) {
      for (let i = 0; i < rangAdvancefilter.length; i++) {
        if (rangAdvancefilter[i].position == "to") {
          if (
            rangAdvancefilter[i].keyName == "girdle_thin" ||
            rangAdvancefilter[i].keyName == "culet_size"
          ) {
            continue;
          }

          newFilter.push({
            key: rangAdvancefilter[i].keyName,
            from: rangAdvancefilter[i - 1].value
              ? parseFloat(rangAdvancefilter[i - 1].value)
              : parseFloat(rangAdvanceFilterDefultValue[i - 1].value),
            to: rangAdvancefilter[i].value
              ? parseFloat(rangAdvancefilter[i].value)
              : parseFloat(rangAdvanceFilterDefultValue[i].value),
          });
        }
      }
    }

    this.fetchData(false, false, newFilter);
  };

  handleInput = (name, value) => {
    this.setState({ [name]: value });
  };

  loadMoreData = () => {
    this.setState({ loader: true });
    let reqData = { ...this.state.reqData };
    reqData.page = parseInt(reqData.page) + 1;
    reqData.offset = (reqData.page - 1) * reqData.limit;
    axios
      .post("/diamond/website", reqData, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token:
            localStorage.getItem("__divinaRetailerToken__") !== null
              ? localStorage.getItem("__divinaRetailerToken__")
              : "",
        },
      })
      .then((res) => {
        let prevItems = [...this.state.items];
        let previtemsClone = [...this.state.itemsClone];
        let data = res.data.data;
        let { diamond_image, activeSort } = this.state;
        if (data.length) {
          data.forEach((el) => {
            el.hidden = false;
            el.shortlist = false;
            el.compare = false;
            el.checked = false;
            el.threedvideo = Array.isArray(el.three_d_file)
              ? el.three_d_file.length == 0
                ? false
                : true
              : el.three_d_file
              ? true
              : false;
            el.diamond_image = Array.isArray(el.diamond_image)
              ? el.diamond_image.length
                ? el.diamond_image
                : diamond_image
              : diamond_image;

            el.selectedImage = Array.isArray(el.diamond_image)
              ? el.diamond_image.length
                ? el.diamond_image[0]
                : diamond_image[0]
              : diamond_image[0];
            el.selectedImageIndex = 0;
          });
        }

        prevItems.push(...data);
        previtemsClone.push(...data);
        let noMoreData =
          previtemsClone.length == res.data.totalItem ? true : false;

        if (activeSort !== null) {
          console.log(activeSort);
          if (activeSort.sort === "desc") {
            prevItems.sort(function (b, a) {
              a[activeSort.key] = a[activeSort.key].toString();
              b[activeSort.key] = b[activeSort.key].toString();
              return a[activeSort.key].localeCompare(b[activeSort.key]);
            });
          } else {
            prevItems.sort(function (a, b) {
              a[activeSort.key] = a[activeSort.key].toString();
              b[activeSort.key] = b[activeSort.key].toString();
              return a[activeSort.key].localeCompare(b[activeSort.key]);
            });
          }
        }

        this.setState({
          items: prevItems,
          reqData: reqData,
          itemsClone: previtemsClone,
          loader: false,
          noMoreData: noMoreData,
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  };

  enterKeyHandler = (e) => {
    if (e.key === "Enter") {
      this.setRangeFilterData();
    }
  };

  handleFilter = (stateArr, value) => {
    let selected = [...this.state[stateArr]];
    let otherColor = [
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    let otherClarity = ["I1", "I2", "I3"];
    if (!this.isSelected(stateArr, value)) {
      if (stateArr == "selectedShapes") {
        selected.push(value.toLowerCase());

        if (value.toLowerCase() == "cushion") {
          selected.push("cushion brilliant");
          selected.push("cushion modified");
          selected.push("cushion");
        }
      } else {
        if (stateArr == "selectedColors") {
          if (value == "M+") {
            selected.push(...otherColor);
          }
        }

        if (stateArr == "selectedClarity") {
          if (value == "I+") {
            selected.push(...otherClarity);
          }
        }
        selected.push(value);
      }
    } else {
      if (stateArr == "selectedShapes") {
        if (value.toLowerCase() == "cushion") {
          selected.splice(selected.indexOf("cushion brilliant"), 1);
          selected.splice(selected.indexOf("cushion"), 1);
          selected.splice(selected.indexOf("cushion modified"), 1);
        }

        selected.splice(selected.indexOf(value.toLowerCase()), 1);
      } else {
        if (stateArr == "selectedColors") {
          if (value == "M+") {
            otherColor.forEach((el) => {
              selected.splice(selected.indexOf(el), 1);
            });
          }
        }

        if (stateArr == "selectedClarity") {
          if (value == "I+") {
            otherClarity.forEach((el) => {
              selected.splice(selected.indexOf(el), 1);
            });
          }
        }

        selected.splice(selected.indexOf(value), 1);
      }
    }

    this.setState({ [stateArr]: selected });
    this.fetchData(stateArr, selected);
  };

  handleMultielectFilter = (stateArr, value) => {
    let selected = [];
    if (value == null) {
      value = [];
    } else {
      value.forEach((el) => {
        selected.push(el.value);
      });
    }

    this.setState({ [stateArr]: selected });

    if (selected == []) {
      this.fetchData();
    } else {
      this.fetchData(stateArr, selected);
    }
  };

  handleFilterAdvance = (value, position, name, index, rangfilter) => {
    let from = 0;
    let to = 0;

    if (position == "from") {
      from = parseInt(value);
      to = parseInt(
        rangfilter[index + 1].value ? rangfilter[index - 1].value : 0
      );
    } else {
      from = parseInt(
        rangfilter[index - 1].value ? rangfilter[index - 1].value : 0
      );
      to = parseInt(value);
    }

    let selectedData = [];
    if (from < to) {
      for (let i = from; i <= to; i++) {
        if (rangfilter[index].option[i].key !== "Any") {
          selectedData.push(rangfilter[index].option[i].key);
        }
      }
    } else {
      for (let i = from; i >= to; i--) {
        if (rangfilter[index].option[i].key !== "Any") {
          selectedData.push(rangfilter[index].option[i].key);
        }
      }
    }

    let { selectedGirdleThin, selectedCuletSize, selectedFancyIntensity } =
      this.state;
    if (name == "girdle_thin") {
      selectedGirdleThin = selectedData;
      if (selectedData.length > 0 && selectedData[0] == "Any") {
        this.fetchData();
      } else {
        this.fetchData("selectedGirdleThin", selectedData);
      }
    }

    if (name == "culet_size") {
      selectedCuletSize = selectedData;
      if (selectedData.length > 0 && selectedData[0] == "Any") {
        this.fetchData();
      } else {
        this.fetchData("selectedCuletSize", selectedData);
      }
    }

    if (name == "fancy_intensity") {
      selectedFancyIntensity = selectedData;
      if (selectedData.length > 0 && selectedData[0] == "Any") {
        this.fetchData();
      } else {
        this.fetchData("selectedFancyIntensity", selectedData);
      }
    }

    this.setState({
      selectedGirdleThin,
      selectedCuletSize,
      selectedFancyIntensity,
    });
  };

  toggleShowMoreShapes = () => {
    let currentState = this.state.showMoreShapes;
    this.setState({ showMoreShapes: !currentState });
  };

  toggleView = (view) => {
    this.setState({ diamondsView: view });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let userData = this.state.token
      ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
      : {};
    let permissions = [];
    let filter = this.state.filterOptions;
    if (!_.isEmpty(userData)) {
      permissions = userData.permissions;
      let tmpdata = [];
      permissions.forEach((el, i) => {
        if (filter[el.key]) {
          tmpdata = [];
          let colorFlag = false;
          let clarityFlag = false;
          filter[el.key].forEach((e, index) => {
            el.data.forEach((data) => {
              if (data.displayName == e.name && data.value) {
                console.log(e);
                tmpdata.push(e);
              } else if (
                el.key == "color" &&
                otherColor.indexOf(data.displayName) > -1 &&
                data.value
              ) {
                colorFlag = true;
              } else if (
                el.key == "clarity" &&
                otherClarity.indexOf(data.displayName) > -1 &&
                data.value
              ) {
                clarityFlag = true;
              }
            });
          });

          if (colorFlag) {
            tmpdata.push({ name: "M+", value: "M+" });
          }

          if (clarityFlag) {
            tmpdata.push({ name: "I+", value: "I+" });
          }

          filter[el.key] = tmpdata;
        }
      });

      // this.setState({ filterOptions: filter });
    }

    this.fetchData();
  }

  changeItem = (items, hidden, compare, shortlist) => {
    let data = [];
    let count = 0;
    items.forEach((el) => {
      if (hidden !== "") {
        if (el.hidden == hidden) {
          data.push(el);
          count++;
        }
      }

      if (compare !== "") {
        if (el.compare == compare) {
          data.push(el);
          count++;
        }
      }

      if (shortlist !== "") {
        if (el.shortlist == shortlist) {
          data.push(el);
          count++;
        }
      }
    });

    return {
      data: data,
      count: count,
    };
  };

  changeViewState = (name, value) => {
    let { items, totalItem, itemsClone, totalItemClone, isHidden } = this.state;
    if (name == "isHidden" && value == false) {
      this.setState({
        isCompare: value,
        isHidden: value,
        items: itemsClone,
        totalItem: totalItemClone,
        itemsClone,
      });
    }

    if (name == "isHidden" && value == true) {
      items = this.changeItem(itemsClone, !value, "", "");
      totalItem = totalItemClone - (itemsClone.length - items.count);
      this.setState({
        isHidden: value,
        items: itemsClone,
        totalItem,
        items: items.data,
      });
    }

    if (name == "isCompare" && value == true) {
      items = this.changeItem(itemsClone, "", value, "");
      totalItem = items.count;
      this.setState({
        isCompare: value,
        isHidden: value,
        totalItem,
        items: items.data,
      });
    }

    if (name == "isCompare" && value == false) {
      if (!isHidden) {
        this.setState({
          isCompare: value,
          items: itemsClone,
          totalItem: totalItemClone,
          itemsClone,
        });
      } else {
        items = this.changeItem(itemsClone, value, "", "");
        totalItem = totalItemClone - (itemsClone.length - items.count);
        this.setState({
          isCompare: value,
          items: itemsClone,
          totalItem,
          items: items.data,
        });
      }
    }
  };

  itemStatusChange = (e, name, index, value) => {
    e.stopPropagation();
    let { items, totalItem, itemsClone, isHidden, totalItemClone } = this.state;
    if (name == "hidden") {
      if (isHidden) {
        index = itemsClone.length - items.length;
        itemsClone[index][name] = value;
        itemsClone[index].checked = false;
        items = this.changeItem(itemsClone, !value);
        totalItem = totalItem - 1;
        this.setState({
          items: items.data,
          totalItem,
          itemsClone,
          checkAll: false,
        });
      } else {
        itemsClone[index][name] = value;
        this.setState({
          items: itemsClone,
          totalItem: totalItemClone,
          itemsClone,
        });
      }
    } else {
      itemsClone[index][name] = value;
      items[index][name] = value;
      this.setState({ itemsClone, items });
    }
  };

  showRow = (id) => {
    let { showRow } = this.state;
    if (showRow == id) {
      showRow = "";
    } else {
      showRow = id;
    }

    this.setState({ showRow });
  };

  checkedData = (event, data, index, value) => {
    event.stopPropagation();
    let { items, itemsClone } = this.state;
    items[index].checked = value;
    this.setState({ items });
  };

  checkAll = (value) => {
    let { items, itemsClone } = this.state;

    items.forEach((el) => {
      el.checked = value;
    });

    this.setState({ items, checkAll: value });
  };

  resetFilter = () => {
    let {
      selectedShapes,
      selectedColors,
      selectedClarity,
      selectedCut,
      selectedPolish,
      selectedSymmetry,
      selectedFlourescene,
      selectedCertificate,
      selectedGirdleThin,
      selectedCuletSize,
      selectedFancyColor,
      selectedOvertone,
      selectedFancyIntensity,
      rangfilter,
      rangAdvancefilter,
      fancyIntensityfilter,
    } = this.state;

    selectedShapes = [];
    selectedColors = [];
    selectedClarity = [];
    selectedCut = [];
    selectedPolish = [];
    selectedSymmetry = [];
    selectedFlourescene = [];
    selectedCertificate = [];
    selectedGirdleThin = [];
    selectedCuletSize = [];
    selectedFancyColor = [];
    selectedOvertone = [];
    selectedFancyIntensity = [];
    rangfilter = [...rangfilterClone];
    rangAdvancefilter = [...rangAdvancefilterClone];
    fancyIntensityfilter = [...fancyIntensityFilterClone];

    rangfilter.forEach((el) => {
      el.value = "";
    });

    rangAdvancefilter.forEach((el) => {
      el.value = "";
    });

    fancyIntensityfilter.forEach((el) => {
      el.value = "";
    });

    this.setState({
      selectedShapes,
      selectedColors,
      selectedClarity,
      selectedCut,
      selectedPolish,
      selectedSymmetry,
      selectedFlourescene,
      selectedCertificate,
      selectedGirdleThin,
      selectedCuletSize,
      selectedFancyColor,
      selectedOvertone,
      selectedFancyIntensity,
      rangfilter,
      rangAdvancefilter,
      fancyIntensityfilter,
    });

    this.fetchData(false, false, false, true);
  };

  dataFatch = () => {
    let data = `<tr>`;
    for (let i = 0; i < 10; i++) {
      data += `<td><Skeleton count={1} ></td>`;
    }
    data += `</tr>`;
    return data;
  };

  rename = (obj, key, newKey) => {
    if (_.includes(_.keys(obj), key)) {
      obj[newKey] = _.clone(obj[key], true);
      delete obj[key];
    }

    return obj;
  };

  changeImage = (i, index, el) => {
    let { items } = this.state;
    items[i].selectedImage = el;
    items[i].selectedImageIndex = index;
    this.setState({ items });
  };

  export = () => {
    let responseJson = [];

    if (this.state.checkAll) {
      responseJson = [...this.state.items];
    } else {
      this.state.items.forEach((el) => {
        if (el.checked) {
          responseJson = el;
        }
      });

      if (responseJson.length == 0) {
        responseJson = [...this.state.items];
      }
    }

    let newName = "";
    let newData = [];
    let datakeys = [];

    if (responseJson.length) {
      datakeys = Object.keys(responseJson[0]);
    }

    responseJson = responseJson.map(function (obj) {
      datakeys.forEach((key) => {
        if (keyName.indexOf(key) == -1) {
          delete obj[key];
        }
      });

      keyName.forEach((el) => {
        newName = el.replace(new RegExp("_", "g"), " ").toUpperCase();
        _this.rename(obj, el, newName);
      });
      return obj;
    });

    const data = responseJson;
    const fileName = "diamonds-" + new Date();
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });

    // responseJson = responseJson.map(function (obj) {
    //   // datakeys.forEach((key) => {
    //   //   if (keyName.indexOf(key) == -1) {
    //   //     delete obj[key];
    //   //   }
    //   // });

    //   keyName.forEach((el) => {
    //     newName = el.replace(new RegExp("_", "g"), " ").toUpperCase();
    //     _this.rename(obj, el, newName);
    //   });

    //   return obj;
    // });

    // const fileName = "diamonds-" + new Date();
    // const exportType = "csv";
    // exportFromJSON({ responseJson, fileName, exportType });
  };

  imageUrlModification = (url) => {
    if (url) {
      let newUrl = baseURL + url.toLowerCase();
      // let urlPart = url.split("/");
      // let name = urlPart[urlPart.length - 1];
      // let diamondName = name.split(".")[0];
      // let ext = name.split(".")[1].toLowerCase();
      // //let ext = name.split(".")[1];

      // urlPart.splice(urlPart.length - 1, 1);
      // url = urlPart.join("/");
      // let newUrl = url + "/" + diamondName + "." + ext;
      return newUrl;
    } else {
      return url;
    }
  };

  genratePDF = () => {
    let responseJson = [];

    if (this.state.checkAll) {
      responseJson = [...this.state.items];
    } else {
      this.state.items.forEach((el) => {
        if (el.checked) {
          responseJson.push(el);
        }
      });
    }

    if (responseJson.length == 0) {
      this.notify("danger", "Select a diamond", "2000");
    }

    this.setState({ isPdf: true, pdfData: responseJson });
  };

  closePDF = () => {
    this.setState({ isPdf: false, pdfData: [] });
  };

  dataSort = (key, type = "asc", index) => {
    let { items, tableHeader, activeSort } = this.state;
    tableHeader.forEach((el) => {
      el.sort = "asc";
    });

    activeSort = tableHeader[index];

    if (type === "desc") {
      tableHeader[index].sort = "asc";

      items.sort(function (a, b) {
        console.log(a[key]);
        console.log(b[key]);
        // a[key] = a[key].toString()
        // b[key] = b[key].toString()
        return a[key] - b[key];
      });
    } else {
      tableHeader[index].sort = "desc";
      items.sort(function (b, a) {
        // a[key] = a[key].toString()
        // b[key] = b[key].toString()
        return a[key] - b[key];
      });
    }

    this.setState({ items, tableHeader, activeSort });
  };

  render() {
    let token = this.state.token;
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <>
        <Header />
        <Bread />

        <div className="container-fluid">
          <div className="row justify-content-lg-between justify-content-center  align-items-center mt-3 ">
            <div className="col-md-6 text-lg-left text-center mb-3 mb-lg-0">
              <p className="fs-28 ft-cormo mb-0 pl-mt-3">Diamond Inventory</p>
            </div>

            <div className="col-md-6 text-lg-right">
              <div className="d-flex justify-content-lg-end justify-content-center align-items-center">
                <p
                  className="m-0 resetFilterBtn"
                  onClick={() => this.resetFilter()}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-refresh mr-2"></i>
                  <span>Reset Filter</span>
                </p>
                <p
                  className="p-0 m-0 ml-3 d-flex align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ hideFilter: !this.state.hideFilter })
                  }
                >
                  <i
                    className={
                      this.state.hideFilter
                        ? "fs-28 fa fa-angle-down"
                        : "fs-28 fa fa-angle-up"
                    }
                    aria-hidden="true"
                  ></i>
                  <span className="p-0 m-0 ml-2">
                    {this.state.hideFilter ? "Show" : "Hide"} Filters
                  </span>
                </p>
              </div>
            </div>
          </div>

          {this.state.hideFilter == false ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="d-flex justify-content-between">
                    <h6>Shapes</h6>
                    {this.state.filterOptions.shape.length > 18 ? (
                      <div
                        className="text-center d-lg-none hover-pointer"
                        onClick={() => this.toggleShowMoreShapes()}
                      >
                        <span className="text-center d-block ">
                          <i
                            className={
                              "fa fa-angle-" +
                              (this.state.showMoreShapes ? "up" : "down")
                            }
                            aria-hidden="true"
                          ></i>
                          <span className="ml-2">
                            {this.state.showMoreShapes ? "Less" : "More"}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-11">
                  <div
                    className={
                      "d-flex flex-wrap " +
                      (this.state.showMoreShapes ? "" : "not-show-more")
                    }
                  >
                    {this.state.filterOptions.shape.map((shape, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() =>
                            this.handleFilter("selectedShapes", shape.name)
                          }
                          className={
                            this.isSelected("selectedShapes", shape.name)
                              ? "selected-shapes mr-1 mt-1 px-1"
                              : "mr-1 mt-1 px-1"
                          }
                        >
                          <div className="d-flex flex-column align-items-center w-100">
                            <img
                              src={shape.imageUrl}
                              fill="#fff"
                              className="shape-img"
                            />
                            <small>{shape.name}</small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {this.state.filterOptions.shape.length > 18 ? (
                  <div
                    className="text-center col-md-1 mt-2 d-none d-lg-flex hover-pointer flex-column show-more"
                    onClick={() => this.toggleShowMoreShapes()}
                  >
                    <span className="text-center d-block ">
                      {this.state.showMoreShapes ? "Less" : "More"}
                    </span>
                    <i
                      className={
                        "fa fa-angle-" +
                        (this.state.showMoreShapes ? "up" : "down")
                      }
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}

                <div className="col-md-12 mt-4">
                  <h6>Range Filter</h6>
                </div>

                {this.state.rangfilter.map((el, i) => {
                  return (
                    <div className="col-md-2 col-6 mt-2 mt-lg-0" key={i}>
                      <small>{el.displayName}</small>
                      <input
                        type="number"
                        min={0}
                        placeholder={
                          (rangFilterDefultValue[i].keyName !== "weight"
                            ? "$"
                            : "") + rangFilterDefultValue[i].value
                        }
                        className="form-control bg-dark text-white filterInput mt-2"
                        onKeyDown={(e) => this.enterKeyHandler(e)}
                        value={el.value}
                        onChange={(e) =>
                          this.rangfilter(
                            el.keyName,
                            el.position,
                            i,
                            e.target.value,
                            "rangfilter"
                          )
                        }
                      />
                    </div>
                  );
                })}

                <div className="col-md-3 mt-3">
                  <h6>
                    Color (
                    <span
                      style={{
                        color: !this.state.isFancy ? "#198aff" : "",
                        cursor: "pointer",
                        textTransform: !this.state.isFancy ? "uppercase" : "",
                      }}
                      onClick={() =>
                        this.setState({ isFancy: !this.state.isFancy })
                      }
                    >
                      White
                    </span>{" "}
                    |{" "}
                    <span
                      style={{
                        color: this.state.isFancy ? "#198aff" : "",
                        cursor: "pointer",
                        textTransform: this.state.isFancy ? "uppercase" : "",
                      }}
                      onClick={() =>
                        this.setState({ isFancy: !this.state.isFancy })
                      }
                    >
                      Fancy
                    </span>
                    )
                  </h6>
                  {/* <button value="D" className="bg-secondary text-white px-3 py-2 rounded">D</button> */}
                  {this.state.isFancy ? (
                    <Select
                      isMulti
                      name="fancy-colors"
                      options={this.state.filterOptions.fancyColor}
                      className="basic-multi-select"
                      classNamePrefix="Fancy Color"
                      placeholder="Fancy Color"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary25: "neutral0",
                        },
                      })}
                      onChange={(e) =>
                        this.handleMultielectFilter("selectedFancyColor", e)
                      }
                      closeMenuOnSelect={false}
                    />
                  ) : (
                    this.state.filterOptions.color.map((e, i) => {
                      return (
                        <button
                          key={i}
                          value={e.value}
                          onClick={() =>
                            this.handleFilter("selectedColors", e.value)
                          }
                          className={
                            this.isSelected("selectedColors", e.value)
                              ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                              : "px-3 py-2 rounded filterBtn"
                          }
                        >
                          {e.name}
                        </button>
                      );
                    })
                  )}
                </div>

                {this.state.isFancy ? (
                  <div className="col-md-6 mt-3">
                    <h6>Intensity</h6>
                    <div className="d-flex">
                      {this.state.fancyIntensityfilter.map((el, i) => {
                        return (
                          <div className="col-md-6 pl-0" key={i}>
                            <select
                              className="form-control filterSelect"
                              onKeyDown={(e) => this.enterKeyHandler(e)}
                              value={el.value}
                              onChange={(e) =>
                                this.rangfilter(
                                  el.keyName,
                                  el.position,
                                  i,
                                  e.target.value,
                                  "fancyIntensityfilter"
                                )
                              }
                            >
                              {el.option.map((e, index) => {
                                return (
                                  <option key={index} value={e.value}>
                                    {e.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}

                {this.state.isFancy ? (
                  <div className="col-md-3 mt-3">
                    <h6>Overtone</h6>
                    <Select
                      isMulti
                      name="fancy-overtone"
                      options={this.state.filterOptions.fancyOvertone}
                      className="basic-multi-select"
                      classNamePrefix="Fancy Color"
                      placeholder="Overtone"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary25: "neutral0",
                        },
                      })}
                      onChange={(e) =>
                        this.handleMultielectFilter("selectedOvertone", e)
                      }
                      closeMenuOnSelect={false}
                    />
                  </div>
                ) : null}

                <div className="col-md-4 mt-3">
                  <h6>Clarity</h6>
                  {this.state.filterOptions.clarity.map((e, i) => {
                    return (
                      <button
                        key={i}
                        value={e.value}
                        onClick={() =>
                          this.handleFilter("selectedClarity", e.value)
                        }
                        className={
                          this.isSelected("selectedClarity", e.value)
                            ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                            : "px-3 py-2 rounded filterBtn"
                        }
                      >
                        {e.name}
                      </button>
                    );
                  })}
                </div>

                <div className="col-md-2 px-lg-0 mt-3">
                  <h6>Cut</h6>
                  {this.state.filterOptions.cut_grade.map((e, i) => {
                    return (
                      <button
                        key={i}
                        value={e.value}
                        onClick={() =>
                          this.handleFilter("selectedCut", e.value)
                        }
                        className={
                          this.isSelected("selectedCut", e.value)
                            ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                            : "px-3 py-2 rounded filterBtn"
                        }
                      >
                        {e.name}
                      </button>
                    );
                  })}
                </div>

                <div className="col-md-2 mt-3">
                  <h6>Polish</h6>
                  {this.state.filterOptions.polish.map((e, i) => {
                    return (
                      <button
                        key={i}
                        value={e.value}
                        onClick={() =>
                          this.handleFilter("selectedPolish", e.value)
                        }
                        className={
                          this.isSelected("selectedPolish", e.value)
                            ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                            : "px-3 py-2 rounded filterBtn"
                        }
                      >
                        {e.name}
                      </button>
                    );
                  })}
                </div>

                <div className="col-md-2 mt-3">
                  <h6>Symmetry</h6>
                  {this.state.filterOptions.symmetry.map((e, i) => {
                    return (
                      <button
                        key={i}
                        value={e.value}
                        onClick={() =>
                          this.handleFilter("selectedSymmetry", e.value)
                        }
                        className={
                          this.isSelected("selectedSymmetry", e.value)
                            ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                            : "px-3 py-2 rounded filterBtn"
                        }
                      >
                        {e.name}
                      </button>
                    );
                  })}
                </div>

                <div className="col-md-2 mt-3">
                  <h6>Flourescence</h6>
                  {this.state.filterOptions.fluorescence_intensity.map(
                    (e, i) => {
                      return (
                        <button
                          key={i}
                          value={e.value}
                          onClick={() =>
                            this.handleFilter("selectedFlourescene", e.value)
                          }
                          className={
                            this.isSelected("selectedFlourescene", e.value)
                              ? "selected-shapes px-3 py-2 rounded filterBtn filterBtnSelected"
                              : "px-3 py-2 rounded filterBtn"
                          }
                        >
                          {e.name}
                        </button>
                      );
                    }
                  )}
                </div>

                <div
                  className={
                    this.state.isFancy
                      ? "col-md-10  mt-3 px-lg-0"
                      : "col-md-6  mt-3 px-lg-0"
                  }
                >
                  <h6>Certificate</h6>
                  <div className="d-flex flex-wrap">
                    {this.state.filterOptions.lab.map((e, i) => {
                      return (
                        <div
                          className={
                            this.state.isFancy
                              ? "col-md-1 col-3 px-0"
                              : "col-md-2 col-4 px-0"
                          }
                          key={i}
                        >
                          <input
                            type="checkbox"
                            onChange={() =>
                              this.handleFilter("selectedCertificate", e.value)
                            }
                            checked={
                              this.isSelected("selectedCertificate", e.value)
                                ? true
                                : false
                            }
                          />
                          <span className="ml-1">{e.name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="col-md-2 mt-3 align-self-center">
                  <h5
                    onClick={() =>
                      this.setState({
                        advanceFilterShow: !this.state.advanceFilterShow,
                      })
                    }
                    style={{ cursor: "pointer" }}
                    className="advanceFilterBtn d-flex align-items-center"
                  >
                    <i
                      className={
                        this.state.advanceFilterShow
                          ? "fs-28 fa fa-angle-up"
                          : "fs-28 fa fa-angle-down"
                      }
                      aria-hidden="true"
                    ></i>
                    <span className="ml-2"> Advance Filter</span>
                  </h5>
                </div>
              </div>

              {this.state.advanceFilterShow ? (
                <div
                  className={
                    this.state.advanceFilterShow
                      ? "row advanceFilterSection"
                      : "row advanceFilterSection"
                  }
                >
                  <div className="col-md-12 advanceFilterBox">
                    <h3>Advance Filter</h3>
                    <div className="d-flex flex-wrap">
                      {this.state.rangAdvancefilter.map((el, i) => {
                        return (
                          <div className="col-md-2 col-6 mt-4" key={i}>
                            <small>{el.displayName}</small>
                            {el.isSelect ? (
                              <>
                                <select
                                  className="form-control bg-dark filterSelect mt-2"
                                  onKeyDown={(e) => this.enterKeyHandler(e)}
                                  value={el.value}
                                  onChange={(e) =>
                                    this.rangfilter(
                                      el.keyName,
                                      el.position,
                                      i,
                                      e.target.value,
                                      "rangAdvancefilter"
                                    )
                                  }
                                >
                                  {el.option.map((e, index) => {
                                    return (
                                      <option key={index} value={e.value}>
                                        {e.key}
                                      </option>
                                    );
                                  })}
                                </select>
                              </>
                            ) : (
                              <input
                                type="number"
                                min={0}
                                placeholder={
                                  rangAdvanceFilterDefultValue[i].value
                                }
                                className="form-control bg-dark text-white filterInput mt-2"
                                onKeyDown={(e) => this.enterKeyHandler(e)}
                                value={el.value}
                                onChange={(e) =>
                                  this.rangfilter(
                                    el.keyName,
                                    el.position,
                                    i,
                                    e.target.value,
                                    "rangAdvancefilter"
                                  )
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {this.state.isPdf && this.state.pdfData.length > 0 ? (
            <div
              style={{
                position: "absolute",
                opacity: 0,
                left: 0,
                right: 0,
                width: "100%",
              }}
            >
              <DiamondPdf data={this.state.pdfData} fatch={this.closePDF} />
            </div>
          ) : null}

          <div className="d-flex flex-wrap mt-3 justify-content-between align-items-center">
            <div className="col-md-6 align-self-center">
              <div className="d-flex  align-items-center flex-wrap">
                <h5 className="m-0 d-inline-block">
                  <small>Total</small> {this.state.totalItem}{" "}
                  <small>Diamond Found</small>
                </h5>
                <div className="ml-2 d-lg-inline-block d-none">
                  <button
                    className="btn-dark"
                    style={{ cursor: "pointer", padding: "5px 10px" }}
                    onClick={this.state.items.length > 0 ? this.export : null}
                    disabled={this.state.items.length > 0 ? false : true}
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                  </button>
                  <button
                    className="btn-dark"
                    onClick={
                      this.state.items.length > 0 ? this.genratePDF : null
                    }
                    disabled={this.state.items.length > 0 ? false : true}
                    style={{ cursor: "pointer", padding: "5px 10px" }}
                  >
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </button>
                </div>

                <div className="d-lg-inline-block d-none">
                  <div className="d-flex ml-2">
                    <div className="form-group mx-2 mb-0">
                      <small className="" htmlFor="Compare">
                        Compare
                      </small>
                      <div>
                        <Switch
                          onChange={() =>
                            this.changeViewState(
                              "isCompare",
                              !this.state.isCompare
                            )
                          }
                          checked={this.state.isCompare}
                          height={20}
                          width={60}
                        />
                      </div>
                    </div>
                    <div className="form-group mx-2 mb-0">
                      <small className="" htmlFor="Hidden">
                        Hidden
                      </small>
                      <div>
                        <Switch
                          onChange={() =>
                            this.changeViewState(
                              "isHidden",
                              !this.state.isHidden
                            )
                          }
                          checked={this.state.isHidden}
                          height={20}
                          width={60}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-right d-none d-lg-block">
              <button
                className="btn-dark"
                onClick={() => this.toggleView("item")}
                style={{ cursor: "pointer", padding: "5px 10px" }}
              >
                <i className="fa fa-th" aria-hidden="true"></i>
              </button>

              <button
                className="btn-dark"
                onClick={() => this.toggleView("table")}
                style={{ cursor: "pointer", padding: "5px 10px" }}
              >
                <i className="fa fa-list" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div className="border my-4"></div>

          {this.state.diamondsView == "item" ? (
            <div className="diamonds-container container-fluid">
              <div className="row">
                {this.state.items.map((e, i) => {
                  return (
                    <div className="col-md-6 my-3" key={i}>
                      <div className="item-box">
                        <div className="d-flex flex-wrap align-items-center">
                          <div className="col-2 px-0">
                            <a
                              href={"/diamonds/diamond/" + e.our_stock}
                              target="_blank"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  e.diamond_image.length
                                    ? this.imageUrlModification(
                                        e.diamond_image[0]
                                      )
                                    : //: "https://web-images-divina.s3.ap-south-1.amazonaws.com/diamond-images/diamond-sample.png"
                                      sampleImage
                                }
                                //src={sampleImage}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                }}
                              />
                            </a>
                          </div>
                          <div className="col-10">
                            <a
                              href={"/diamonds/diamond/" + e.our_stock}
                              target="_blank"
                            >
                              <p className="ft-lato fs-28 my-0 text-white">
                                {e.our_stock ? e.our_stock : ""}
                              </p>
                              <p className="mb-1">
                                <div>
                                  {!token ? (
                                    <a href="/login">Login</a>
                                  ) : (
                                    <a
                                      href={e.certificate_filename}
                                      target="_blank"
                                    >
                                      {e.certificate}
                                    </a>
                                  )}
                                </div>
                              </p>
                            </a>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap mt-2  diamond-table">
                          <div className="col-6 pl-0">
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Shape</b>
                              </p>
                              <p className=" text-white mb-1 col-6 px-0">
                                {e.shape ? e.shape.toUpperCase() : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Weight</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.weight ? e.weight : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Color</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.color ? e.color : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Clarity</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.clarity ? e.clarity : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0 ">
                                <b>Cut</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0">
                                {e.cut_grade ? e.cut_grade : ""}
                              </p>
                            </div>
                          </div>
                          <div className="col-6 pl-0">
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Polish</b>
                              </p>
                              <p className=" text-white mb-1 col-6 pl-0">
                                {e.polish ? e.polish : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Sym.</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.symmetry ? e.symmetry : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Fluor.</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.fluorescence_intensity
                                  ? e.fluorescence_intensity
                                  : ""}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="text-white mb-1 col-6 pl-0">
                                <b>Lab</b>
                              </p>
                              <p className="text-white mb-1 col-6 pl-0 ">
                                {e.lab ? e.lab : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.diamondsView == "table" ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped diamond-table">
                      <thead className="diamond-table-head">
                        <tr>
                          <th>
                            {" "}
                            <input
                              type="checkbox"
                              checked={this.state.checkAll}
                              onChange={() =>
                                this.checkAll(!this.state.checkAll)
                              }
                            />{" "}
                          </th>
                          <th></th>
                          {
                            //console.log(this.state.tableHeader)
                          }

                          {this.state.tableHeader.map((el, index) => {
                            return el.isSort ? (
                              <th
                                key={index}
                                onClick={() =>
                                  this.dataSort(el.key, el.sort, index)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className={
                                    el.sort === "asc"
                                      ? "fa fa-long-arrow-down"
                                      : "fa fa-long-arrow-up"
                                  }
                                ></i>{" "}
                                {el.label}
                              </th>
                            ) : (
                              <th key={index}> {el.label}</th>
                            );
                          })}

                          {/* <th>LOT NO</th>
                          <th onClick={() => this.dataSort('shape')} style={{ cursor: "pointer" }}> SHAPE</th>
                          <th>CTS</th>
                          <th>COL</th>
                          <th>CLA</th>
                          <th>CUT</th>
                          <th>POL</th>
                          <th>SYM</th>
                          <th>FLO</th>
                          <th>AMOUNT</th>
                          <th>LAB</th>
                          <th>DEPTH%</th>
                          <th>TABLE%</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.items.length ? (
                          this.state.items.map((e, i) => {
                            return (
                              //  e.hidden == this.state.isHidden
                              <Fragment key={i}>
                                <tr
                                  onClick={() => this.showRow(e._id)}
                                  className="custom-tr"
                                >
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={(event) =>
                                        this.checkedData(
                                          event,
                                          e,
                                          i,
                                          !e.checked
                                        )
                                      }
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                      checked={e.checked}
                                      disabled={e.hidden}
                                    />
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        e.hidden
                                          ? "hideResult active"
                                          : "hideResult"
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      title="Hide from Result"
                                      onClick={(event) =>
                                        this.itemStatusChange(
                                          event,
                                          "hidden",
                                          i,
                                          !e.hidden
                                        )
                                      }
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-eye-slash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>

                                    <span
                                      className={
                                        e.compare
                                          ? "addToCompare ml-1 active"
                                          : "addToCompare ml-1"
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      title="Add To Compare"
                                      onClick={(event) =>
                                        this.itemStatusChange(
                                          event,
                                          "compare",
                                          i,
                                          !e.compare
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-exchange"
                                        aria-hidden="true"
                                      >
                                        {" "}
                                      </i>{" "}
                                    </span>
                                    <span
                                      className={
                                        e.threedvideo
                                          ? "threedvideo ml-1 active"
                                          : "threedvideo ml-1"
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      title="3D Video Avaiable"
                                    >
                                      <i
                                        className="fa fa-video-camera"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </td>
                                  <td>{e.our_stock}</td>
                                  <td className="text-uppercase">{e.shape}</td>
                                  <td>{e.weight}</td>
                                  <td>{e.color}</td>
                                  <td>{e.clarity}</td>
                                  <td>{e.cut_grade}</td>
                                  <td>{e.polish}</td>
                                  <td>{e.symmetry}</td>
                                  <td>{e.fluorescence_intensity}</td>
                                  <td>
                                    {token ? (
                                      new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(round(e.price, 2))
                                    ) : (
                                      <a href="/login">Login</a>
                                    )}
                                  </td>
                                  <td>{e.lab}</td>
                                  <td>{e.depth}%</td>
                                  <td>{e.table}%</td>
                                </tr>
                                {this.state.showRow == e._id ? (
                                  <tr>
                                    <td colSpan="15" className="p-0">
                                      <div
                                        className="container-fluid"
                                        style={{
                                          border: "1px solid",
                                          padding: "25px",
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-md-4">
                                            <table className="detailed-table table bg-black ">
                                              <tbody>
                                                <tr>
                                                  <td>LOT ID</td>
                                                  <td>{e.our_stock}</td>
                                                </tr>

                                                <tr>
                                                  <td>SHAPE</td>
                                                  <td className="text-uppercase">
                                                    {e.shape}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>CARAT</td>
                                                  <td>{e.weight}</td>
                                                </tr>

                                                <tr>
                                                  <td>COLOR</td>
                                                  <td>{e.color}</td>
                                                </tr>

                                                <tr>
                                                  <td>CLARITY</td>
                                                  <td>{e.clarity}</td>
                                                </tr>

                                                <tr>
                                                  <td>CUT</td>
                                                  <td>{e.cut_grade}</td>
                                                </tr>

                                                <tr>
                                                  <td>POLISH</td>
                                                  <td>{e.polish}</td>
                                                </tr>

                                                <tr>
                                                  <td>SYMMETRY</td>
                                                  <td>{e.symmetry}</td>
                                                </tr>
                                                <tr>
                                                  <td>FLUORESCENCE</td>
                                                  <td>
                                                    {e.fluorescence_color}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>CERT NO.</td>
                                                  <td>
                                                    {token ? (
                                                      <a
                                                        href={
                                                          e.certificate_filename
                                                        }
                                                        target="_blank"
                                                      >
                                                        {e.certificate}
                                                      </a>
                                                    ) : (
                                                      <a href="/login">Login</a>
                                                    )}
                                                  </td>
                                                </tr>

                                                <tr className="d-none">
                                                  <td>RAP OFF</td>
                                                  <td>
                                                    {token ? (
                                                      e.rapnet_discount ? (
                                                        e.rapnet_discount <
                                                        0 ? (
                                                          round(
                                                            e.rapnet_discount,
                                                            2
                                                          ) + "%"
                                                        ) : (
                                                          "-" +
                                                          round(
                                                            e.rapnet_discount,
                                                            2
                                                          ) +
                                                          "%"
                                                        )
                                                      ) : (
                                                        0
                                                      )
                                                    ) : (
                                                      <a href="/login">Login</a>
                                                    )}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>US$/CT</td>
                                                  <td>
                                                    {token ? (
                                                      e.cash_price ? (
                                                        new Intl.NumberFormat(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "USD",
                                                          }
                                                        ).format(
                                                          round(e.cash_price, 2)
                                                        )
                                                      ) : null
                                                    ) : (
                                                      <a href="/login">Login</a>
                                                    )}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>AMOUNT</td>
                                                  <td>
                                                    {token ? (
                                                      e.price ? (
                                                        new Intl.NumberFormat(
                                                          "en-US",
                                                          {
                                                            style: "currency",
                                                            currency: "USD",
                                                          }
                                                        ).format(
                                                          round(e.price, 2)
                                                        )
                                                      ) : null
                                                    ) : (
                                                      <a href="/login">Login</a>
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="col-md-4">
                                            <table className="detailed-table table bg-black ">
                                              <tbody>
                                                <tr>
                                                  <td>LAB</td>
                                                  <td>{e.lab}</td>
                                                </tr>

                                                <tr>
                                                  <td>DEPTH%</td>
                                                  <td>
                                                    {e.depth
                                                      ? e.depth + "%"
                                                      : ""}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>TABLE%</td>
                                                  <td>
                                                    {e.table
                                                      ? e.table + "%"
                                                      : ""}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>MEASURE</td>
                                                  <td>
                                                    {
                                                      e[
                                                        "length x width x height"
                                                      ]
                                                    }
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>GIRDLE%</td>
                                                  <td>
                                                    {e.girdle
                                                      ? e.girdle + "%"
                                                      : ""}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>GIRDLE</td>
                                                  <td>{e.girdle_thin}</td>
                                                </tr>

                                                <tr>
                                                  <td>CULET</td>
                                                  <td>{e.culet_size}</td>
                                                </tr>

                                                <tr>
                                                  <td>SHADE</td>
                                                  <td>{}</td>
                                                </tr>

                                                <tr>
                                                  <td>AVAILABLE</td>
                                                  <td>{e.availability}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="row">
                                              {e.diamond_image.length ? (
                                                <div className="col-md-12 text-center">
                                                  <img
                                                    src={this.imageUrlModification(
                                                      e.diamond_image[0]
                                                    )}
                                                    //src={sampleImage}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                <div className="col-md-12 text-center">
                                                  <img
                                                    src={sampleImage}
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            <div className="row mt-3">
                                              {e.diamond_image.length ? (
                                                e.diamond_image.map(
                                                  (el, index) => {
                                                    return (
                                                      <div
                                                        className="col-md-3 text-center"
                                                        key={index}
                                                      >
                                                        {console.log(el)}
                                                        <div
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          className={
                                                            e.selectedImageIndex ==
                                                            index
                                                              ? "thum-img-container active"
                                                              : "thum-img-container"
                                                          }
                                                          onClick={() =>
                                                            this.changeImage(
                                                              i,
                                                              index,
                                                              el
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={this.imageUrlModification(
                                                              el
                                                            )}
                                                            // src={sampleImage}
                                                            className="img-fluid"
                                                            // alt=""
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <>
                                                  <div className="col-md-4">
                                                    <img
                                                      src={sampleImage}
                                                      className="img-fluid"
                                                      alt=""
                                                    />
                                                  </div>
                                                </>
                                              )}
                                            </div>

                                            <div className="row">
                                              <div className="col-md-12">
                                                <a
                                                  target="_blank"
                                                  href={
                                                    "/diamonds/diamond/" +
                                                    e.our_stock
                                                  }
                                                >
                                                  <button className="btn btn-block bg-dark mt-4 text-white">
                                                    View Details
                                                  </button>
                                                </a>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-5">
                                            {token == null ? (
                                              <button className="btn btn-block bg-dark text-white">
                                                LOGIN FOR PRICE &amp;
                                                CERTIFICATE DETAILS
                                              </button>
                                            ) : null}
                                            <div className="row mt-3">
                                              <div className="col-md-6">
                                                {this.state.token ? (
                                                  <a
                                                    href={`https://api.whatsapp.com/send?phone=+19179579177&text=Hey!, I want to enquire about the Diamond ${e.our_stock}
                                                  Name: ${this.state.userdata.name}
                                                  Company:${this.state.userdata.business_name}
                                                  Contact Number: ${this.state.userdata.mobile_number}
                                                  Email ID: ${this.state.userdata.email}
                                                  Please call me or email me on the same`}
                                                    target="_blank"
                                                  >
                                                    <button className="btn btn-block btn-outline-dark text-white">
                                                      SEND AN ENQUIRY
                                                    </button>
                                                  </a>
                                                ) : (
                                                  <button
                                                    className="btn btn-block btn-outline-dark text-white"
                                                    onClick={() =>
                                                      this.notify(
                                                        "danger",
                                                        "Please Login First",
                                                        "2000"
                                                      )
                                                    }
                                                  >
                                                    SEND AN ENQUIRY
                                                  </button>
                                                )}
                                              </div>
                                              <div className="col-md-6 px-0">
                                                {this.state.token ? (
                                                  <a
                                                    href={`https://api.whatsapp.com/send?phone=+19179579177&text=Hey!, I want to 24hr hold on the Diamond ${e.our_stock}
                                                  Name: ${this.state.userdata.name}
                                                  Company:${this.state.userdata.business_name}
                                                  Contact Number: ${this.state.userdata.mobile_number}
                                                  Email ID: ${this.state.userdata.email}
                                                  Please call me or email me on the same`}
                                                    target="_blank"
                                                  >
                                                    <button className="btn btn-block btn-outline-dark text-white">
                                                      REQUEST 24 HOUR HOLD
                                                    </button>
                                                  </a>
                                                ) : (
                                                  <button
                                                    className="btn btn-block btn-outline-dark text-white"
                                                    onClick={() =>
                                                      this.notify(
                                                        "danger",
                                                        "Please Login First",
                                                        "2000"
                                                      )
                                                    }
                                                  >
                                                    REQUEST 24 HOUR HOLD
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}
                              </Fragment>
                            );
                          })
                        ) : this.state.loader ? (
                          <>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                              <td>
                                <Skeleton count={1} />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr className="text-center">
                            <td colSpan="14">
                              <p>
                                SORRY, NO DIAMONDS MATCH YOUR CRITERIA. PLEASE
                                ADJUST THE FILTERS OR CALL +1 212-575-4600 FOR
                                PERSONALIZED ASSISTANCE.
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="container">
            <div className="d-flex justify-content-center 3 mb-5">
              {!this.state.noMoreData ? (
                <button className="btn btn-dark" onClick={this.loadMoreData}>
                  {this.state.loader ? (
                    <Loader
                      type="Puff"
                      color="#fff"
                      height={50}
                      width={50}
                      timeout={3000} //3 secs
                    />
                  ) : (
                    "Show More Diamonds"
                  )}
                </button>
              ) : null}
            </div>
          </div>

          {/* <div className="container text-center 3 ">
            <p className="fs-35 ft-cormo">About Our Diamonds</p>
            <p className="text-center fs-20 fw-200 ft-lato">
              The diamonds you will find in this diamond search can be purchased
              loose, or set into an engagement ring or jewellery piece. All the
              diamonds listed here are GIA graded, certified and ethically
              sourced. Images, videos and sourcing location are available for
              most diamonds, upon request. When shopping for a diamond through
              our diamond search, we suggest you first consider the diamond
              shape and your price point. The shapes available on our diamond
              search include round brilliant, cushion, oval, emerald, pear,
              princess or square, marquise, asscher, radiant, heart and
              hexagonal. However, we can source a whole host of different
              diamond shapes and cuts from antique cuts, like rose cut or the
              Old European to fully custom-cut shapes.
            </p>
          </div> */}
        </div>
        <Footer />
      </>
    );
  }
}
