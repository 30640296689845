import React, { Component, Fragment } from "react";
import Footer from "./common/Footer";
import Header from "./common/Header";

export default class NotFound extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="container-fluid px-0">
          <div className="row pb-5 mx-0" style={{ paddingTop: "150px" }}>
            <div className="col-md-12 pt-5 px-0 text-center">
              <h1 className="display-1 contact-heading">Item not found</h1>
              <h4
                className=""
                style={{ fontFamily: "Cormorant Garamond, serif" }}
              >
                The item that you are looking for does not exist
              </h4>
            </div>
            <div className="col-md-12 text-center text-lg-left">
              <img src="/Divina_Logo.svg" className="img-fluid" width="330px" />
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
