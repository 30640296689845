import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import axios from "../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
let _this = "";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };

    _this = this;

    if (
      localStorage.getItem("__divinaRetailerData__") !== null &&
      localStorage.getItem("__divinaRetailerToken__") !== null
    ) {
      _this.props.history.goBack();
    }
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  enterKeyHandler = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  login = () => {
    let { email, password } = this.state;

    let data = {
      password: password,
      email: email,
    };

    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }

    if (data.password == "") {
      _this.notify("danger", "Password is required", "2000");
      return;
    }

    axios
      .post("/retailer/login", data)
      .then((res) => {
        let data = res.data.data;
        if (res.data.status) {
          this.notify("success", "Logged in successfully", "2000");
          localStorage.setItem("__divinaRetailerData__", JSON.stringify(data));
          localStorage.setItem("__divinaRetailerToken__", res.data.token);
          // _this.props.history.goBack();
          this.props.history.push("/");
        } else {
          let msg = res.data.message;
          this.notify("danger", msg, "2000");
        }
      })
      .catch((err) => {
        this.notify("danger", err.message, "2000");
      });
  };

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };
  render() {
    if (localStorage.getItem("__divinaRetailerToken__")) {
      return <Redirect to={this.props.location.state?.from} />;
    }
    return (
      <Fragment>
        {/* <Header /> */}
        <Bread />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "100vh" }}
        >
          <div className="container mb-5 mt-5" style={{ flex: 1 }}>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-7">
                <div
                  className="login-box"
                  style={{ padding: "50px", background: "#232323a6" }}
                >
                  <div className="text-center">
                    <h1>Login</h1>
                  </div>
                  <div className="form-group mt-5">
                    <div className="">
                      <label>Email Address (Max 100 characters):</label>
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          this.fatchData("email", e.target.value)
                        }
                        value={this.state.email}
                        placeholder={"Email"}
                        className="form-control"
                        type="email"
                        onKeyDown={(e) => this.enterKeyHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="">
                      <label>Password:</label>
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          this.fatchData("password", e.target.value)
                        }
                        value={this.state.password}
                        placeholder={"Password"}
                        className="form-control"
                        type="password"
                        onKeyDown={(e) => this.enterKeyHandler(e)}
                      />
                    </div>
                  </div>
                  <small>Password must be 8-30 characters</small>
                  <div className="d-flex flex-wrap justify-content-sm-between justify-content-center align-items-center mt-3">
                    <button
                      className="btn btn-sm btn-primary mb-3 mb-sm-0"
                      onClick={this.login}
                    >
                      Login
                    </button>
                    <div>
                      <p className="mb-0">
                        <Link to="/create-account" className="text-white">
                          Register
                        </Link>{" "}
                        |{" "}
                        <Link to="/forgot-password" className="text-white">
                          Forgot Password
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
