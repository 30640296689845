

//local
//export const baseURL = "http://localhost:8080/";
//export const baseURL = "http://localhost:80/";


//uat
//export const baseURL = "https://divina-api.codioticdemo.com/";


//production
export const baseURL = "https://api.divinainc.com/";
