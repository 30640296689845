import React, { Component, Fragment } from "react";
import "../../style.css";
import logo from "../../assets/v-WHITE.png";
import { Link, Redirect } from "react-router-dom";
import axios from "../../config/Axios";
import Sidebar from "./Sidebar";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../../constant/defaultValues";

export default class Header extends Component {
  constructor(props) {
    super(props);
    let tmppath = window.location.pathname.split("/");
    console.log(window.location.pathname);
    tmppath.splice(0, 1);

    // if (tmppath.length > 2 && tmppath[0] !== "estate-jewelry") {
    //   tmppath.splice(tmppath.length - 2, 1);
    // }

    if (tmppath[0] == "reset-password") {
      tmppath.splice(1, 1);
    }

    let path = [];

    tmppath.forEach((el) => {
      path.push({
        path: el.toUpperCase(),
        url: "",
      });
    });

    if (tmppath.length == 2) {
      path[0].url = "/" + tmppath[0];
    } else if (tmppath.length == 3) {
      path[0].url = "/" + tmppath[0];
      path[1].url = "/" + tmppath[0] + "/" + tmppath[1];
    }

    this.state = {
      selectedcategory: "",
      search: "",
      searchOption: [],
      searchOptionShow: false,
      path: path,
      logout:
        localStorage.getItem("__divinaRetailerToken__") == null ? true : false,
      sidebarOpen: false,
      windowWidth: window.innerWidth,
      placeholder: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    return state;
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    this.categories();
  }
  categories = () => {
    const requestBody = {
      offset: "",
      limit: "10",
      query: "",
      params: [],
      page: "1",
      orderBy: "",
      filterBy: [{}],
      forExport: "0",
    };
    axios
      .post(`${baseURL}custom-category/website`, requestBody)
      .then((response) => {
        const categories = response.data.data;
        this.setState({ categories });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  search = (value) => {
    if (value == "") {
      this.setState({ searchOption: [], searchOptionShow: false, search: "" });
      return;
    }
    const { selectedcategory, categories } = this.state;
    console.log(categories);
    // let { selectedcategory } = this.state;
    let url = "";
    let params = [];
    let filter = [];
    if (selectedcategory === "Diamonds") {
      url = "/diamond/website";
      params = ["our_stock"];
    }
    if (selectedcategory === "Estate") {
      url = "/estate/website";
      params = ["product_name"];
      filter = [{ fieldName: "is_active", value: true }];
    } else {
      const customCategory = categories.find(
        (category) => category.category_code === selectedcategory
      );
      if (customCategory) {
        url = `/jwellery/website`;
        params = ["style_name"];
        filter = [
          { fieldName: "category", value: customCategory.category_code },
        ];
      }
    }
    // if (selectedcategory == "Rings") {
    //   url = "/jwellery/website";
    //   params = ["style_name"];
    //   filter = [{ fieldName: "category", value: "rings" }];
    // }

    // if (selectedcategory == "Bracelets") {
    //   url = "/jwellery/website";
    //   params = ["style_name"];
    //   filter = [{ fieldName: "category", value: "bracelets" }];
    // }

    // if (selectedcategory == "Earrings") {
    //   url = "/jwellery/website";
    //   params = ["style_name"];
    //   filter = [{ fieldName: "category", value: "earrings" }];
    // }

    let data = {
      params: params,
      orderBy: "_id",
      query: value,
      forExport: 1,
      filterBy: filter,
    };

    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          let { searchOption } = this.state;
          searchOption = [];
          if (res.data.data) {
            let data = res.data.data;
            let redirectUrl = "";
            let productName = "";
            data.forEach((el) => {
              if (selectedcategory === "Diamonds") {
                redirectUrl = "/diamonds/diamond/" + el.our_stock;
                productName = el.our_stock;
              }

              if (selectedcategory === "Estate") {
                redirectUrl =
                  "/estate-jewelry/" + el.category + "/" + el.product_name;
                productName = el.product_name;
              } else {
                const customCategory = categories.find(
                  (category) => category.category_code === selectedcategory
                );
                if (customCategory) {
                  redirectUrl = `/jewelry/${customCategory.category_code}/${el.style_name}`;
                  productName = el.style_name;
                }
              }
              // if (selectedcategory == "Rings") {
              //   redirectUrl = "/jewelry/rings/ring/" + el.style_name;
              //   productName = el.style_name;
              // }

              // if (selectedcategory == "Bracelets") {
              //   redirectUrl = "/jewelry/bracelets/bracelet/" + el.style_name;
              //   productName = el.style_name;
              // }

              // if (selectedcategory == "Earrings") {
              //   redirectUrl = "/jewelry/earrings/earring/" + el.style_name;
              //   productName = el.style_name;
              // }
              searchOption.push({
                url: redirectUrl,
                displayName: productName,
              });
            });

            console.log(searchOption);

            this.setState({ searchOption });
          } else {
            this.setState({ searchOption });
          }
        } else {
          console.log(res.data.message);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });

    this.setState({ search: value });
  };

  logout = () => {
    localStorage.removeItem("__divinaRetailerToken__");
    localStorage.removeItem("__divinaRetailerData__");
    this.setState({ logout: true });
    window.location.reload();
  };

  sidebarClose = (value) => {
    this.setState({ sidebarOpen: value });
  };

  render() {
    let userData =
      localStorage.getItem("__divinaRetailerData__") !== null
        ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
        : {};
    return (
      <>
        <header
          className="position-fixed w-100"
          style={{ background: "#000", zIndex: 999 }}
        >
          {this.state.windowWidth < 992 ? (
            <Sidebar
              sidebarOpen={this.state.sidebarOpen}
              close={this.sidebarClose}
            />
          ) : null}
          <div className="d-flex justify-content-around py-0 web-header align-items-center">
            <div
              className="nav-item d-lg-none"
              style={{
                position: "absolute",
                left: "25px",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ sidebarOpen: !this.state.sidebarOpen })
              }
            >
              <img src="/menu.svg" width={30} />
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/blogs" className="td-none">
                <img src="/blogger.png" className="img-fluid" width="25px" />
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/diamonds" className="td-none">
                <p className="p-0 m-0">DIAMONDS</p>
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/jewelry" className="td-none">
                <p className="p-0 m-0">JEWELRY</p>
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/estate-jewelry" className="td-none">
                <p className="p-0 m-0">ESTATE JEWELRY</p>
              </Link>
            </div>

            <div className="nav-item ">
              <Link to="/" className="td-none">
                <img className="logo" src={"/v-WHITE.svg"} />
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/custom-design" className="td-none">
                <p className="p-0 m-0">CUSTOM DESIGNS</p>
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/we-buy" className="td-none">
                <p className="p-0 m-0">WE BUY</p>
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              <Link to="/contact-us" className="td-none">
                <p className="p-0 m-0">CONTACT US</p>
              </Link>
            </div>

            <div className="nav-item d-none d-lg-block">
              {localStorage.getItem("__divinaRetailerToken__") !== null ? (
                <div className="text-center">
                  <div className="dropdown" style={{ cursor: "pointer" }}>
                    <h4
                      className="mb-0 userDropdown dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-user-circle-o"></i>
                    </h4>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <p className="dropdown-item">{userData.name}</p>
                      <p
                        className="dropdown-item"
                        onClick={() => this.logout()}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                  {/* <small className="text-uppercase">{userData.name}</small> */}
                </div>
              ) : (
                <Link to="/login" className="td-none">
                  <h4 className="mb-0" style={{ cursor: "pointer" }}>
                    <i className="fa fa-user-circle-o"></i>
                  </h4>
                </Link>
              )}
            </div>
          </div>
          <div className="border-top border-bottom border-white">
            <div className="row align-items-center">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="ml-5 p-0">
                  <ul className="list-inline m-0 fs-12 bread">
                    <li className="list-inline-item">
                      <Link to="/" className="text-white">
                        HOME
                      </Link>
                    </li>
                    {this.state.path.map((el, i) => {
                      return (
                        <Fragment key={i}>
                          <li className="list-inline-item">/</li>
                          <li className="list-inline-item">
                            {el.url ? (
                              <Link to={el.url} className="text-white">
                                {el.path}
                              </Link>
                            ) : (
                              el.path
                            )}
                          </li>
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 my-2">
                <div className="d-flex justify-content-end">
                  <div className="col-lg-6">
                    <div className="form-group mb-0 d-flex align-items-center globleSearchBox">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <select
                            className="input-group-text text-capitalize"
                            id="basic-addon3"
                            onChange={(e) =>
                              this.setState({
                                selectedcategory: e.target.value,
                              })
                            }
                            value={this.state.selectedcategory}
                          >
                            <option value="">Select</option>
                            <option value="Diamonds" placeholder="Lot #">
                              Diamonds
                            </option>
                            {this.state.categories?.map((category, index) => (
                              <option
                                key={index}
                                value={category.category_code}
                              >
                                {category.name}
                              </option>
                            ))}
                            <option value="Estate" placeholder="Style #">
                              Estate
                            </option>
                          </select>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="basic-url"
                          aria-describedby="basic-addon3"
                          onChange={(e) => this.search(e.target.value)}
                          value={this.state.search}
                          disabled={this.state.selectedcategory ? false : true}
                          placeholder={`Search ${
                            this.state.selectedcategory == "Diamonds"
                              ? "Lot #"
                              : "Style #"
                          } here`}
                        />
                      </div>
                      {this.state.searchOption.length ? (
                        <div className="searchOptionBox">
                          <ul>
                            {this.state.searchOption.map((el, index) => {
                              return (
                                <a
                                  key={index}
                                  href={el.url}
                                  target="_blank"
                                  onClick={() =>
                                    this.setState({
                                      searchOption: [],
                                      search: el.displayName,
                                    })
                                  }
                                >
                                  <li>{el.displayName}</li>
                                </a>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
