import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Bread extends Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname.split("/")[1];
    this.state = {
      path: path.toUpperCase(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    return state;
  }

  render() {
    return (
      <>
        {/* <div style={{ paddingTop: "125px" }}> </div>{" "} */}
        {localStorage.getItem("__divinaRetailerToken__") ? (
          <div style={{ paddingTop: "125px" }}> </div>
        ) : null}
      </>
    );
  }
}
