import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import axios from "../config/Axios";
import { humanDate } from "../utils/util";
import Skeleton from "react-loading-skeleton";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Events extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventsAndShowsBannerImage: "",
      items: [],
      loader: false,
      reqData: {
        offset: 0,
        limit: 10,
        query: "",
        params: ["show_name"],
        page: 1,
        orderBy: "",
      },
    };
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        let { eventsAndShowsBannerImage } = res.data.data[0];
        this.setState({
          eventsAndShowsBannerImage,
        });
      })
      .catch((err) => {
        console.log("err");
      });

    this.setState({ loader: true });
    axios
      .post("/event/website", this.state.reqData)
      .then((req) => {
        let data = req.data.data;
        this.setState({ items: data, loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  }

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.eventsAndShowsBannerImage
            })`,
          }}
        >
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    SHOWS &amp; EVENTS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 mb-5 container">
          <div className="row">
            {this.state.items.length ? (
              this.state.items.map((item, i) => {
                return (
                  <div className="col-md-6 mb-5" key={i}>
                    <h3>{item.show_name}</h3>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-calendar-o"></i>
                      <p className="mb-0 ml-2">{humanDate(item.date)}</p>
                    </div>
                    {item.booth_number ? (
                      <div className="d-flex align-items-center">
                        <i className="fa fa-map-marker"></i>
                        <p className="mb-0 ml-2">{item.booth_number}</p>
                      </div>
                    ) : null}

                    <div className="my-4  blog-image">
                      <iframe
                        src={item.map_link}
                        className=" mb-0"
                        width="100%"
                        height="200"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="col-md-6 mb-5">
                  <h3>
                    <Skeleton count={1} />
                  </h3>
                  <div className="d-flex align-items-center">
                    <Skeleton count={1} width={20} />
                    <p className="mb-0 ml-2">
                      <Skeleton count={1} width={50} />
                    </p>
                  </div>
                  <div className="my-4">
                    <Skeleton count={1} height={400} />
                  </div>
                  <div>
                    <p>
                      <Skeleton count={3} />
                    </p>
                  </div>
                  <div className="mt-2">
                    <Skeleton count={1} width={200} height={30} />
                  </div>
                </div>
                <div className="col-md-6 mb-5">
                  <h3>
                    <Skeleton count={1} />
                  </h3>
                  <div className="d-flex align-items-center">
                    <Skeleton count={1} width={20} />
                    <p className="mb-0 ml-2">
                      <Skeleton count={1} width={50} />
                    </p>
                  </div>
                  <div className="my-4">
                    <Skeleton count={1} height={400} />
                  </div>
                  <div>
                    <p>
                      <Skeleton count={3} />
                    </p>
                  </div>
                  <div className="mt-2">
                    <Skeleton count={1} width={200} height={30} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
