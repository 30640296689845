import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import axios from "../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

let _this = "";

export default class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      confirm_password: "",
      token: "",
    };

    _this = this;

    if (
      localStorage.getItem("__divinaRetailerData__") !== null &&
      localStorage.getItem("__divinaRetailerToken__") !== null
    ) {
      window.location.replace("/");
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let token = this.props.match.params.token;

    if (!token) {
      window.location.replace("/login");
    } else {
      this.setState({ token: token });
    }
  }

  setNewPassword = () => {
    let { new_password, confirm_password, token } = this.state;

    let data = {
      new_password: new_password,
      confirm_password: confirm_password,
    };

    if (data.new_password == "") {
      _this.notify("danger", "Password is required", "2000");
      return;
    }

    if (data.confirm_password == "") {
      _this.notify("danger", "Confirm Password is required", "2000");
      return;
    }

    if (data.confirm_password !== data.new_password) {
      _this.notify(
        "danger",
        "Password and Confirm Password is not match",
        "2000"
      );
      return;
    }

    axios
      .post("/retailer/reset-password", data, {
        headers: {
          token: token,
        },
      })
      .then((res) => {
        let data = res.data.data;
        if (res.data.status) {
          this.notify("success", "Password update successfully", "2000");
          window.location.replace("/login");
        } else {
          this.notify("danger", res.data.message, "2000");
        }
      })
      .catch((err) => {
        this.notify("danger", err.message, "2000");
      });
  };

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  render() {
    return (
      <Fragment>
        {localStorage.getItem("__divinaRetailerToken__") ? <Header /> : null}
        <Bread />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "100vh" }}
        >
          <div className="container mb-5 mt-5" style={{ flex: 1 }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-7">
                <div
                  className="login-box"
                  style={{ padding: "50px", background: "#232323a6" }}
                >
                  <div className="text-center">
                    <h1>Set New Password</h1>
                  </div>
                  <div className="form-group mt-4">
                    <div className="">
                      <label>New Password</label>
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          this.fatchData("new_password", e.target.value)
                        }
                        value={this.state.new_password}
                        placeholder="New Password"
                        className="form-control"
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="">
                      <label>Confirm Password</label>
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          this.fatchData("confirm_password", e.target.value)
                        }
                        value={this.state.confirm_password}
                        placeholder="Confirm Password"
                        className="form-control"
                        type="password"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3 text-center">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.setNewPassword}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
