import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import lineImg from "../assets/line.png";
import img1 from "../assets/images/estate-1.png";
import img2 from "../assets/Estate-Banner.png";
import axios from "../config/Axios";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Magnifier from "react-magnifier";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
// import "react-image-gallery/styles/scss/image-gallery.scss";

let category = "";

export default class EstateJewlleryWithType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estatePageBannerImage: "",
      estatePageDescription: "",
      items: [],
      selectedItem: "",
      selectedImage: "",
      selectedImageIndex: "0",
      reqData: {
        limit: 10,
        page: 1,
        offset: 0,
        params: ["product_name"],
        orderBy: "_id",
        query: "",
        filterBy: [
          { fieldName: "is_active", value: true },
          { fieldName: "category", value: category },
        ],
      },
      noMoreData: false,
      totalPage: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    category = this.props.match.params.name.replace(new RegExp("-", "g"), " ");
    axios
      .get("/cms/website")
      .then((res) => {
        let { estatePageBannerImage, estatePageDescription } = res.data.data[0];
        this.setState({ estatePageBannerImage, estatePageDescription });
      })
      .catch((err) => {
        console.log("err");
      });

    let { reqData } = this.state;
    reqData.filterBy[1].value = category;
    this.setState({ reqData });
    this.fatchData(reqData);
  }

  fatchData = (reqData) => {
    this.setState({ loader: true });
    axios
      .post("/estate/website", reqData)
      .then((res) => {
        let data = res.data.data;
        if (data.length) {
          let noMoreData = data.length == res.data.totalItem ? true : false;
          this.setState({
            items: data,
            loader: false,
            noMoreData: noMoreData,
            totalPage: res.data.totalPage,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  loadMoreData = (reqData) => {
    this.setState({ loader: true });
    axios
      .post("/estate/website", reqData)
      .then((res) => {
        let prevItems = [...this.state.items];
        let data = res.data.data;
        if (data.length) {
          prevItems.push(...data);
          console.log(prevItems.length);
          console.log(prevItems.length == res.data.totalItem);
          let noMoreData =
            prevItems.length == res.data.totalItem ? true : false;

          console.log(noMoreData);
          this.setState({
            items: prevItems,
            loader: false,
            noMoreData: noMoreData,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {
    let winHeight =
      document.querySelector(".footer").offsetHeight +
      document.querySelector("header").offsetHeight * this.state.reqData.page;
    let winscrollY = window.scrollY;
    if (winscrollY > winHeight) {
      if (!this.state.noMoreData) {
        let reqData = { ...this.state.reqData };
        reqData.offset = reqData.limit * reqData.page;
        reqData.page = reqData.page + 1;
        if (this.state.totalPage >= reqData.page) {
          this.loadMoreData(reqData);
          this.setState({ reqData });
        }
      }
    }
  };

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    let photoIndex = this.state.selectedImageIndex;
    let images = [];

    if (this.state.selectedItem.images) {
      this.state.selectedItem.images.forEach((el) => {
        images.push({
          original: baseURL + el,
          thumbnail: baseURL + el,
        });
      });
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.estatePageBannerImage
            })`,
          }}
        >
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    Estate Jewelry : {category}
                  </p>
                  <div className="cat-desc-text text-center custom-cat-desc d-none d-lg-block">
                    <p className="desc-text">
                      {this.state.estatePageDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cat-desc-text text-center custom-cat-desc d-block d-lg-none my-3">
          <p className="desc-text">{this.state.estatePageDescription}</p>
        </div>

        <div
          className={
            this.state.selectedItem ? "mt-5 container" : "mt-5 container"
          }
        >
          <div className="row">
            <div
              className={this.state.selectedItem ? "col-md-12" : "col-md-12"}
            >
              <div className="d-flex flex-wrap">
                {this.state.items.length ? (
                  this.state.items.map((item, i) => {
                    return (
                      <div
                        className={
                          this.state.selectedItem
                            ? "col-md-3 col-6 d-flex"
                            : "col-md-3 col-6 d-flex"
                        }
                        id={item._id}
                        key={i}
                      >
                        {item.is_gallery ? (
                          <div
                            className="product-box"
                            onClick={() =>
                              this.setState({
                                selectedItem: item,
                                selectedImage: baseURL + item.images[0],
                                selectedImageIndex: 0,
                              })
                            }
                          >
                            <div className="product-image-box">
                              <img
                                src={
                                  item.images[0]
                                    ? baseURL + item.images[0]
                                    : "https://divinacreation.s3.ap-south-1.amazonaws.com/estate_img_14KT%20White%20Gold%202ct%20Y%20VS1%20Halo%20Engagement%20Ring%09_0_2020_58_5_15_58_10.jpg"
                                }
                                className="img-fluid"
                              />
                            </div>

                            <div className="product-box-effect">
                              <p className="text-center text-white mb-0">
                                {!item.is_gallery ? item.product_name : null}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <Link
                            to={
                              "/estate-jewelry/" +
                              category +
                              "/" +
                              item.product_name
                            }
                            className="text-white"
                          >
                            <div className="product-box">
                              <div className="product-image-box">
                                <img
                                  src={
                                    item.images[0]
                                      ? baseURL + item.images[0]
                                      : "https://divinacreation.s3.ap-south-1.amazonaws.com/estate_img_14KT%20White%20Gold%202ct%20Y%20VS1%20Halo%20Engagement%20Ring%09_0_2020_58_5_15_58_10.jpg"
                                  }
                                  className="img-fluid"
                                />
                              </div>
                              <div className="product-box-effect">
                                <p className="text-center text-white mb-0">
                                  {item.product_name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    );
                  })
                ) : this.state.loader ? (
                  <>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="">
              {this.state.selectedItem ? (
                <div className="estateGalleryBox">
                  <div
                    className="close"
                    onClick={() =>
                      this.setState({
                        selectedItem: "",
                      })
                    }
                  >
                    <i className="fa fa-times"></i>
                  </div>{" "}
                  <ImageGallery items={images} />{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
