import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Productcard from "../Components/Productcard";
import lineImg from "../assets/line.png";
import img1 from "../assets/images/ring-1.png";
import img2 from "../assets/images/ring-2.png";
import img3 from "../assets/images/ring-3.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import Magnifier from "react-magnifier";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import axios from "../config/Axios";

import uploadImg from "../assets/upload.png";
import InquiryModal from "./common/InquiryModal";
import { Link } from "react-router-dom";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

let _this = "";

export default class Ring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ringPageSliderTitle: "",
      ringPageSliderData: [],
      features: [
        { key: "shape", displayName: "Shape", value: "" },
        { key: "diamond_weight", displayName: "Diamond Weight", value: "" },
        { key: "diamond_color", displayName: "Diamond Color", value: "" },
        { key: "diamond_clarity", displayName: "Diamond Clarity", value: "" },
        { key: "setting", displayName: "setting", value: "" },
        { key: "metal", displayName: "metal", value: "" },
        { key: "custom_fields", displayName: "", value: "" },
      ],
      style_name: "",
      description: "",
      catCode: "",
      images: [],
      declaimer: "Disclaimer: All Diamond Weights are approx weights",
      modalOpen: false,
      loader: false,
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
      selectedImage: "",
      three_d_file: [],
      three_d_fileView: false,
    };

    _this = this;
  }

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    let id = this.props.match.params.styleName;
    let styleCat = this.props.match.params.cateCode;
    this.setState({ loader: true });
    axios
      .get("/cms/website")
      .then((res) => {
        let { ringPageSliderTitle, ringPageSliderData } = res.data.data[0];
        this.setState({ ringPageSliderTitle, ringPageSliderData });
      })
      .catch((err) => {
        console.log("err");
      });

    axios
      .get(`/jwellery/website/?category=${styleCat}&style_name=` + id, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__divinaRetailerToken__"),
        },
      })
      .then((res) => {
        let data = _this.state;
        if (res.data.data.length) {
          for (const [key, value] of Object.entries(res.data.data[0])) {
            data.features.forEach((element) => {
              if (element.key == key) {
                // value = value.toUpperCase();
                element.value = value;
              }
            });
          }

          data.style_name = res.data.data[0].style_name;
          data.description = res.data.data[0].description;
          data.three_d_file = res.data.data[0].three_d_file;
          data.images =
            res.data.data[0].images.length > 0
              ? res.data.data[0].images
              : data.images;
          data.selectedImage = data.images[0];

          if (res.data.data[0]) {
            data.three_d_fileView =
              res.data.data[0].three_d_file !== null &&
              res.data.data[0].three_d_file.length > 0
                ? true
                : false;

            data.selectedImage = data.three_d_fileView
              ? "/play-button.svg"
              : data.selectedImage;
          }
        }

        this.setState({ ...data, loader: false, catCode: styleCat });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  }

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 7,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <>
        <div className="container-fluid p-0">
          <Header />
          <Bread />

          <div className="container-fluid">
            <div className="row mt-2" style={{ background: "#272727a6" }}>
              <div className="col-lg-4 text-center">
                <div className="py-3">
                  <img
                    src="/united-states.png"
                    width="60px"
                    style={{ filter: "grayscale(1)" }}
                  />
                </div>
              </div>
              <div className="col-lg-4 text-center">
                <div className="py-3">
                  <h1 className="mb-0" style={{ fontWeight: 100 }}>
                    MADE IN USA
                  </h1>
                </div>
              </div>
              <div className="col-lg-4 text-center d-none d-lg-block">
                <div className="py-3">
                  <img
                    src="/united-states.png"
                    width="60px"
                    style={{ filter: "grayscale(1)" }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap py-5 ">
              <div className="col-lg-4">
                <div className="main-img-container text-center">
                  {this.state.loader ? (
                    <Skeleton count={1} height={400} />
                  ) : this.state.three_d_file !== null &&
                    this.state.three_d_file.length > 0 &&
                    this.state.three_d_fileView ? (
                    <video autoPlay id="background-video" loop style={{}}>
                      <source
                        src={baseURL + this.state.three_d_file[0]}
                        type="video/mp4"
                      />
                      <source
                        src={baseURL + this.state.three_d_file[0]}
                        type="video/ogg"
                      />
                      <source
                        src={baseURL + this.state.three_d_file[0]}
                        type="video/webm"
                      ></source>
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <Magnifier
                      src={this.state.selectedImage}
                      width={500}
                      zoomImgSrc={this.state.selectedImage}
                      zoomFactor={1.5}
                    />
                  )}
                </div>
                <div className="row mt-3">
                  {this.state.loader ? (
                    <>
                      <div className="col-3 mb-1">
                        <Skeleton count={1} height={150} />
                      </div>
                      <div className="col-3 mb-1">
                        <Skeleton count={1} height={150} />
                      </div>
                      <div className="col-3 mb-1">
                        <Skeleton count={1} height={150} />
                      </div>
                      <div className="col-3 mb-1">
                        <Skeleton count={1} height={150} />
                      </div>
                    </>
                  ) : (
                    <>
                      {this.state.three_d_file !== null &&
                      this.state.three_d_file.length > 0 ? (
                        <div className="col-3 mb-1">
                          <div
                            style={{ cursor: "pointer" }}
                            className={
                              this.state.selectedImage == "/play-button.svg"
                                ? "thum-img-container active"
                                : "thum-img-container"
                            }
                            onClick={() =>
                              this.setState({
                                selectedImage: "/play-button.svg",
                                three_d_fileView: true,
                              })
                            }
                          >
                            <img
                              src={"/play-button.svg"}
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      ) : null}
                      {this.state.images.map((el, i) => {
                        return el ? (
                          <div className="col-3 mb-1" key={i}>
                            <div
                              style={{ cursor: "pointer" }}
                              className={
                                this.state.selectedImage == el
                                  ? "thum-img-container active"
                                  : "thum-img-container"
                              }
                              onClick={() =>
                                this.setState({
                                  selectedImage: baseURL + el,
                                  three_d_fileView: false,
                                })
                              }
                            >
                              <img src={baseURL + el} className="img-fluid" />
                            </div>
                          </div>
                        ) : null;
                      })}
                    </>
                  )}
                </div>
              </div>

              <div className="col-lg-8 mt-3 mt-lg-0">
                <h1 className="ft-lato bb-dashed fw-200 fs-2r">
                  STYLE#{" "}
                  {this.state.style_name == "" ? (
                    <Skeleton count={1} width={200} height={40} />
                  ) : (
                    this.state.style_name
                  )}
                </h1>
                <p className="ft-lato mt-3">
                  {this.state.style_name ? (
                    this.state.description
                  ) : (
                    <>
                      <Skeleton count={1} width={1000} height={20} />
                      <Skeleton count={1} width={1000} height={20} />
                      <Skeleton count={1} width={1000} height={20} />
                      <Skeleton count={1} width={500} height={20} />
                    </>
                  )}
                </p>

                <div className="row">
                  <div className="col-lg-8">
                    <table className="table table-bordered mt-3 mb-0">
                      <tbody>
                        {this.state.features.map((e, i) => {
                          return e.key !== "custom_fields" ? (
                            <tr key={i}>
                              <td className="text-capitalize">
                                {e.displayName}
                              </td>
                              <td className="fw-200 text-uppercase">
                                {this.state.loader ? (
                                  <Skeleton count={1} width={200} height={20} />
                                ) : (
                                  e.value
                                )}
                              </td>
                            </tr>
                          ) : e.value.length ? (
                            e.value.map((custom, index) => {
                              return custom.field_name ? (
                                <tr key={index}>
                                  <td className="text-capitalize">
                                    {custom.field_name}
                                  </td>
                                  <td className="fw-200 text-uppercase">
                                    {custom.field_value == "" ? (
                                      <Skeleton
                                        count={1}
                                        width={200}
                                        height={20}
                                      />
                                    ) : (
                                      custom.field_value
                                    )}
                                  </td>
                                </tr>
                              ) : null;
                            })
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-lg-12">
                    <small className="">{this.state.declaimer}</small>
                  </div>
                </div>

                <div className="product-action-buttons mt-3">
                  <div className="d-flex flex-wrap">
                    <div className="col-lg-6 pl-lg-0 px-0">
                      {this.state.token ? (
                        <a
                          href={`https://api.whatsapp.com/send?phone=+19179579177&text=Hey!, I want to purchase the "Ring": *${this.state.style_name}* *Name*: ${this.state.userdata.name} *Company*:${this.state.userdata.business_name} *Contact Number*: ${this.state.userdata.mobile_number} *Email ID*: ${this.state.userdata.email} Please call me or email me on the same`}
                          target="_blank"
                        >
                          <button className="btn btn-block py-3 btn-outline-light ft-lato-bold">
                            <i
                              className="fa fa-whatsapp"
                              aria-hidden="true"
                            ></i>
                            <span className="ml-2"> ENQUIRE NOW</span>
                          </button>
                        </a>
                      ) : (
                        <button
                          className="btn btn-block py-3 btn-outline-light ft-lato-bold"
                          onClick={() =>
                            this.notify("danger", "Please Login First", "2000")
                          }
                        >
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                          <span className="ml-2"> ENQUIRE NOW</span>
                        </button>
                      )}
                    </div>

                    <div className="col-lg-6 pr-lg-0 mt-2 mt-lg-0 px-0 pl-lg-3">
                      <button
                        className="btn btn-block  btn-white ft-lato-bold py-3"
                        onClick={() =>
                          this.setState({ modalOpen: !this.state.modalOpen })
                        }
                      >
                        <i className="fa fa-life-ring" aria-hidden="true"></i>
                        <span className="ml-2"> CUSTOMIZE YOUR RING</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap mb-lg-5 ">
              <div className="col-lg-12 d-none d-lg-block">
                <div className="text-center">
                  <h1 className="d-inline-block text-big-35 text-center py-3">
                    {this.state.ringPageSliderTitle &&
                    this.state.ringPageSliderData
                      ? this.state.ringPageSliderTitle
                      : ""}
                  </h1>
                </div>
                <div className="position-relative">
                  <div className="slider-left-layer"></div>
                  <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={false}
                    ssr={false} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    centerMode={true}
                    customTransition="ease .5"
                    transitionDuration={1000}
                    containerClassName="w-100"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={this.props.deviceType}
                    itemClassName="carousel-item-padding-40-px"
                  >
                    {this.state.ringPageSliderData
                      ? this.state.ringPageSliderData.map((e, i) => {
                          return (
                            <a
                              key={i}
                              href={"/jewelry/rings/ring/" + e.label}
                              className="text-white"
                              target="_blank"
                            >
                              <Productcard
                                key={i}
                                img={e.images[0] ? e.images[0] : img1}
                                name={e.label}
                              />
                            </a>
                          );
                        })
                      : null}
                  </Carousel>
                  <div className="slider-right-layer"></div>
                </div>
              </div>
            </div>
          </div>

          <InquiryModal
            data={this.state.modalOpen}
            fatchData={this.fatchData}
            subject={`Inquiry for Ring  Style Name : ${this.state.style_name}`}
            section={this.state.cateCode}
          />

          <Footer />
        </div>
      </>
    );
  }
}
