import React, { Component } from "react";
import insta from "../../assets/insta.png";
import fb from "../../assets/fb.png";
import { Link } from "react-router-dom";
import axios from "../../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../../constant/defaultValues";
let _this = "";
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    _this = this;
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  saveEmail = () => {
    let { email } = this.state;
    if (email == "") {
      return false;
    }

    axios
      .post(
        "/mail/website/add-mail",
        { mail_data: email },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("__authToken__"),
          },
        }
      )
      .then(function (response) {
        _this.notify("success", "subscribed successfully", "2000");
        _this.setState({ open: false, email: "" });
      })
      .catch(function (error) {
        _this.notify("danger", error.message, "2000");
      });
  };
  render() {
    return (
      <div className=" pt-5 footer">
        <div className="container ">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-6">
                  <h5 className="fw-400">
                    <b>Quick Links</b>
                  </h5>
                  <ul className="list-unstyled footer-links fw-200">
                    {localStorage.getItem("__divinaRetailerToken__") ? (
                      <>
                        <li>
                          <Link className="text-white" to="/blogs">
                            Blogs
                          </Link>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <Link to="/about-us" className="text-white">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                    {localStorage.getItem("__divinaRetailerToken__") ? null : (
                      <li>
                        <Link className="text-white" to="/login">
                          Login
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link className="text-white" to="/create-account">
                        Registration
                      </Link>
                    </li>
                    {localStorage.getItem("__divinaRetailerToken__") ? (
                      <>
                        <li>
                          <Link
                            className="text-white"
                            to="/schedule-appointment"
                          >
                            Schedule Appointment
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/gallery">
                            Our Gallery
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>

                {localStorage.getItem("__divinaRetailerToken__") ? (
                  <div className="col-6">
                    <h5 className="fw-400">
                      <b>Products</b>
                    </h5>
                    <ul className="list-unstyled footer-links fw-200">
                      <li>
                        <Link className="text-white" to="/diamonds">
                          Diamonds
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/jewelry">
                          Jewelry
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/estate-jewelry">
                          Estate Jewelry
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/custom-design">
                          Custom Design
                        </Link>
                      </li>
                      <li>
                        <Link className="text-white" to="/we-buy">
                          We Buy
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-lg-4 mt-3 mt-lg-0 text-center">
              <p className="text-lg-right fw-400 mb-2 mb-lg-3">
                Join Our Mailing List
              </p>
              <form className="form-inline d-flex justify-content-lg-end justify-content-center">
                <div className="form-group">
                  <input
                    type="text"
                    style={{ borderColor: "white" }}
                    placeholder="Your Email Address"
                    name=""
                    id=""
                    className="form-control px-4"
                    aria-describedby="helpId"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>

                <div className="form-group ml-2">
                  <button
                    type="button"
                    style={{ borderColor: "white", cursor: "pointer" }}
                    name=""
                    id=""
                    className="form-control"
                    placeholder=""
                    aria-describedby="helpId"
                    onClick={this.saveEmail}
                  >
                    Join
                  </button>
                </div>
              </form>
              <div className=" text-lg-right mt-3 mt-lg-3">
                <img src={"/facebook.png"} className="img-fluid mr-3 d-none" />
                <a href="https://www.rapnet.com/" target="_blank">
                  <img
                    src={baseURL + "uploads/Divina-Web-Resources/RapImg.png"}
                    className="img-fluid mr-3"
                    width="120"
                  />
                </a>
                <a
                  href="https://www.instagram.com/divinacreations/"
                  target="_blank"
                >
                  <img src={"/instagram.png"} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center py-2 mb-0 mt-3 mt-lg-0">
          Copyright &copy; {new Date().getFullYear()} Divina Creations Inc.
          <br />
          Created by HighQ.co.in
        </p>
      </div>
    );
  }
}
