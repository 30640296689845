import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import Skeleton from "react-loading-skeleton";
import axios from "../config/Axios";
import moment from "moment";
import Loader from "react-loader-spinner";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noMoreData: false,
      pagination: {
        offset: 0,
        limit: 10,
        query: "",
        params: ["title"],
        page: 1,
        orderBy: "",
      },
      items: [],
      loader: false,
      blogsPageBannerImage: "",
      blogsPageDescription: "",
      blogsPageTitle: "",
    };
  }

  loadMoreData = () => {
    this.setState({ loader: true });
    let pagination = { ...this.state.pagination };
    pagination.page = pagination.page + 1;
    axios
      .post("/blog/website", pagination)
      .then((res) => {
        let prevItems = [...this.state.items];
        let data = res.data.data;

        prevItems.push(...data);
        let noMoreData = prevItems.length == res.data.totalItem ? true : false;
        this.setState({
          items: prevItems,
          pagination: pagination,
          loader: false,
          noMoreData: noMoreData,
        });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        let { blogsPageBannerImage, blogsPageDescription, blogsPageTitle } =
          res.data.data[0];
        this.setState({
          blogsPageBannerImage,
          blogsPageDescription,
          blogsPageTitle,
        });
      })
      .catch((err) => {
        console.log("err");
      });

    this.setState({ loader: true });
    axios
      .post("/blog/website", this.state.pagination)
      .then((res) => {
        let data = res.data.data;
        let noMoreData = data.length == res.data.totalItem ? true : false;
        this.setState({
          items: res.data.data,
          loader: false,
          noMoreData: noMoreData,
        });
      })
      .catch((err) => {
        this.notify("danger", err.message, "2000");
      });
  }

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <>
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.blogsPageBannerImage
            })`,
          }}
        >
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    {this.state.blogsPageTitle}
                  </p>
                  <div className="cat-desc-text text-center custom-cat-desc">
                    <p className="desc-text">
                      {this.state.blogsPageDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            {this.state.items.length ? (
              this.state.items.map((el, i) => {
                return (
                  <div className="col-md-4 mb-5" key={i}>
                    <h3>{el.title}</h3>
                    <div className="d-flex align-items-center">
                      <i className="fa fa-calendar-o"></i>
                      <p className="mb-0 ml-2">
                        {moment(el.publish_date).format("Do MMMM YYYY")}
                      </p>
                    </div>
                    <div className="my-4  blog-image">
                      <img
                        src={baseURL + el.images[0]}
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <div className="mt-2 text-center">
                      <Link to={"blogs/blog/" + el.title.replace(/\s/g, "-")}>
                        <button className="btn btn-sm btn-primary">
                          <span>Read More</span>{" "}
                          <i className="fa fa-arrow-right ml-2"></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-md-12 mb-5">
                <h3>
                  <Skeleton count={1} />
                </h3>
                <div className="d-flex align-items-center">
                  <Skeleton count={1} width={20} />
                  <p className="mb-0 ml-2">
                    <Skeleton count={1} width={100} />
                  </p>
                </div>
                <div className="my-4">
                  <Skeleton count={1} height={400} />
                </div>
                <div>
                  <p>
                    <Skeleton count={3} />
                  </p>
                </div>
                <div className="mt-2">
                  <Skeleton count={1} width={200} height={30} />
                </div>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-center mt-3 mb-5">
            {!this.state.noMoreData ? (
              <button className="btn btn-dark" onClick={this.loadMoreData}>
                {this.state.loader ? (
                  <Loader
                    type="Puff"
                    color="#fff"
                    height={50}
                    width={50}
                    timeout={3000} //3 secs
                  />
                ) : (
                  "Show More Blogs"
                )}
              </button>
            ) : null}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
