import React, { Component } from "react";
import Routes from "./Routes";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // console.log(window.scrollTo(0));
    // $(window).scroll(function () {
    //       console.log($(this).scrollTop(0));
    //     });
  }

  render() {
    return (
      <>
        <Routes />
      </>
    );
  }
}
