import React, { Component } from "react";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import Skeleton from "react-loading-skeleton";
import axios from "../config/Axios";
import moment from "moment";
import Slider from "react-slick";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../constant/defaultValues";

export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loader: false,
    };
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let id = this.props.match.params.id.replace(new RegExp("-", "g"), " ");
    this.setState({ loader: true });
    axios
      .get("/blog/website/blog-by-title/" + id)
      .then((res) => {
        let data = res.data.data;
        this.setState({ data: res.data.data, loader: false });
      })
      .catch((err) => {
        this.notify("danger", err.message, "2000");
      });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <>
        <Header />
        <Bread />
        <div className="cat-jumbo d-flex">
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    {this.state.data.length ? this.state.data[0].title : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row text-center">
            {this.state.data.length ? (
              this.state.data.map((el, i) => {
                return (
                  <div className="col-md-12" key={i}>
                    <div className="mb-3">
                      {
                        el.images.length > 1 ? <Slider {...settings}>
                          {
                            el.images.map((img, imgIndex) => {
                              return (
                                img !== "" ? <img key={imgIndex} src={img} className="img-fluid" /> : null
                              )
                            })
                          }
                        </Slider> : <img src={baseURL + el.images[0]} className="img-fluid" />
                      }


                    </div>
                    {/* <div className="text-center my-5">
                      <h2>{el.title}</h2>
                    </div> */}
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: el.description,
                        }}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-md-12">
                <div className="mb-3">
                  <Skeleton count={1} height={400} />
                </div>
                <div className="text-center my-5">
                  <h2>
                    <Skeleton count={1} width={600} />
                  </h2>
                </div>
                <div>
                  <p>
                    <Skeleton count={3} />
                  </p>
                  <p>
                    <Skeleton count={2} />
                  </p>
                  <p>
                    <Skeleton count={4} />
                  </p>
                  <p>
                    <Skeleton count={6} />
                  </p>
                  <p>
                    <Skeleton count={2} />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
