import React, { Component } from "react";
import Header from "../../common/Header";
import Bread from "../../common/Bread";
import Footer from "../../common/Footer";
// import lineImg from "../assets/line.png";
// import img1 from "../assets/images/ring-1.png";
// import img2 from "../assets/images/ring-2.png";
// import img3 from "../assets/images/ring-3.png";
import axios from "../../../config/Axios";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { baseURL } from "../../../constant/defaultValues";
import { Redirect, withRouter } from "react-router-dom/cjs/react-router-dom";

class Categorylayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customCategoryBannerImage: "",
      customCategoryPageDescription: "",
      pagination: {
        offset: 0,
        limit: 10,
        query: "",
        params: [],
        page: 1,
        forExport: "1",
        orderBy: "",
        filterBy: [
          { fieldName: "category", value: this.props.match.params.id },
          { fieldName: "is_active", value: true },
        ],
      },
      items: [],
      loader: false,
      noMoreData: false,
      totalPage: 1,
    };
  }

  componentDidMount() {
    const id = this.props.match.params;
    this.fetchCategories();

    window.scrollTo(0, 0);
    axios
      .get(`${baseURL}cms/website`)
      .then((res) => {
        let { customCategoryBannerImage, customCategoryPageDescription } =
          res.data.data[0];
        this.setState({
          customCategoryBannerImage,
          customCategoryPageDescription,
        });
      })
      .catch((err) => {
        console.log("err");
      });

    let { pagination } = this.state;

    this.fatchData(pagination);
  }

  fetchCategories = () => {
    const requestBody = {
      offset: "",
      limit: "10",
      query: "",
      params: [],
      page: "1",
      orderBy: "",
      filterBy: [{ fieldName: "name", value: this.props.match.params.id }],
      forExport: "0",
    };
    axios
      .post(`${baseURL}custom-category/website`, requestBody)
      .then((response) => {
        const categories = response.data.data;
        this.setState({ categories });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  };

  fatchData = (pagination) => {
    this.setState({ loader: true });
    axios
      .post(`${baseURL}jwellery/website`, pagination)
      .then((res) => {
        let data = res.data.data;
        if (data.length) {
          let noMoreData = data.length == res.data.totalItem ? true : false;
          this.setState({
            items: data,
            loader: false,
            noMoreData: noMoreData,
            totalPage: res.data.totalPage,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  loadMoreData = (pagination) => {
    this.setState({ loader: true });
    axios
      .post(`${baseURL}jwellery/website`, pagination)
      .then((res) => {
        let prevItems = [...this.state.items];
        let data = res.data.data;
        if (data.length) {
          prevItems.push(...data);
          console.log(prevItems.length);
          console.log(prevItems.length == res.data.totalItem);
          let noMoreData =
            prevItems.length == res.data.totalItem ? true : false;

          console.log(noMoreData);
          this.setState({
            items: prevItems,
            loader: false,
            noMoreData: noMoreData,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {
    let winHeight =
      document.querySelector(".footer").offsetHeight +
      document.querySelector("header").offsetHeight *
        this.state.pagination.page;
    let winscrollY = window.scrollY;
    if (winscrollY > winHeight) {
      if (!this.state.noMoreData) {
        let pagination = { ...this.state.pagination };
        pagination.offset = pagination.limit * pagination.page;
        pagination.page = pagination.page + 1;
        if (this.state.totalPage >= pagination.page) {
          this.loadMoreData(pagination);
          this.setState({ pagination });
        }
      }
    }
  };

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    const data = this.state.categories;

    const categoryLength = Array.isArray(data) ? data.length : 0;
    const categories =
      categoryLength > 0 ? data[0].name : "No categories available";

    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.customCategoryBannerImage
            })`,
          }}
        >
          <div className="blur-bg align-self-stretch d-flex justify-content-center ">
            <p className="text-center align-self-center jumbo-cat-name text-capitalize">
              {this.state.categories && this.state.categories.length > 0
                ? this.state.categories[0].name
                : ""}
            </p>
          </div>
        </div>

        <div className="cat-desc-text mt-2 mt-lg-5 text-center">
          <p className="desc-text">
            {this.state.customCategoryPageDescription}
          </p>
        </div>

        <div className="container">
          <div className="row justify-content-lg-center">
            {this.state.items.length ? (
              this.state.items.map((item) => {
                return (
                  <div key={item._id} className="col-md-3 col-6 " id={item._id}>
                    <Link
                      to={`/jewelry/${item.category}/${item.style_name}`}
                      className="text-white"
                    >
                      <div className="product-box">
                        <div className="product-image-box">
                          <img
                            src={
                              item.images[0]
                                ? baseURL + item.images[0]
                                : "https://divinacreation.s3.amazonaws.com/jwellery_img_STR-001_0_2020_33_1_14_33_31.jpeg"
                            }
                            className="img-fluid"
                          />
                        </div>
                        <div className="product-box-effect">
                          <p className="text-center text-white mb-0">
                            {item.style_name} <br></br>{" "}
                            {item.diamond_weight + "cttw"} {item.diamond_color}{" "}
                            {item.diamond_clarity}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : this.state.loader ? (
              <>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
              </>
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(Categorylayout);
