import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import lineImg from "../assets/line.png";
import img1 from "../assets/images/estate-1.png";
import img2 from "../assets/Estate-Banner.png";
import axios from "../config/Axios";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Magnifier from "react-magnifier";
import ReactImageMagnify from "react-image-magnify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
// import "react-image-gallery/styles/scss/image-gallery.scss";

let category = "";

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      selectedItem: "",
      selectedImage: "",
      selectedImageIndex: "0",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loader: true });
    axios
      .get("/gallery/website/all-gallery-data")
      .then((res) => {
        this.setState({ items: res.data.data, loader: false });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  render() {
    let photoIndex = this.state.selectedImageIndex;
    let images = [];

    if (this.state.selectedItem.images) {
      this.state.selectedItem.images.forEach((el) => {
        images.push({
          original: baseURL + el,
          thumbnail: baseURL + el,
        });
      });
    }
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className={
            this.state.selectedItem ? "mt-5 container" : "mt-5 container"
          }
        >
          <div className="row">
            <div
              className={this.state.selectedItem ? "col-md-12" : "col-md-12"}
            >
              <div className="d-flex flex-wrap">
                {this.state.items.length ? (
                  this.state.items.map((item, i) => {
                    return (
                      <div
                        className={
                          this.state.selectedItem
                            ? "col-md-3 d-flex"
                            : "col-md-3 d-flex"
                        }
                        id={item._id}
                        key={i}
                      >
                        <div
                          className="product-box"
                          onClick={() =>
                            this.setState({
                              selectedItem: item,
                              selectedImage: item.images[0],
                              selectedImageIndex: 0,
                            })
                          }
                        >
                          <div className="product-image-box">
                            {item.three_d_file !== null &&
                            item.three_d_file.length > 0 ? (
                              <video
                                autoPlay
                                id="background-video"
                                loop
                                style={{}}
                              >
                                <source
                                  src={baseURL + item.three_d_file[0]}
                                  type="video/mp4"
                                />
                                <source
                                  src={baseURL + item.three_d_file[0]}
                                  type="video/ogg"
                                />
                                <source
                                  src={baseURL + item.three_d_file[0]}
                                  type="video/webm"
                                ></source>
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={
                                  item.images[0]
                                    ? baseURL + item.images[0]
                                    : "https://divinacreation.s3.ap-south-1.amazonaws.com/estate_img_14KT%20White%20Gold%202ct%20Y%20VS1%20Halo%20Engagement%20Ring%09_0_2020_58_5_15_58_10.jpg"
                                }
                                className="img-fluid"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : this.state.loader ? (
                  <>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Skeleton count={1} height={250} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="">
              {this.state.selectedItem ? (
                <div className="estateGalleryBox">
                  <div
                    className="close"
                    onClick={() =>
                      this.setState({
                        selectedItem: "",
                      })
                    }
                  >
                    <i className="fa fa-times"></i>
                  </div>{" "}
                  <ImageGallery items={images} />{" "}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
