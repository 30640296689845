import React, { Component } from 'react'

export default class Productcard extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="mx-3" >
                <img className="img-fluid" src={this.props.img} alt="Card image cap" />
                <div className="card-body">
                    <p className="card-text text-center">
                        {this.props.name}
                        </p>                    
                </div>
            </div>
        )
    }
}
