import React, { Component } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import axios from "../../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";

let _this = "";
export default class InquiryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.data,
      name: "",
      email: "",
      contact_number: "",
      messgae: "",
      company_name: "",
      subject: props.subject,
      section: props.section,
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
    };

    _this = this;
    this.fatch = props.fatchData;
  }

  static getDerivedStateFromProps(props, state) {
    state.open = props.data;
    state.subject = props.subject;
    state.section = props.section;
    return state;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let {
      name,
      email,
      contact_number,
      company_name,
      userdata,
      token,
    } = this.state;
    if (token) {
      name = userdata.name;
      email = userdata.email;
      contact_number = userdata.mobile_number;
      company_name = userdata.business_name;
      this.setState({ name, email, contact_number, company_name });
    }
  }

  getComponentData = (name, value) => {
    this.setState({ [name]: value });
  };

  onCloseModal = () => {
    this.setState({ open: false });
    this.fatch("modalOpen", false);
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  sendInquiry = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      company_name: this.state.company_name,
      subject: this.state.subject,
      remark: this.state.messgae,
      section: this.state.section,
      status: "PENDING",
    };

    if (data.company_name == "") {
      _this.notify("danger", "Company Name is required", "2000");
      return;
    }

    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }

    if (data.contact_number == "") {
      _this.notify("danger", "Mobile is required", "2000");
      return;
    }

    axios
      .post("/inquiry/website/add-inquiry", data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__authToken__"),
        },
      })
      .then(function (response) {
        _this.notify("success", "Inquiry added successfully", "2000");
        _this.setState({ open: false });
        _this.fatch("modalOpen", false);
      })
      .catch(function (error) {
        _this.notify("danger", error, "2000");
      });
  };

  render() {
    return (
      <Modal open={this.state.open} onClose={this.onCloseModal}>
        <h4>Send Inquiry</h4>
        <hr />
        <div className="form-group">
          <div>
            <label className="label-control">Name</label>
          </div>
          <div>
            <input
              className="form-control"
              onChange={(e) => this.getComponentData("name", e.target.value)}
              value={this.state.name}
              placeholder={"Name"}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label className="label-control">Email</label>
          </div>
          <div>
            <input
              className="form-control"
              type="email"
              onChange={(e) => this.getComponentData("email", e.target.value)}
              value={this.state.email}
              placeholder={"Email"}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label className="label-control">Contact Numbar</label>
          </div>
          <div>
            <input
              className="form-control"
              type="text"
              onChange={(e) =>
                this.getComponentData("contact_number", e.target.value)
              }
              value={this.state.contact_number}
              placeholder={"Contact Numbar"}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label className="label-control">Company Name</label>
          </div>
          <div>
            <input
              className="form-control"
              onChange={(e) =>
                this.getComponentData("company_name", e.target.value)
              }
              value={this.state.company_name}
              placeholder={"Company Name"}
            />
          </div>
        </div>
        <div className="form-group">
          <div>
            <label className="label-control">Description</label>
          </div>
          <div>
            <textarea
              className="form-control"
              onChange={(e) => this.getComponentData("messgae", e.target.value)}
              value={this.state.messgae}
              placeholder={"Description"}
            ></textarea>
          </div>
        </div>
        <hr />
        <div className="form-group text-right">
          <button className="btn btn-sm btn-white" onClick={this.onCloseModal}>
            Cancel
          </button>
          <button
            className="btn btn-sm btn-success ml-2"
            onClick={this.sendInquiry}
          >
            Send
          </button>
        </div>
      </Modal>
    );
  }
}
