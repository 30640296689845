import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import axios from "../config/Axios";
import toaster from "toasted-notes";

let _this = "";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };

    _this = this;
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  forgetPassword = () => {
    let { email } = this.state;

    let data = {
      email: email,
    };

    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }

    axios
      .post("/retailer/forgot-password-link", data)
      .then((res) => {
        let data = res.data.data;
        if (res.data.status) {
          this.notify("success", "Link send successfully", "2000");
          // localStorage.setItem("__divinaRetailerData__", JSON.stringify(data));
          // localStorage.setItem("__divinaRetailerToken__", res.data.token);
          _this.props.history.goBack();
        } else {
          _this.notify("danger", res.data.message, "2000");
        }
      })
      .catch((err) => {
        _this.notify("danger", err.message, "2000");
      });
  };

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };

  enterKeyHandler = (e) => {
    if (e.key === "Enter") {
      this.forgetPassword();
    }
  };

  render() {
    return (
      <Fragment>
        {localStorage.getItem("__divinaRetailerToken__") ? <Header /> : null}
        <Bread />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "100vh" }}
        >
          <div className="container mb-5 mt-5" style={{ flex: 1 }}>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-7">
                <div
                  className="login-box"
                  style={{ padding: "50px", background: "#232323a6" }}
                >
                  <div className="text-center">
                    <h1>Forgot Password</h1>
                    <small className="mb-0 mt-2 text-capitalize">
                      Kindly Enter your registered email to get a link to reset
                      your password.
                    </small>
                  </div>
                  <div className="form-group mt-4">
                    <div className="">
                      <label>Email Address (Max 100 characters):</label>
                    </div>
                    <div>
                      <input
                        onChange={(e) =>
                          this.fatchData("email", e.target.value)
                        }
                        value={this.state.email}
                        placeholder="Email"
                        className="form-control"
                        type="email"
                        onKeyDown={(e) => this.enterKeyHandler(e)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-sm-between justify-content-center align-items-center mt-3">
                    <button
                      className="btn btn-sm btn-primary mb-3 mb-sm-0"
                      onClick={this.forgetPassword}
                    >
                      Submit
                    </button>
                    <div>
                      <p className="mb-0">
                        <Link to="/login" className="text-white">
                          Login
                        </Link>{" "}
                        |{" "}
                        <Link className="text-white" to="/create-account">
                          Register
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
