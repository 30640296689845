import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Bread from './common/Bread'
import Footer from './common/Footer'
import Header from './common/Header'

export default class CongratulationPage extends Component {

    goback = () => {
        window.location.replace('/')
    }

    render() {
        return (
            <div className="container-fluid p-0">
                <Header />
                <Bread />
                <div className="container h-100 py-md-5 py-3 px-5">
                    <div className="row my-md-5 my-2 text-center justify-content-center">
                        <h1 className="mt-md-5">Congratulation Your registration is successful.</h1>
                        <h3 className="my-md-5 my-5">Registrations are reviewed within 1-2 business days. Please feel free to contact giacert@gmail.com or call +1 (212) 575-4600.</h3>
                        <div className="">
                            <button onClick={() => this.goback()} className="btn btn-primary">Go To Site</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
