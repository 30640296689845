import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import axios from "../config/Axios";
import Skeleton from "react-loading-skeleton";
import { baseURL } from "../constant/defaultValues";

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactUs: {
        city: "",
        country: "",
        state: "",
        zipcode: "",
        address1: "",
        address2: "",
        contact_number: "",
        email: "",
        whatsapp_link: "",
        email_link: "",
        location_link: "",
        call_link: "",
        banner_image: "",
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        console.log(res.data);
        let {
          city,
          country,
          state,
          zipcode,
          address1,
          address2,
          contact_number,
          email,
          whatsapp_link,
          email_link,
          location_link,
          call_link,
          banner_image,
        } = res.data.data[0].contactUs;
        this.setState({
          contactUs: {
            city,
            country,
            state,
            zipcode,
            address1,
            address2,
            contact_number,
            email,
            whatsapp_link,
            email_link,
            location_link,
            call_link,
            banner_image,
          },
        });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  render() {
    return (
      <div className="container-fluid p-0">
        {localStorage.getItem("__divinaRetailerToken__") ? <Header /> : null}
        <Bread />
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "100vh" }}
        >
          <div className="container" style={{ flex: 1 }}>
            <div className="row d-flex">
              <div className="col-lg-6">
                <div className="text-center mt-5">
                  <h2 className="display-4 contact-heading">Contact Us</h2>
                </div>
                <div className="mt-5">
                  <p className="text-center address-line">
                    {this.state.contactUs.address1 ? (
                      this.state.contactUs.address1
                    ) : (
                      <Skeleton count={2} />
                    )}
                    {this.state.contactUs.address2 ? (
                      " " + this.state.contactUs.address2
                    ) : (
                      <Skeleton count={2} />
                    )}{" "}
                    <br />
                    {this.state.contactUs.city} {this.state.contactUs.state}{" "}
                    {this.state.contactUs.zipcode} <br />
                    {this.state.contactUs.country}
                    <br />
                  </p>

                  <p className="address-line text-center mt-2">
                    {this.state.contactUs.contact_number}
                  </p>
                  <p className="address-line text-center mt-2">
                    {this.state.contactUs.email}
                  </p>
                  <ul className="list-unstyled  d-flex flex-wrap justify-content-center">
                    <a
                      href={`tel:${this.state.contactUs.contact_number}`}
                      className="text-white"
                    >
                      <li className="contact-icon">
                        <i className="fa fa-phone " aria-hidden="true"></i>
                      </li>
                    </a>

                    <a
                      href={this.state.contactUs.whatsapp_link}
                      target="_blank"
                      className="text-white"
                    >
                      <li className="contact-icon">
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                      </li>
                    </a>

                    <a
                      href={`mailto:${this.state.contactUs.email}`}
                      className="text-white"
                    >
                      <li className="contact-icon">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </li>
                    </a>

                    <a
                      href={this.state.contactUs.location_link}
                      target="_blank"
                      className="text-white"
                    >
                      <li className="contact-icon">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </li>
                    </a>

                    <a
                      href="/schedule-appointment"
                      target="_blank"
                      className="text-white"
                    >
                      <li className="contact-icon">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </li>
                    </a>
                  </ul>
                </div>

                <div className="d-flex justify-content-center text-big-35">
                  <Link to="/events" className="text-white">
                    <p style={{ fontWeight: 100 }}>SHOWS AND EVENTS</p>
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 contact-banner"
                style={{
                  backgroundImage: `url(${
                    baseURL + "uploads/Divina-Web-Resources/Contact-Us-Page.jpg"
                  })`,
                }}
              ></div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
