import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import lineImg from "../assets/line.png";
import img1 from "../assets/images/estate-1.png";
import img2 from "../assets/Estate-Banner.png";
import axios from "../config/Axios";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Estatejewllery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estatePageBannerImage: "",
      estatePageDescription: "",
      items: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        let { estatePageBannerImage, estatePageDescription } = res.data.data[0];
        this.setState({ estatePageBannerImage, estatePageDescription });
      })
      .catch((err) => {
        console.log("err");
      });

    this.setState({ loader: true });
    axios
      .get("/jewelleryCategory/website")
      .then((res) => {
        this.setState({ items: res.data.data, loader: false });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-none"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.estatePageBannerImage
            })`,
          }}
        >
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    Estate Jewelry
                  </p>
                  <div className="cat-desc-text text-center custom-cat-desc">
                    <p className="desc-text">
                      {this.state.estatePageDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-6 mt-0 mt-lg-5 mb-0 mb-lg-auto"
              style={{ marginBottom: "21px" }}
            >
              <ul className="list-unstyled mt-lg-5 mt-3 jwelery-cats text-center custom-grid">
                {this.state.items.length ? (
                  this.state.items.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        to={"/estate-jewelry/" + item.name.replace(/\s/g, "-")}
                        className="td-none"
                      >
                        <li>{item.name}</li>
                      </Link>
                    );
                  })
                ) : (
                  <>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div
              className="col-md-6 jwellery-cat-banner"
              // style={{ backgroundImage: "url('" + this.state.bgImg + "')" }}
            >
              <div className="position-relative jewelryImageBox h-100">
                <img
                  src={baseURL + "uploads/Divina-Web-Resources/Estate.jpg"}
                  className="img-fluid"
                  style={{ zIndex: 1 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 container d-none">
          <div className="row ">
            {this.state.items.length ? (
              this.state.items.map((item, i) => {
                return item.name ? (
                  <div className="col-md-3 d-flex" id={item._id} key={i}>
                    <Link
                      to={"/estate-jewelry/" + item.name.replace(/\s/g, "-")}
                      className="text-white"
                    >
                      <div className="product-box" style={{ border: "none" }}>
                        <div className="product-image-box">
                          <img
                            src={
                              item.image
                                ? baseURL + item.image
                                : "https://divinacreation.s3.ap-south-1.amazonaws.com/estate_img_14KT%20White%20Gold%202ct%20Y%20VS1%20Halo%20Engagement%20Ring%09_0_2020_58_5_15_58_10.jpg"
                            }
                            className="img-fluid"
                          />
                        </div>
                        <div className="product-box-effect">
                          <p className="text-center text-white mb-0">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : null;
              })
            ) : this.state.loader ? (
              <>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
                <div className="col-md-3 mb-3">
                  <Skeleton count={1} height={250} />
                </div>
              </>
            ) : null}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
