import React, { Component } from "react";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Header from "./common/Header";
import axios from "../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
let _this = "";

var dtToday = new Date();

var month = dtToday.getMonth() + 1;
var day = dtToday.getDate();
var year = dtToday.getFullYear();
if (month < 10) month = "0" + month.toString();
if (day < 10) day = "0" + day.toString();

var maxDate = year + "-" + month + "-" + day;

// var hours = dtToday.getHours();
// var minutes = dtToday.getMinutes();

// console.log(hours, minutes);

export default class ScheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      contact_number: "",
      messgae: "",
      company_name: "",
      date: "",
      time: "",
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
    };
    _this = this;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let { name, email, contact_number, company_name, userdata, token } =
      this.state;
    if (token) {
      name = userdata.name;
      email = userdata.email;
      contact_number = userdata.mobile_number;
      company_name = userdata.business_name;
      this.setState({ name, email, contact_number, company_name });
    }
  }

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  getComponentData = (name, value) => {
    this.setState({ [name]: value });
  };

  addAppointment = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      company_name: this.state.company_name,
      subject: this.state.subject,
      description: this.state.messgae,
      date: this.state.date,
      time: this.state.time,
      status: "PENDING",
    };

    if (data.name == "") {
      _this.notify("danger", "Name is required", "2000");
      return;
    }

    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }

    if (data.contact_number == "") {
      _this.notify("danger", "Phone is required", "2000");
      return;
    }

    if (data.company_name == "") {
      _this.notify("danger", "Company Name is required", "2000");
      return;
    }

    if (data.subject == "") {
      _this.notify("danger", "Subject is required", "2000");
      return;
    }

    if (data.date == "") {
      _this.notify("danger", "Date is required", "2000");
      return;
    } else if (data.date < maxDate) {
      _this.notify("danger", "Invalid Date is required", "2000");
      return;
    }

    if (data.time == "") {
      _this.notify("danger", "Time is required", "2000");
      return;
    }

    axios
      .post("/appointment/website/add-appointment", data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__authToken__"),
        },
      })
      .then(function (response) {
        _this.notify("success", "Appointment  added successfully", "2000");
        _this.setState({
          open: false,
          subject: "",
          messgae: "",
          date: "",
          time: "",
        });
      })
      .catch(function (error) {
        this.notify("danger", error.message, "2000");
      });
  };
  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <>
        <Header />
        <Bread />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 d-none px-0">
              <div
                className="h-100"
                style={{
                  backgroundImage: "url()",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPositionY: "center",
                }}
              ></div>
            </div>
            <div className="col-md-6 pt-5">
              <div className="row py-5 justify-content-center">
                <div className="col-md-10 py-5">
                  <div className="text-center">
                    <h1 style={{ fontWeight: 100 }}>REQUEST AN APPOINTMENT</h1>
                    <h6 style={{ fontWeight: 100 }}>
                      We love to see our customers. Let us know your desired
                      date and time and we’ll get back to you with a
                      confirmation.
                    </h6>
                  </div>
                  <div className="my-5 appointmentBox">
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">NAME</div>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData("name", e.target.value)
                            }
                            value={this.state.name}
                            placeholder={"NAME"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">COMPANY NAME</div>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData(
                                "company_name",
                                e.target.value
                              )
                            }
                            value={this.state.company_name}
                            placeholder={"COMPANY NAME"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">EMAIL</div>
                        <div className="col-md-8">
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData("email", e.target.value)
                            }
                            value={this.state.email}
                            placeholder={"EMAIL"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">PHONE</div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData(
                                "contact_number",
                                e.target.value
                              )
                            }
                            value={this.state.contact_number}
                            placeholder={"PHONE"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">SUBJECT</div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData("subject", e.target.value)
                            }
                            value={this.state.subject}
                            placeholder={"SUBJECT"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">COMMENT</div>
                        <div className="col-md-8">
                          <textarea
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData("messgae", e.target.value)
                            }
                            value={this.state.messgae}
                            placeholder={"COMMENT"}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">DATE</div>
                        <div className="col-md-8">
                          <input
                            type="date"
                            placeholder="DATE"
                            className="form-control"
                            min={maxDate}
                            onChange={(e) =>
                              this.getComponentData("date", e.target.value)
                            }
                            value={this.state.date}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <div className="col-md-4">TIME</div>
                        <div className="col-md-8">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              this.getComponentData("time", e.target.value)
                            }
                            value={this.state.time}
                          >
                            <option value="0">Preferred Time</option>
                            <option value="10am">10am </option>
                            <option value="10:30am">10:30am</option>
                            <option value="11am">11am</option>
                            <option value="11:30am">11:30am</option>
                            <option value="12pm">12pm</option>
                            <option value="12:30pm">12:30pm</option>
                            <option value="1pm">1pm</option>
                            <option value="1:30pm">1:30pm</option>
                            <option value="2pm">2pm</option>
                            <option value="2:30pm">2:30pm</option>
                            <option value="3pm">3pm</option>
                            <option value="3:30pm">3:30pm</option>
                            <option value="4pm">4pm</option>
                            <option value="4:30pm">4:30pm</option>
                            <option value="5pm">5pm</option>
                            <option value="5:30pm">5:30pm</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <div className="d-flex justify-content-center">
                        <div className="col-md-8">
                          <button
                            className="btn btn-primary btn-sm btn-block"
                            onClick={this.addAppointment}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
