import React, { Component } from 'react'

export default class Numericinput extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             value:0
        }
    }
    

    handlePlusMinus = (op)=>{
        if(op == 'inc'){
            let prevVal = this.state.value;
            this.setState({value:prevVal+1})
        }

        if(op == 'dec'){
            let prevVal = this.state.value;
            if( prevVal >=1){
                this.setState({value:prevVal-1})
            }
            
        }

    }
    render() {
        return (
            <>
                <div className={"d-flex numeric-inp"}>
                <button className="btn radius-left bg-secondary text-white" onClick={()=>this.handlePlusMinus('dec')}><span className="px-1">-</span></button>
                <input type="text" className="btn bg-dark radius-none text-white px-2" value={this.state.value} />
                <button className="btn radius-right bg-secondary text-white" onClick={()=>this.handlePlusMinus('inc')}><span className="px-1">+</span></button>
                </div>
            </>
        )
    }
}
