import React, { Component, Fragment } from "react";
import { baseURL } from "../constant/defaultValues";
import Footer from "./common/Footer";
import Header from "./common/Header";

export default class AboutUS extends Component {
  render() {
    return (
      <Fragment>
        {localStorage.getItem("__divinaRetailerToken__") ? <Header /> : null}
        <div className="container-fluid px-0">
          <div className="row pb-5 mx-0" style={{ paddingTop: "150px" }}>
            <div className="col-md-12 px-0">
              <img
                src={baseURL + "uploads/Divina-Web-Resources/About-Us4.jpg"}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
