import React, { Component } from "react";
import Header from "../../common/Header";
import Bread from "../../common/Bread";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import { baseURL } from "../../../constant/defaultValues";
import Skeleton from "react-loading-skeleton";

import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Jewelry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bgImg: "/IM-default.jpg",
      images: [
        "/IM-default.jpg",
        "/IM-default.jpg",
        "/IM-default.jpg",
        "/IM-default.jpg",
      ],
      selectedImage: "/IM-default.jpg",
      categories: [],
    };
  }

  componentDidMount() {
    const requestBody = {
      offset: "",
      limit: "10",
      query: "",
      params: [],
      page: "1",
      orderBy: "",
      filterBy: [{}],
      forExport: "1",
    };
    axios
      .post(`${baseURL}custom-category/website`, requestBody)
      .then((response) => {
        const categories = response.data.data;
        this.setState({ categories });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }

  changeBg = (img) => {
    switch (img) {
      case "rings":
        this.setState({
          bgImg: "/IM-ring.jpg",
        });
        break;

      case "bracelet":
        this.setState({
          bgImg: "/IM-bracelet.jpg",
        });
        break;

      case "earrings":
        this.setState({
          bgImg: "/IM-earring.jpg",
        });
        break;
      default:
        this.setState({
          bgImg: "/IM-default.jpg",
        });
    }
  };

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />

        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-6 mt-0 mt-lg-5 mb-0 mb-lg-auto"
              style={{ marginBottom: "21px" }}
            >
              <ul className="list-unstyled mt-lg-5 mt-3 jwelery-cats text-center custom-grid">
                {this.state.categories.length ? (
                  this.state.categories.map((category) => {
                    return (
                      <Link
                        key={category._id}
                        to={`/jewelry/${category.category_code}`}
                        className="td-none"
                        style={{ textTransform: "capitalize" }}
                      >
                        <li
                          onMouseEnter={() => {
                            this.changeBg(category.category_code);
                          }}
                          onMouseLeave={() => {
                            this.changeBg("");
                          }}
                        >
                          {category.name}
                        </li>
                      </Link>
                    );
                  })
                ) : (
                  <>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                    <li>
                      <Skeleton count={1} width={300} />
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div
              className="col-md-6 jwellery-cat-banner"
              // style={{ backgroundImage: "url('" + this.state.bgImg + "')" }}
            >
              <div className="position-relative jewelryImageBox h-100">
                {/* <img
                  src={"/IM-Jewelry.jpg"}
                  className="img-fluid"
                  style={{ zIndex: 1 }}
                /> */}
                {this.state.images.map((el, i) => {
                  return (
                    <img
                      key={i}
                      src={baseURL + "uploads/Divina-Web-Resources/" + el}
                      className="img-fluid"
                      style={{
                        zIndex: this.state.bgImg == el ? 1 : 0,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
