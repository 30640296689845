import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import uploadImg from "../assets/upload.png";
import Skeleton from "react-loading-skeleton";
import axios from "../config/Axios";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

let _this = "";
export default class Webuy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weBuyPageBannerImage: "",
      weBuyPageDescription: "",
      weBuyPageContactNumber: "",
      weBuyPageAddress: "",
      weBuyPageBusinessHour: [],
      name: "",
      email: "",
      contact_number: "",
      message: "",
      company_name: "",
      subject: "We Buy Page Inquiry",
      section: "We Buy",
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
    };

    _this = this;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        let {
          weBuyPageBannerImage,
          weBuyPageDescription,
          weBuyPageContactNumber,
          weBuyPageAddress,
          weBuyPageBusinessHour,
        } = res.data.data[0];
        this.setState({
          weBuyPageBannerImage,
          weBuyPageDescription,
          weBuyPageContactNumber,
          weBuyPageAddress,
          weBuyPageBusinessHour,
        });
      })
      .catch((err) => {
        console.log("err");
      });

    let { name, email, contact_number, company_name, userdata, token } =
      this.state;
    if (token) {
      name = userdata.name;
      email = userdata.email;
      contact_number = userdata.mobile_number;
      company_name = userdata.business_name;
      this.setState({ name, email, contact_number, company_name });
    }
  }

  getComponentData = (name, value) => {
    this.setState({ [name]: value });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  sendInquiry = () => {
    let data = {
      name: this.state.name,
      email: this.state.email,
      contact_number: this.state.contact_number,
      company_name: this.state.company_name,
      subject: this.state.subject,
      remark: this.state.message,
      section: this.state.section,
      status: "PENDING",
    };

    if (data.name == "") {
      _this.notify("danger", "Name is required", "2000");
      return;
    }

    if (data.email == "") {
      _this.notify("danger", "Email is required", "2000");
      return;
    }

    if (data.contact_number == "") {
      _this.notify("danger", "Mobile is required", "2000");
      return;
    }

    axios
      .post("/inquiry/website/add-inquiry", data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__authToken__"),
        },
      })
      .then(function (response) {
        _this.notify("success", "Inquiry added successfully", "2000");
        _this.setState({ open: false, message: "" });
      })
      .catch(function (error) {
        _this.notify("danger", error.message, "2000");
      });
  };

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.weBuyPageBannerImage
            })`,
          }}
        >
          <div className="blur-bg">
            <div className="container h-100">
              <div className="d-flex flex-wrap justify-content-center align-items-center h-100">
                <div>
                  <p className="text-center align-self-center jumbo-cat-name">
                    We Buy
                  </p>
                  <div className="cat-desc-text text-center custom-cat-desc d-none d-lg-block">
                    <p className="desc-text">
                      {this.state.weBuyPageDescription}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cat-desc-text text-center custom-cat-desc d-block d-lg-none my-3">
          <p className="desc-text">{this.state.weBuyPageDescription}</p>
        </div>

        <div className="container ">
          {/* <div className="mt-5">
            <p className="text-center mb-5 desc-text">
              {this.state.weBuyPageDescription}
            </p>
          </div> */}

          <div className="mx-auto bg-dark video-container justify-content-center align-item-middle d-flex">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/NIhyWNrsPKc"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-7">
              <h5 className="border-bottom pb-2 d-inline-block">
                Sell us your item
              </h5>

              <form className="mt-5">
                <div className="form-group form-bottom-border-parent">
                  <label
                    htmlFor="name"
                    className={this.state.name ? "active" : ""}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name=""
                    id="name"
                    className="form-control inp-form"
                    aria-describedby="helpId"
                    autoComplete="false"
                    onChange={(e) =>
                      this.getComponentData("name", e.target.value)
                    }
                    value={this.state.name}
                  />
                </div>

                <div className="form-group form-bottom-border-parent">
                  <label
                    htmlFor="email"
                    className={this.state.email ? "active" : ""}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    name=""
                    id="email"
                    className="form-control inp-form"
                    aria-describedby="helpId"
                    autoComplete="false"
                    onChange={(e) =>
                      this.getComponentData("email", e.target.value)
                    }
                    value={this.state.email}
                  />
                </div>

                <div className="form-group form-bottom-border-parent">
                  <label
                    htmlFor="mobile"
                    className={this.state.contact_number ? "active" : ""}
                  >
                    Mobile
                  </label>
                  <input
                    type="text"
                    name=""
                    id="mobile"
                    className="form-control inp-form"
                    aria-describedby="helpId"
                    autoComplete="false"
                    onChange={(e) =>
                      this.getComponentData("contact_number", e.target.value)
                    }
                    value={this.state.contact_number}
                  />
                </div>

                <div className="form-group form-bottom-border-parent mt-5">
                  <label
                    htmlFor="mobile"
                    className={this.state.message ? "active" : ""}
                  >
                    Item Description
                  </label>
                  <textarea
                    type="text"
                    rows="4"
                    name=""
                    id="mobile"
                    className="form-control inp-form"
                    aria-describedby="helpId"
                    autoComplete="false"
                    onChange={(e) =>
                      this.getComponentData("message", e.target.value)
                    }
                    value={this.state.message}
                  >
                    {this.state.message}
                  </textarea>
                </div>

                <div className="text-center">
                  <button
                    className="btn btn-dark px-5"
                    type="button"
                    onClick={this.sendInquiry}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-5  mt-4 mt-lg-0">
              <div className="contact-col p-lg-5 p-2">
                <h5 className="border-bottom pb-2 d-inline-block">
                  Contact Us
                </h5>

                <div className="mt-2 mt-lg-5 bg-dark p-2">
                  <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                  <span>{this.state.weBuyPageContactNumber}</span>
                </div>

                <div className="my-2 bg-dark p-2">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                  <span>{this.state.weBuyPageAddress}</span>
                </div>

                <div className="my-2 bg-dark p-2">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                  <span>Store Hours</span>
                  <table width="100%">
                    <tbody>
                      {this.state.weBuyPageBusinessHour.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>{e.day}</td>
                            <td>{e.time}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
