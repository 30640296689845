import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import axios from "../config/Axios";
import InquiryModal from "./common/InquiryModal";
import { Link } from "react-router-dom";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export default class Customdesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customizationPageBannerImage: "",
      modalOpen: false,
      contactUs: {
        city: "",
        country: "",
        state: "",
        zipcode: "",
        address1: "",
        address2: "",
        contact_number: "",
        email: "",
        whatsapp_link: "",
        email_link: "",
        location_link: "",
        call_link: "",
        banner_image: "",
      },
    };
  }

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/cms/website")
      .then((res) => {
        let { customizationPageBannerImage, contactUs } = res.data.data[0];
        this.setState({ customizationPageBannerImage, contactUs });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />
        <div
          className="cat-jumbo  d-flex"
          style={{
            backgroundImage: `url(${
              baseURL + this.state.customizationPageBannerImage
            })`,
          }}
        >
          <div className="blur-bg align-self-stretch d-flex justify-content-center ">
            <p className="text-center align-self-center jumbo-cat-name">
              Customization
            </p>
          </div>
        </div>

        <div className="mt-2 text-center">
          <Link to={"/gallery"}>
            <button className="btn btn-md btn-light ft-lato-bold py-3">
              Visit Custom Gallery
            </button>
          </Link>
        </div>

        <div className="container">
          <div className="text-center my-5">
            <span className="display-4">We create what you can visualize</span>
          </div>

          <div className="text-center">
            <img
              src={baseURL + "uploads/Divina-Web-Resources/Custom1.jpg"}
              className="img-fluid"
            />
          </div>

          <div className="my-3">
            <p>
              Whether, developing a design from scratch or enhancing the beauty
              of an existing piece of jewelry, we provide you with the finest
              work of art. The process undertaken for any custom work is
              meticulously monitored by our skilled craftsman based in New York.
              Over the years, we have
            </p>
          </div>

          <div className="my-5">
            <span className="display-4">
              designed and crafted exotic pieces of fine jewelry
            </span>
          </div>

          <div className="text-center ">
            <img
              src={baseURL + "uploads/Divina-Web-Resources/custom2.jpg"}
              className="img-fluid"
            />
          </div>

          <div className="my-5">
            <p>
              for high end stores demanding nothing less than perfection. Our
              team of in-house designers work relentlessly to produce flawless
              pieces of art. We begin by sketching your imagination on paper or
              work on the sketches you bring in to explore different
              possibilities. Once you are satisfied with the concept, only then
              we begin different phases of manufacturing.
            </p>
            <p>
              Our master jeweler works every step of the way with you to ensure
              that end product exceeds your expectations.
            </p>
          </div>

          <div className="">
            <div className="mx-auto bg-dark video-container justify-content-center align-item-middle d-flex">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/CFdazbZggUg"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="mt-5">
            <span className="display-4">
              WE INVITE YOU TO GIVE US A CALL AND EXPERIENCE THE MAGIC
            </span>
          </div>

          <div className="mt-5">
            <div className="custom-contact-box">
              <div className="text-center py-3 bg-dark mx-0 px-0">
                <span>GIVE RELAITY TO YOUR THOUGHTS</span>
              </div>
              <div className="text-center py-3">
                <span>CUSTOMIZE YOUR DESIGN NOW</span>
              </div>
              <div className="d-flex justify-content-between p-lg-5 p-2 py-4 ">
                <a
                  href={`tel:${this.state.contactUs.contact_number}`}
                  className="text-white"
                >
                  <div className="contact-icon-custom text-center ">
                    <div className="m-0">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <span>Call</span>
                    <div>
                      <small>{this.state.contactUs.contact_number}</small>
                    </div>
                  </div>
                </a>

                <a
                  href={this.state.contactUs.whatsapp_link}
                  target="_blank"
                  className="text-white"
                >
                  <div className="contact-icon-custom text-center ">
                    <div className="m-0">
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    </div>
                    <span>Whatsapp</span>
                  </div>
                </a>
                <a
                  href={`mailto:${this.state.contactUs.email}`}
                  className="text-white"
                >
                  <div className="contact-icon-custom text-center ">
                    <div className="m-0">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </div>
                    <span>Email</span>
                  </div>
                </a>

                <div
                  className="contact-icon-custom text-center "
                  onClick={() =>
                    this.setState({ modalOpen: !this.state.modalOpen })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="m-0">
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </div>
                  <span>Write Us</span>
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
              <img
                src={baseURL + "uploads/Divina-Web-Resources/custom3.jpg"}
                className="img-fluid"
              />
            </div>

            <div className="text-center my-5">
              <img
                src={baseURL + "uploads/Divina-Web-Resources/Custom4.jpg"}
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <InquiryModal
          data={this.state.modalOpen}
          fatchData={this.fatchData}
          subject={`Inquiry from Custom Desing`}
          section="Custom Desing"
        />
        <Footer />
      </div>
    );
  }
}
