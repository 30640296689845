import React, { Component } from "react";
// import { Link } from 'react-router-dom'
import "../style.css";
import $ from "jquery";
import axios from "../config/Axios";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
let _this = "";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.state = {
      videoURL: "/divina_video.webm",
      bullets: [
        { target: "#home", activeClass: "current", to: "home" },
        { target: "#diamond", activeClass: "", to: "diamond" },
        { target: "#jewlery", activeClass: "", to: "jewlery" },
        { target: "#estate", activeClass: "", to: "estate" },
        { target: "#custome", activeClass: "", to: "custome" },
        { target: "#webuy", activeClass: "", to: "webuy" },
        { target: "#contact", activeClass: "", to: "contact" },
      ],
      cms: {
        homePageDiamondImage: "",
        homePageJweleryImage: "",
        homePageEstateImage: "",
        homePageCustomizeImage: "",
        homePageWeBuyImage: "",
        homePageContactusImage: "",
      },
      promotionData: [],
      email: "",
    };
    _this = this;
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    axios
      .get("/cms/website")
      .then((res) => {
        let {
          homePageDiamondImage,
          homePageJweleryImage,
          homePageEstateImage,
          homePageCustomizeImage,
          homePageWeBuyImage,
          homePageContactusImage,
        } = res.data.data[0];
        this.setState({
          cms: {
            homePageDiamondImage,
            homePageJweleryImage,
            homePageEstateImage,
            homePageCustomizeImage,
            homePageWeBuyImage,
            homePageContactusImage,
          },
        });
      })
      .catch((err) => {
        console.log("err");
      });

    axios
      .get("/promotionalBanner/website")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            promotionData: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  }

  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  scrollToWithContainer() {
    let goToContainer = new Promise((resolve, reject) => {
      Events.scrollEvent.register("end", () => {
        resolve();
        Events.scrollEvent.remove("end");
      });

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    goToContainer.then(() =>
      scroller.scrollTo("scroll-container-second-element", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
    );
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  removeBanner = (i) => {
    let data = this.state.promotionData;
    console.log(data);
    console.log(data.splice(i, 1));
    console.log(data);
    this.setState({ promotionData: data });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  saveEmail = (i) => {
    let { email } = this.state;
    if (email == "") {
      return false;
    }

    axios
      .post(
        "/mail/website/add-mail",
        { mail_data: email },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("__authToken__"),
          },
        }
      )
      .then(function (response) {
        _this.removeBanner(i);
        _this.notify("success", "subscribed successfully", "2000");
        _this.setState({ open: false, email: "" });
      })
      .catch(function (error) {
        _this.notify("danger", error.message, "2000");
      });
  };

  render() {
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }
    return (
      <>
        {this.state.promotionData.length
          ? this.state.promotionData.map((el, i) => {
              return (
                <div
                  className="promotionBannerBox"
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.removeBanner(i)}
                >
                  <div className="row w-100 justify-content-center">
                    <div
                      className="col-md-6 promotionContentBox animate__animated animate__backInDown d-flex justify-content-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="promotionImageBox">
                        <img
                          src={baseURL + el.images[0]}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}

        <div className="container-fluid p-0">
          <Element name="home" className="element">
            <section className="full-page divina-video" id="home">
              <video autoPlay id="background-video" loop autoPlay muted>
                <source src={this.state.videoURL} type="video/mp4" />
                <source src={this.state.videoURL} type="video/ogg" />
                <source src={this.state.videoURL} type="video/webm" />
              </video>
              <div
                className="text-center home-page-video-banner"
                style={{ background: "rgb(0 0 0 / 68%)" }}
              >
                <img src="/Divina_Logo.svg" />
                {/* <div
                  style={{ backgroundImage: "url(/Divina_Logo.svg)" }}
                  className="home-page-video-banner"
                ></div> */}
              </div>
            </section>
          </Element>

          <Element name="diamond" className="element">
            <a href="/diamonds" className="td-none" id="diamond">
              <section
                className="diamonds bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageDiamondImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">DIAMONDS</p>
                </div>
              </section>
            </a>
          </Element>

          <Element name="jewelry" className="element">
            <a href="/jewelry" className="td-none" id="jewlery">
              <section
                className="jwellery bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageJweleryImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">Jewelry</p>
                </div>
              </section>
            </a>
          </Element>

          <Element name="estate" className="element">
            <a href="/estate-jewelry" className="td-none" id="estate">
              <section
                className="estate-jewelry bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageEstateImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">ESTATE Jewelry</p>
                </div>
              </section>
            </a>
          </Element>

          <Element name="custome" className="element">
            <a href="/custom-design" className="td-none" id="custome">
              <section
                className="custom-design bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageCustomizeImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">CUSTOM DESIGN</p>
                </div>
              </section>
            </a>
          </Element>

          <Element name="webuy" className="element">
            <a href="/we-buy" className="td-none" id="webuy">
              <section
                className="we-buy bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageWeBuyImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">We Buy</p>
                </div>
              </section>
            </a>
          </Element>

          <Element name="contact" className="element">
            <a href="/contact-us" className="td-none" id="contact">
              <section
                className="contact-us bg-fixed"
                style={{
                  backgroundImage: `url(${
                    baseURL + this.state.cms.homePageContactusImage
                  })`,
                }}
              >
                <div className="full-page home-page-blur-bg text-center d-flex flex-column justify-content-center align-middle">
                  <p className="category-heading-name">Contact Us</p>
                </div>
              </section>
            </a>
          </Element>

          <div className="dotstyle dotstyle-scaleup home-nav">
            <ul className="d-flex flex-column">
              {this.state.bullets.map((e, i) => {
                return (
                  <li
                    key={i}
                    className={"mt-4 mt-lg-5 "}
                    // onClick={() => this.handleScrollBullet(i)}
                  >
                    <Link
                      activeClass="active"
                      className={e.to}
                      to={e.to}
                      spy={true}
                      smooth={true}
                      duration={500}
                    ></Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}
