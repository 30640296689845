import React, { Component } from "react";
import Header from "./common/Header";
import Bread from "./common/Bread";
import Footer from "./common/Footer";
import Productcard from "../Components/Productcard";
import lineImg from "../assets/line.png";
import img1 from "../assets/images/ring-1.png";
import img2 from "../assets/images/ring-2.png";
import img3 from "../assets/images/ring-3.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import Magnifier from "react-magnifier";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import axios from "../config/Axios";

import uploadImg from "../assets/upload.png";
import InquiryModal from "./common/InquiryModal";
import { baseURL } from "../constant/defaultValues";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

let _this = "";

export default class Estate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estatePageSliderTitle: "",
      estatePageSliderData: [],
      product_name: "",
      description: "",
      images: [],
      declaimer: "Disclaimer: All Diamond Weights are approx weights",
      topTenProducts: [],
      features: [{ key: "custom_fields", displayName: "", value: "" }],
      modalOpen: false,
      userdata:
        localStorage.getItem("__divinaRetailerData__") !== null
          ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
          : "",
      token:
        localStorage.getItem("__divinaRetailerData__") !== null ? true : false,
      selectedImage: "",
    };

    _this = this;
  }

  fatchData = (name, value) => {
    this.setState({ [name]: value });
  };

  notify = (type, msg, duration) => {
    toaster.notify(<div className={"toaster " + type}>{msg}</div>, {
      duration: duration,
      position: "bottom",
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let id = this.props.match.params.id;
    this.setState({ loader: true });
    axios
      .get("/cms/website")
      .then((res) => {
        let { estatePageSliderTitle, estatePageSliderData } = res.data.data[0];
        this.setState({ estatePageSliderTitle, estatePageSliderData });
      })
      .catch((err) => {
        console.log("err");
      });

    axios
      .get("/estate/website/" + id, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("__divinaRetailerToken__"),
        },
      })
      .then((res) => {
        let data = _this.state;

        if (res.data.data.length) {
          for (const [key, value] of Object.entries(res.data.data[0])) {
            data.features.forEach((element) => {
              if (element.key == key) {
                element.value = value;
              }
            });
          }

          data.product_name = res.data.data[0].product_name;
          data.description = res.data.data[0].product_description;
          data.images =
            res.data.data[0].images.length > 0
              ? res.data.data[0].images
              : data.images;
          data.selectedImage = data.images[0];
        }

        this.setState({ ...data, loader: false });
      })
      .catch((err) => {
        this.setState({ loader: false });
        this.notify("danger", err.message, "2000");
      });
  }

  render() {
    console.log(this.state);
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 7,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    if (localStorage.getItem("__divinaRetailerToken__") === null) {
      return <Redirect to={"/login"} />;
    }

    return (
      <div className="container-fluid p-0">
        <Header />
        <Bread />

        <div className="container-fluid">
          <div className="d-flex flex-wrap py-5 ">
            <div className="col-lg-4">
              <div className="main-img-container text-center">
                {this.state.loader ? (
                  <Skeleton count={1} height={400} />
                ) : (
                  <Magnifier
                    src={baseURL + this.state.selectedImage}
                    width={500}
                    zoomImgSrc={baseURL + this.state.selectedImage}
                    zoomFactor={1.5}
                  />
                )}
              </div>
            </div>

            <div className="col-lg-4 d-block d-lg-none my-3">
              <div className="row">
                {this.state.loader ? (
                  <>
                    <div className="col-3">
                      <Skeleton count={1} height={50} />
                    </div>
                    <div className="col-3">
                      <Skeleton count={1} height={50} />
                    </div>
                    <div className="col-3">
                      <Skeleton count={1} height={50} />
                    </div>
                    <div className="col-3">
                      <Skeleton count={1} height={50} />
                    </div>
                  </>
                ) : (
                  this.state.images.map((el, i) => {
                    return el ? (
                      <div className="col-3" key={i}>
                        <div
                          style={{ cursor: "pointer" }}
                          className={
                            this.state.selectedImage == el
                              ? "thum-img-container active"
                              : "thum-img-container"
                          }
                          onClick={() => this.setState({ selectedImage: el })}
                        >
                          <img src={baseURL + el} className="img-fluid" />
                        </div>
                      </div>
                    ) : null;
                  })
                )}
              </div>
            </div>

            <div className="col-lg-8">
              <h1 className="ft-lato bb-dashed fw-200 fs-2r">
                STYLE#{" "}
                {this.state.product_name == "" ? (
                  <Skeleton count={1} width={200} height={40} />
                ) : (
                  this.state.product_name
                )}
              </h1>
              <p className="ft-lato mt-3">
                {this.state.product_name ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.description,
                    }}
                  />
                ) : (
                  <>
                    <Skeleton count={1} width={1000} height={20} />
                    <Skeleton count={1} width={1000} height={20} />
                    <Skeleton count={1} width={1000} height={20} />
                    <Skeleton count={1} width={500} height={20} />
                  </>
                )}
              </p>

              <div className="row">
                <div className="col-lg-8">
                  <table className="table table-bordered mt-3 mb-0">
                    <tbody>
                      {this.state.features.map((e, i) => {
                        return e.key !== "custom_fields" ? (
                          <tr key={i}>
                            <td className="text-capitalize">{e.displayName}</td>
                            <td className="fw-200">
                              {this.state.loader ? (
                                <Skeleton count={1} width={200} height={20} />
                              ) : (
                                e.value
                              )}
                            </td>
                          </tr>
                        ) : e.value.length ? (
                          e.value.map((custom, index) => {
                            return custom.field_name ? (
                              <tr key={index}>
                                <td className="text-capitalize">
                                  {custom.field_name}
                                </td>
                                <td className="fw-200 text-uppercase">
                                  {custom.field_value == "" ? (
                                    <Skeleton
                                      count={1}
                                      width={200}
                                      height={20}
                                    />
                                  ) : (
                                    custom.field_value
                                  )}
                                </td>
                              </tr>
                            ) : null;
                          })
                        ) : null;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap mt-0 align-items-center mb-5">
            <div className="col-lg-4 d-none d-lg-block">
              <div className="row">
                {this.state.loader ? (
                  <>
                    <div className="col-lg-3">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-lg-3">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-lg-3">
                      <Skeleton count={1} height={150} />
                    </div>
                    <div className="col-lg-3">
                      <Skeleton count={1} height={150} />
                    </div>
                  </>
                ) : (
                  this.state.images.map((el, i) => {
                    return el ? (
                      <div className="col-lg-3" key={i}>
                        <div
                          style={{ cursor: "pointer" }}
                          className={
                            this.state.selectedImage == el
                              ? "thum-img-container active"
                              : "thum-img-container"
                          }
                          onClick={() => this.setState({ selectedImage: el })}
                        >
                          <img src={baseURL + el} className="img-fluid" />
                        </div>
                      </div>
                    ) : null;
                  })
                )}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="product-action-buttons">
                <div className="d-flex flex-wrap">
                  <div className="col-lg-6 pl-lg-0 px-0 px-lg-auto">
                    {this.state.token ? (
                      <a
                        href={`https://api.whatsapp.com/send?phone=+19179579177&text=Hey!, I want to purchase the "Estate": *${this.state.product_name}* *Name*: ${this.state.userdata.name} *Company*:${this.state.userdata.business_name} *Contact Number*: ${this.state.userdata.mobile_number} *Email ID*: ${this.state.userdata.email} Please call me or email me on the same`}
                        target="_blank"
                      >
                        <button className="btn btn-block py-3 btn-outline-light ft-lato-bold">
                          <i className="fa fa-whatsapp" aria-hidden="true"></i>
                          <span className="ml-2"> ENQUIRE NOW</span>
                        </button>
                      </a>
                    ) : (
                      <button
                        className="btn btn-block py-3 btn-outline-light ft-lato-bold"
                        onClick={() =>
                          this.notify("danger", "Please Login First", "2000")
                        }
                      >
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                        <span className="ml-2"> ENQUIRE NOW</span>
                      </button>
                    )}
                  </div>

                  <div className="col-lg-6 pr-lg-0 px-0 pl-lg-3 mt-3 mt-lg-0">
                    <button
                      className="btn btn-block  btn-white ft-lato-bold py-3"
                      onClick={() =>
                        this.setState({ modalOpen: !this.state.modalOpen })
                      }
                    >
                      <i className="fa fa-life-ring" aria-hidden="true"></i>
                      <span className="ml-2"> CUSTOM RING</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none mb-5">
            <div className="col-lg-12">
              <div className="text-center">
                <h1 className="d-inline-block text-big-35 text-center py-3">
                  {this.state.estatePageSliderTitle &&
                  this.state.estatePageSliderData
                    ? this.state.estatePageSliderTitle
                    : ""}
                </h1>
              </div>
              <div className="position-relative">
                <div className="slider-left-layer"></div>
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  responsive={responsive}
                  ssr={false} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={2000}
                  keyBoardControl={true}
                  centerMode={true}
                  customTransition="ease .5"
                  transitionDuration={1000}
                  containerClassName="w-100"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType}
                  itemClassName="carousel-item-padding-40-px"
                >
                  {this.state.estatePageSliderData
                    ? this.state.estatePageSliderData.map((e, i) => {
                        return (
                          <Productcard
                            key={i}
                            img={e.images[0] ? e.images[0] : img1}
                            name={e.label}
                          />
                        );
                      })
                    : null}
                </Carousel>
                <div className="slider-right-layer"></div>
              </div>
            </div>
          </div>
        </div>

        <InquiryModal
          data={this.state.modalOpen}
          fatchData={this.fatchData}
          subject={`Inquiry for Estate  Style Name : ${this.state.product_name}`}
          section="Estate"
        />

        <Footer />
      </div>
    );
  }
}
