import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "../../config/Axios";
import Skeleton from "react-loading-skeleton";
import { forEach } from "lodash";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: props.sidebarOpen,
      items: [],
      logout:
        localStorage.getItem("__divinaRetailerToken__") == null ? true : false,
    };

    this.sidebarClose = props.close;
  }

  static getDerivedStateFromProps(props, state) {
    state.sidebarOpen = props.sidebarOpen;

    return state;
  }

  logout = () => {
    localStorage.removeItem("__divinaRetailerToken__");
    localStorage.removeItem("__divinaRetailerData__");
    this.setState({ logout: true });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get("/jewelleryCategory/website")
      .then((res) => {
        this.setState({ items: res.data.data, loader: false });
      })
      .catch((err) => {
        console.log("err");
      });
  }

  render() {
    let userData =
      localStorage.getItem("__divinaRetailerData__") !== null
        ? JSON.parse(localStorage.getItem("__divinaRetailerData__"))
        : {};
    return (
      <Fragment>
        <div
          className={
            this.state.sidebarOpen
              ? "sidebar animate__animated animate__slideInLeft"
              : "sidebar animate__animated animate__slideOutLeft"
          }
        >
          <div className="sidebar-header">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="mb-0">
                {localStorage.getItem("__divinaRetailerData__") !== null
                  ? userData.business_name
                    ? userData.business_name
                    : "Divina Creation Inc."
                  : "Divina Creation Inc."}
              </h4>
              <img
                src="/cancel.svg"
                width={20}
                onClick={() => this.sidebarClose(false)}
              />
            </div>
            {localStorage.getItem("__divinaRetailerData__") !== null ? (
              <div
                className="user-details my-2 p-2"
                style={{ background: "#1f1f1f" }}
              >
                <div className="d-flex align-items-center my-2">
                  <img src="/user.svg" width={15} />
                  <h6 className="mb-0 ml-1">{userData.name}</h6>
                </div>
                <div className="d-flex align-items-center my-2">
                  <img src="/smartphone.svg" width={15} />
                  <h6 className="mb-0 ml-1">{userData.mobile_number}</h6>
                </div>

                <div className="d-flex align-items-center my-2">
                  <img src="/email.svg" width={15} />
                  <h6 className="mb-0 ml-1">{userData.email}</h6>
                </div>
              </div>
            ) : null}
          </div>

          <div className="sidebar-body">
            <ul>
              <li>
                <Link to="/diamonds" className="text-white">
                  <b>Diamonds</b>
                </Link>
              </li>
              <li>
                <Link to="/jewelry" className="text-white">
                  <b>Jewelry</b>
                </Link>
                <ul>
                  <li>
                    <Link to="/jewelry/rings" className="text-white">
                      Rings
                    </Link>
                  </li>
                  <li>
                    <Link to="/jewelry/bracelets" className="text-white">
                      Bracelets
                    </Link>
                  </li>
                  <li>
                    <Link to="/jewelry/earrings" className="text-white">
                      Earrings
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/estate-jewelry" className="text-white">
                  <b>Estate Jewelry</b>
                </Link>
                <ul>
                  {/* <li>{JSON.stringify(this.state.items)}</li> */}
                  {this.state.items.length > 0 ? (
                    this.state.items.map((item, i) => {
                      return (
                        <Link
                          key={i}
                          to={
                            "/estate-jewelry/" + item.name.replace(/\s/g, "-")
                          }
                          className="td-none"
                        >
                          <li>{item.name}</li>
                        </Link>
                      );
                    })
                  ) : (
                    <>
                      <li>
                        <Skeleton count={1} width={300} />
                      </li>
                      <li>
                        <Skeleton count={1} width={300} />
                      </li>
                      <li>
                        <Skeleton count={1} width={300} />
                      </li>
                    </>
                  )}
                </ul>
              </li>
              <li>
                <Link to="/custom-design" className="text-white">
                  <b>Custom Design</b>
                </Link>
              </li>
              <li>
                <Link to="/we-buy" className="text-white">
                  <b>We Buy</b>
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-white">
                  <b>Contact Us</b>
                </Link>
              </li>
            </ul>
          </div>

          <div className="sidebar-footer">
            <ul>
              <li>
                {localStorage.getItem("__divinaRetailerData__") !== null ? (
                  <div onClick={() => this.logout()}>
                    <i className="fa fa-sign-out"></i>
                    <span className="ml-2">
                      <b>Logout</b>
                    </span>
                  </div>
                ) : (
                  <Link to="/login" className="text-white">
                    <i className="fa fa-sign-in"></i>
                    <b className="ml-2">Login</b>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}
